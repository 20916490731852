// import  { useContext } from 'react';
// import requests from '../Api/requests.js';
// import useFetch from '../Hooks/useFetch';
// import API from '../Api';
// import React, { useState,useEffect } from "react";
// import {AppContext} from "../AppContext.js";

import requests from "../Api/requests";


export const validateToken = (token) => {
  console.log("validating token on:",`${requests.baseURL}/auth/${token}`);
  try{
  return fetch(`${requests.baseURL}/auth/${token}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    // body: JSON.stringify(credentials)
  }).then(data =>{
      if(data.status === 200){
        return data.json();
      }
      return undefined;
    });
}catch(err){
  console.log("error");
  return err;
}

}
export const validateClient = (id,sessionToken) => {
  // console.log("validating client on:",`${requests.baseURL}/client/${id}`);
  try{
    
  return fetch(`${requests.baseURL}/client/${id}/${sessionToken}`, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    // body: JSON.stringify(user)
  })
    .then(data =>data.json(),console.log("Invalid or No Token"));
}catch(err){
  console.log("error");
}

}


export async function loginWithGoogle(profileObj,isRegister) {
  try{
    const extension = (isRegister ? "register" : "login");
  return fetch(`${requests.baseURL}/auth/${extension}/google`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(profileObj)
  })
    .then(data =>  data.json()).catch(err => {return{error:err?.message || 'Login Failed'}});
}catch(err){
  console.log({error:err?.message || 'Login Failed'});
}
}



async function loginUser(credentials) {
  // if(credentials.username === undefined){
  //   console.log(JSON.stringify(credentials));
  // }
  const extension = (credentials.username === undefined ? "login" : "register");



    try{
    return fetch(`${requests.baseURL}/auth/${extension}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data =>  data.json());
}catch(err){
    console.log({error:err?.message || 'Login Failed'});
}
   }


export default loginUser;