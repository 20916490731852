import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


const options = [
    {name: 'Organization 1'},

];

export default function ComboBox(props) {
    return (
      <Autocomplete
        // disablePortal
        freeSolo
        // id="combo-box"
        options={props.options}
        sx={{borderColor:'transparent'}}
        onChange={(event, newValue) => (props.onChange({target:{name:props.name,value:newValue?.label},persist:()=>{}}))}
        // sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Organizations" />}
      />
    );
  }