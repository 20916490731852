


const ParseNCBSNMulitLicensev1 = (offer)=>{
    const licenses = offer?.Licenses?.map(license=>{
        return{
            name:'NCBSN Multi License v1.0',
            type:'LicenseV1.1',
            email:offer?.email,
            description: license?.messages || 'License Description',
            identifier:license?.LicenseNumber || '1234',
            validity :{
                type:'Validity',
                validIn:{
                    type:'Place',
                    addressCountry:license?.CountryCode || 'CA'
                },
                firstValidFrom:license?.LicenseOriginalIssueDate?.split('T')[0] || '2024-06-01',
                validFrom:license?.LicenseOriginalIssueDate?.split('T')[0]  || '2024-06-02',
                validUntil:license?.LicenseExpirationDate?.split('T')[0]  || '2024-06-29'
            },
            recipient:license?.Name || {
                type:'PersonName',
                givenName:'Name',
                familyName:'LastName'
            },
            }
                
    });

    return licenses || [];

}
export default ParseNCBSNMulitLicensev1;