import React from 'react';
import { Box, Paper, Popover } from '@mui/material';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
const PopoverComponent = ({open,onClose,children,
    fullWidth=false,
    showBackground=false,
    topClose=false
    ,...props}) => {
    return (
        <Popover
        component={!showBackground && Paper}

        elevation={10}
        open={open}
        onClose={()=>onClose()}
        anchorReference='none'
        sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border:'1px solid',
          }}
          width= {fullWidth ? window.innerWidth :'1000px'}
          {...props}
        >
            {topClose &&<Button onClick={onClose} color="inherit" variant='outlined' endIcon = {<CloseIcon fontSize='xl'/>} sx={{mt:1,ml:1}}>Close</Button>}
            <Box sx={{m:5}}>
            {children}
            {!topClose &&<Button onClick={onClose} color="error" variant='outlined' endIcon = {<CloseIcon/>}>Close</Button>}
            </Box>
        </Popover>
    );
};

export default PopoverComponent;