import React, { useEffect, useState,useMemo } from 'react';
import get from '../../Hooks/getRequest.js';
import requests from '../../Api/requests.js';
import Table from '../Table/Table.js';
import {getIssuedRows} from '../Table/tableData.js';
import { issuedHead } from '../Table/tableData.js';
import revokeCredential from '../../Hooks/revokeCredential.js';
import { useCookies } from 'react-cookie';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import OfferView from '../Table/OfferView.js';
import { Checkbox, Input, Popover, Stack } from '@mui/material';
import { H1, H2, P } from '../MUI/Typography.js';
import Button from '@mui/material/Button';
import ThemeCustomization from '../../themes/index.jsx';
import { typeNames } from '../Table/tableData.js';

const getIssuedData = (data) => {
    const issuedData = [{date: 0, count: 0, data: []},{date: 1, count: 0, data: []},{date: 2, count: 0, data: []},{date: 3, count: 0, data: []},{date: 4, count: 0, data: []},{date: 5, count: 0, data: []},{date: 6, count: 0, data: []},{date: 7, count: 0, data: []},{date: 8, count: 0, data: []},{date: 9, count: 0, data: []},{date: 10, count: 0, data: []},{date: 11, count: 0, data: []}];
    // console.log("IssuedTable:getIssuedData",data);
    try{
    for(const d of data){
        const date = new Date(d?.createdAt).getMonth();
        const index = issuedData.findIndex((data) => data.date === date);
        if(index >=0){
            issuedData[index].count += 1;
           
        }
        issuedData[index].data.push({date: new Date(d?.createdAt).getDate()});
    }
    return {issuedData};
}catch(err){
    return [];
  }
}

const IssuedTable = ({setIssuedData}) => {
    const [data, setData] = useState([]);
    const {role,client} = useContext(AppContext);
    const [view, setView] = useState(undefined);
    const [cookies] = useCookies(['AUTH_TOKEN']);
    const [rows,setRows] = useState(undefined);
    const [showPopup, setShowPopup] = useState(false);
    const [reload, setReload] = useState(false);
    const [notify, setNotify] = useState(false);
    const [message, setMessage] = useState('Your Credential has been revoked');
    const removeOffer = (data) => {
        setShowPopup(data);
        console.log("IssuedTable:removeOffer",data);
      }
    const revokeCred = (credential) => {
        try{
        if(role === 'admin'){
        const data = {
            message: message,
        }
        if(notify){
            data.linkedOffer = {
                credentialType: notify,
                issuerDid: client?.did,
            };
        }
        console.log("IssuedTable:revokeCred",data);
        revokeCredential(data,credential.id).then((data) => {
            console.log("IssuedTable:revokeCred",data);
            resetPopover();
            setReload(!reload);
        }).catch((err) => {console.log("Error in IssuedTable:revokeCred",err)});
        }
    }catch(err){
        console.log("Error in IssuedTable:revokeCred",err);
    }
    }
    const childSetView = (e) => {
        // console.log("IssuedTable:childSetView",data);
        if(!e) return undefined;

        var offer = undefined;
        for(const d of data){
            // console.log("IssuedTable:childSetView:d",d,e?.currentTarget?.id);
            if(d.id === e?.currentTarget?.id){
                offer = d;
            }
        }
        // console.log("IssuedTable:childSetView:offer",offer);
        setView(offer);
    }

    useEffect(() => {
        setReload(!reload);
        // console.log("IssuedTable:useEffect:role",role);
    }, [role])
    useEffect(() => {
        setIssuedData(getIssuedData(data));
        // console.log("IssuedTable:useEffect:role",role);
    }, [data])



    useEffect(() => {
        // console.log("IssuedTable:useEffect");   
        get({url:`${requests.baseURL}/api/${cookies.AUTH_TOKEN}/credentials`}).then((data) => {
            setData(data);
            for(const d of data){
                d.removeOffer = removeOffer;
                d.view = setView;
                d.canRemove = role === 'admin';
              }
            setRows(getIssuedRows(data));
        }).catch((err) => {
            setRows([]);
            console.log("Error in IssuedTable:",err,rows)});
    }, [reload]);

    const resetPopover = () =>{
        setShowPopup(false);
        setNotify(false);
        setMessage('Your Credential has been revoked');
    }

    return (<>
        {view && <OfferView useView={view} setView={setView} />}
        {showPopup && <Popover 

            elevation={10}
            open={Boolean(showPopup)}
            onClose={()=>resetPopover()}
            anchorReference='none'
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                
              }}
              width= '1000px'
        >

            <Stack p={5} px={10}>
            <H1>Revoke Credential?</H1>
            
            <Stack direction='row' justifyContent='space-between'>
                <Stack>
                    <H2>Issued To: <P>{showPopup.credentialSubject?.vendorUserId}</P></H2>
                    <H2>Issued By: <P>{client?.name}</P></H2>
                    <H2>Issued On: <P>{new Date(showPopup?.issued).toDateString()}</P></H2>
                </Stack>
                </Stack>
                <Stack><H2>Revocation Message:</H2><Input placeholder='Your Credential Has Been Revoked.'  onChange={(v)=>setMessage(v.target.value)}/></Stack>
                <Stack direction='row'>
                <H2>Notify Recipient of replacment offer: </H2>
                <Checkbox value={notify} onChange={()=>setNotify(notify ? false : "EmploymentPastV1.1")}/>
                </Stack>
                <Stack direction='row' spacing={1}>
                    {notify && <>
                    <H2>Replacment Type:</H2>
                        <select defaultValue={"EmploymentPastV1.1"} height='50px' sx={{color:'red'}} onChange={(e)=>setNotify(e.target.value)}>
                            {Object.keys(typeNames).map((key) => {
                                return <option value={key} >{typeNames[key]}</option>    
                            })}
                        </select></>
                        
                        }
                </Stack>
                {/* <H2>Are you sure you want to revoke this credential?</H2> */}
            <Stack direction='row' justifyContent='space-between'spacing={2} pt={2}>
            <Button variant='contained' color='error' fullWidth onClick={()=>{revokeCred(showPopup);
                resetPopover();
                }}>Revoke</Button>
            <Button variant='contained' color='success' fullWidth onClick={()=>resetPopover()}>Cancel</Button>
                </Stack>
        
        </Stack>
        </Popover>}
        <Table
    rows={rows} 
    head={issuedHead}
    isLoading={rows===undefined}
    canRemove={false}
    removeOffer={removeOffer} 
    view={view} 
    setView={childSetView}
    placeHolder='No Credentials Issued On the Network'
    />
    </>);
};

export default IssuedTable;