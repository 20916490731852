
import requests from "../Api/requests";
import Cookies from 'js-cookie';


async function searchCredentialEngine(data) {
    try {
        console.log("Search Data:",data);
        if(!data ){
            console.log("Form Data Invalid");
            return {error:"Form Data Invalid"};
        }
        //clearing empty data from the query (will return nothing of included)
        if(data?.Query?.['ceterms:name'] === ''){
            delete data.Query['ceterms:name'];
        }
        if(data?.Query?.['search:termGroup']?.['ceterms:offeredBy']?.['ceterms:name'] === ''){
            delete data.Query['search:termGroup'];
        }
        if(data?.Query?.['search:termGroup']?.['ceterms:ownedBy']?.['ceterms:name'] === ''){
            delete data.Query['ceterms:ownedBy'];
        }
        return fetch(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/credential-engine/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(data => data.json());
    } catch (error) {
        console.log("Error:", error);
        return {error:error};
    }
}
export default searchCredentialEngine;