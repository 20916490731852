
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { H1, H2, P2 } from '../MUI/Typography';
import { Box, Fade, Input, Stack, Switch, TablePagination, TextField, ToggleButton } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CredentialEngineCard from './CredentialEngineCard';


import searchCredentialEngine from '../../Hooks/searchCredentialEngine.js';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteForever.js';
import UploadIcon from '@mui/icons-material/Upload';
import { credetialEngineTypes,queryFilters, velocityToQuery } from '../../Api/fields.js';
import NotifyPopup from '../MUI/NotifyPopup.js';
import axios from 'axios';
import requests from '../../Api/requests.js';
import SelectComponent from '../MUI/SelectComponent.js';
import notfound from '../../assets/images/notfound.gif';
import InfoButtonComponent from '../MUI/InfoButtonComponent.js';

/**
 * Generic compoennt for displaying a loading message.
 * @returns component
 *  
 * @example
 * // Usage
 * <LoadingComponent/>
 */
const LoadingComponent = () => {    
    return (
        <Stack direction='row' alignItems='center' spacing={1} color={'gray'} width='100%' justifyContent='center' pt={4}>
                 <Fade in={true} style={{ transitionDelay: '6s' }} unmountOnExit>
                    <Typography variant='h4' color={'inherit'}>{'Still'}</Typography>
                </Fade>
            <Fade in={true} style={{ transitionDelay: '2s',paddingRight:'20px' }} unmountOnExit >
            <Typography variant='h4' color={'inherit'}>Loading...</Typography>
            </Fade>
            <CircularProgress size={100} color='inherit'/>
        </Stack>
    );
}
/**
 * Generic compoennt for displaying a not found message.
 * @param {title} param0 
 * @returns component
 */
export const NotFoundComponent = ({title="No Results Found"}) => {
    return (
        <Stack alignItems='center' spacing={'-10%'} color={'gray'} width='100%' justifyContent='center' pt={4}
        sx={{
            height: '100%'
        }}
        >

            <Typography variant='h4' color={'inherit'}>{title}</Typography>
            <img src={notfound} width={'50%'} height={'100%'}/>
        </Stack>
    );
}

/**
 * Component for searching and displaying credential engine data.
 * 
 * @component
 * @example
 * // Usage
 * <CredentialEngineSearch type='open-badge' ctid='ce-a4c0a549-aed3-4704-ade2-e81a5d76865b' pushSelection={handleSelection} />
 * 
 * @param {string} type - The type of credential engine.
 * @param {string} ctid - The CTID (Credential Engine CTID) for the organization.
 * @param {function} pushSelection - Function to handle the selected credentials.
 * @returns {JSX.Element} The CredentialEngineSearch component.
 */
const CredentialEngineSearch = ({type='open-badge', ctid,pushSelection, ...props }) => {
    
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true); // Add a new state for loading
    const [widget, setWidget] = useState(false);
    const [useCtid, setuseCtid] = useState(ctid);
    const [selected, setSelected] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [numPages, setNumPages] = useState(0);
    const [searchData, setSearchData] = useState({name:'',org:''});
    const [typedData, setTypedData] = useState({name:'',org:''});
    const [page,setPage] = useState(0);
    const[owner,setOwner] = useState(null);
    const [currentFilter, setCurrentFilter] = useState(queryFilters[velocityToQuery[type]]?.value);
    // useEffect(() => {   
    //     console.log('useEffect',owner);
    // }, [owner]);

    const Paginator = () => {
        return (
            <TablePagination
                sx={{border:'1px solid #ccc',borderRadius:'5px'}}
        component="div"
        count={numPages}
        page={page}
        rowsPerPageOptions={[
            { value: 5, label: <Typography color='grey.600'>5</Typography> },
            { value: 10, label: <Typography color='grey.600'>10</Typography> },
            { value: 25, label: <Typography color='grey.600'>25</Typography> },
            { value: 50, label: <Typography color='grey.600'>50</Typography> }
        ]
        }
        onPageChange={(e,value)=>setPage(value)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(e)=>setRowsPerPage(e.target.value)}
        />
        );
    }

    const pushSelections = () => {
        pushSelection(selected);
    }


    useEffect(() => {  
        // console.log('useEffect', useCti d); 
        try{
            //setting owner
        axios.get(`${requests.credentialEngineURL}/${ctid}`).then(data=>setOwner(data?.data)).catch(error=>setOwner(null));
        setError(null);
        setLoading(true); // Set loading to true before fetching data
        // setOwner(null);
        // ctid='ce-a4c0a549-aed3-4704-ade2-e81a5d76865b'
        if(useCtid){
            if(ctid){ searchCredentialEngine({
                Query:{
                    '@type': {
                        "search:value": currentFilter,
                        'search:matchType': "subClassOf"
                        },
                    // '@type' : currentFilter,
                    'ceterms:name':searchData.name,
                    "search:termGroup": {
                        "search:operator": "search:orTerms",
                        "ceterms:ownedBy": {
                            "ceterms:ctid": ctid
                        },
                        "ceterms:offeredBy": {
                            "ceterms:ctid": ctid
                        }
                    }
                
                },
                Skip:page*rowsPerPage,
                Take:rowsPerPage
            }).then(data => {
                setNumPages(data?.extra?.TotalResults || 0);
                setData(data);
                setLoading(false); // Set loading to false after data is fetched
            })
            .catch(error => {
                // console.error(error);
                setError('Error: ' + error?.message);
                setLoading(false); // Set loading to false in case of error
            }) }else{
                setError('No CTID Provided. Add your Credential Engine CTID from the account Settings tab');
                setLoading(false);
            }
            
        }else{
                
            
            searchCredentialEngine({
                Query:{
                        '@type': {
                        "search:value": currentFilter,
                        'search:matchType': "subClassOf"
                        },
                    // '@type' : currentFilter,
                    'ceterms:name':searchData.name,
                    "search:termGroup": {
                        "search:operator": "search:orTerms",
                        "ceterms:ownedBy": {
                            "ceterms:name": searchData.org
                        },
                        "ceterms:offeredBy": {
                            "ceterms:name": searchData.org
                        }
                    }
                },
                Skip:page*rowsPerPage,
                Take:rowsPerPage
            }).then(data => {
                console.log('Graph data', data);
                setNumPages(data?.extra?.TotalResults || 0);
                setData(data);
                setLoading(false); // Set loading to false after data is fetched
            })}




        }catch(error){
            setError('Error: ' + error);
            setLoading(false); // Set loading to false in case of error
        }
    }, [searchData,page,rowsPerPage,ctid,useCtid,currentFilter]); // Add ctid to the dependency array

    return (<>
        {!useCtid && <NotifyPopup severity='warning' message='Warning: Credentials Will Not Originate from your Organization'/>}
          {selected.length > 0 && (
            <Stack
                direction={'row'}
                sx={{
                    position: 'fixed',
                    top: 1,
                    left: 0,
                    borderRadius: '5px',
                    transition: 'all 0.2s ease-in-out',
                    width: '70%',
                    height: '50px',
                    backgroundColor: '#d9f2e6',
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '15%',
                    justifyContent: 'center',
                    color: 'grey.600',
                    fontSize: '20px',
                    '&:hover': {
                        // backgroundColor: '#d9f2e6',
                        height: '60px',
                        borderRadius: '10px',
                        border: '1px solid black',
                    },
                    border: '1px solid #555',
                    zIndex: 1000
                }}
            >
                1 Credential {selected.length>1&&` and ${selected.length} alignment`}{selected.length > 2 && 's'} selected
                <IconButton color="inherit" sx={{height:'100%',
                minWidth:'5%',


                }} onClick={() => pushSelections()} title='Add to Credential' >
                            <UploadIcon />
                </IconButton>
        
                <IconButton color="inherit" sx={{height:'100%'}}onClick={() => setSelected([])} title='Remove Selections' >
                            <DeleteIcon />
                </IconButton>
             
            </Stack>
        )}
        
        
       <Stack direction='row' width={'100%'} bgcolor='grey.200' pl={1}><H2>Search With Widget</H2><Switch onChange={(e)=>setWidget(e.target.checked)} value={widget}/>
       

            <Stack direction='row' justifyContent='center' alspacing={3} width={'100%'} bgcolor='grey.200' pt={1}>
        <img width='50px' height='50px' src='https://www.floridaemop.com/wp-content/uploads/credential-engine-logo.png'/>
            <H1 sx={{textAlign:'center',fontSize:30}} color='grey.600'>
           
                Credential Engine Registry
                </H1>
                </Stack>
            <img  height='50px' src='https://credreg.net/Images/Common/credreg_logo_poweredby.png'/>
            </Stack> 
       
       { widget ? 
        <iframe src="https://credentialfinder.org/search?widgetid=110" style={{width:window.innerWidth-100,height:window.innerHeight-150}}></iframe>
        :
        
        <Box width={window.innerWidth-80} height={window.innerHeight-120}>
            <Stack direction='row'><H2>Use Organization Credentials</H2><Switch onChange={(e)=>setuseCtid(e.target.checked)} checked={useCtid}/></Stack> 
            
            {loading ? (
                <LoadingComponent/>
            ) : error ? (<><NotifyPopup severity='error' message={error}/>
                <Stack direction='row' alignItems='center' spacing={1}><WarningIcon color='error'/><H2 color='error'>{error}</H2></Stack></>
            ) : (
                
    <Stack direction='column' alignItems='center' spacing={1}>

         <Stack direction='row' spacing={1} py={2} alignItems='center'> 
        <TextField placeholder='All' 
        onChange={(e)=>setTypedData({...typedData, name:e.target.value})}
         value={typedData.name}
         label='🎓 Credential Name'
         title='Credential Name'
        
         />
       {!useCtid &&  <TextField placeholder='Any' 
        onChange={(e)=>setTypedData({...typedData, org:e.target.value})}
         value={typedData.org}
         label='🏛️ Organization Name'
         title='Organization Name'
        
         />}
          <SelectComponent options={queryFilters} value={currentFilter} title='Type'
        onChange={(e)=>setCurrentFilter(e.target.value)}
        fullWidth={false}
        />
         <Button  onClick={()=>setSearchData(typedData)} variant='outlined' endIcon={<SearchIcon/>} sx={{width:'150px'}}>Search</Button>
         <Paginator/>
         </Stack>

         <Stack direction='row' spacing={0} width='100%' justifyContent='flex-start' my={-100}>
         <Button onClick={()=>setExpanded(data?.['data']?.map(item => item?.['@id']))} size='small'>Expand All</Button>
         <Button onClick={()=>setExpanded([])} size='small'>Collapse All</Button>
         </Stack>
         {useCtid && <Stack direction='row' textAlign={'center'}><P2 sx={{textAlign:'center'}}>Credentials From Your Organization:</P2 ><InfoButtonComponent src={owner}/></Stack>}
                    { (data && data?.data?.length > 0) ? data?.['data']?.map(item => (
                <CredentialEngineCard key={item['@id']} item={item} onClick={()=>{
                    const index = selected.indexOf(item['@id']);
                    if(index>-1){
                        setSelected([...selected.slice(0,index),...selected.slice(index+1)]);
                    }else{
                    setSelected([...selected,item['@id']])}
                    }    
                }
                onExpand={()=>{
                    const index = expanded.indexOf(item['@id']);
                    if(index>-1){
                        setExpanded([...expanded.slice(0,index),...expanded.slice(index+1)]);
                    }else{
                    setExpanded([...expanded,item['@id']])}
                }}
                expanded={expanded.indexOf(item['@id'])>-1}
                selected = {selected.indexOf(item['@id'])>-1}
                owner={useCtid&&owner}
                />
            )) : <NotFoundComponent/>}
            </Stack>
            //USING GRAPH SEARCH API
        )
    }
        </Box>}
   </> );
};

export default CredentialEngineSearch;


