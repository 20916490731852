/**
 * AccountCard component.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.user - The user object.
 * @param {Function} props.onClick - The click event handler.
 * @returns {JSX.Element} The rendered AccountCard component.
 */
import React from 'react';

import { Box } from '@mui/system';
import Paper from '@mui/material/Paper';
import { Avatar, Button, Stack } from '@mui/material';
import { H2, P2, P3,P } from '../MUI/Typography';

const AccountCard = ({ user,onClick }) => {

    return(
      <Box>
      <Button className="account__card" component={Paper} sx={{border:'1px solid #ccc'}} onClick={()=>onClick(user)}>
         <Stack direction='row' justifyContent='space-between' alignItems='center'>
         <Avatar >{user.username.toUpperCase()[0]}</Avatar>
        <P3>{user.username}, </P3>
        <P >{user.email}</P>
        </Stack>
      </Button>
    </Box>);
}

export default AccountCard;