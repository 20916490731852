import axios from 'axios';
import requests from '../../Api/requests.js';
import Cookies from 'js-cookie';

export async function setOfferClaimed(code) {
    try {
        const response = await axios.post(`${requests.baseURL}/api/offers/claim/${code}`, {
            message: "Offer Claimed",
            headers: {
            'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (err) {
        return err;
    }
}

export async function decodePresentationRequest(code) {
    try {
        const response = await axios.get(`${requests.baseURL}/api/disclosures/${code}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (err) {
        return err;
    }
}

export async function shareCredential(presentationCode,credentials){
    try {
        const response = await axios.post(`${requests.baseURL}/api/share-credential/${presentationCode}`, {
            ...credentials,
        });
        return response.data;
    } catch (err) {
        return err;
    }
}

export async function getNonVelocityCredentials(){
    try {
        const response = await axios.get(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/recieved-credentials/nonVelocity`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (err) {
        return err;
    }
}

export async function deleteNonVelocityCredential(id){
    try {
        const response = await axios.delete(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/nonVelocity/${id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (err) {
        return err;
    }
}