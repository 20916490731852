import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import useMediaQuery from '@mui/material/useMediaQuery';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

// project import
import Drawer from './Drawer';
import Header from './Header';
// import navigation from 'menu-items';
import Loader from '@mui/material/CircularProgress';
// import Breadcrumbs from 'components/@extended/Breadcrumbs';

import { handlerDrawerOpen, useGetMenuMaster } from '../../Api/menu'
import { Stack } from '@mui/material';

// ==============================|| MAIN LAYOUT ||============================== //

export default function DashboardLayout({children}) {
  const { menuMasterLoading } = useGetMenuMaster();
  const downXL = useMediaQuery((theme) => theme.breakpoints.down('xl'));

  useEffect(() => {
    handlerDrawerOpen(!downXL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downXL]);

  if (menuMasterLoading) return <Loader />;

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header />
      <Stack direction="row"  justifyContent="center" alignItems="center" mr ={3} width='100%'>
      <Drawer />
      
        {children}
      </Stack>
    </Box>
  );
}
