import Cookies from 'js-cookie';
import { env } from '../env';

/**
 * Reads the base URL from the environment variables and sets it as the default value
 */
const BASE_URL = env.REACT_APP_BASE_URL || 'https://service.velocitycreds.com';

export default {
  networkRegistrar: "https://stagingregistrar.velocitynetwork.foundation",
  Offers: `${BASE_URL}/api/${Cookies.get('AUTH_TOKEN')}/offers`,
  Users: `${BASE_URL}/users/${Cookies.get('AUTH_TOKEN')}`,
  baseURL: BASE_URL,
  credentialEngineURL: 'https://credentialengineregistry.org/resources',
};
 