// project import
import NavCard from './NavCard';
import Navigation from './Navigation';
import SimpleBar from './SimpleBar';
import { useTheme } from '@mui/material/styles';
// ==============================|| DRAWER CONTENT ||============================== //

export default function DrawerContent() {
  const theme = useTheme();
  return (
    <>
      <SimpleBar sx={{ '& .simplebar-content': { display: 'flex', flexDirection: 'column' } ,backgroundColor: theme.palette.mode === 'dark' && theme.palette.grey[800]}}>
        <Navigation />
        {/* <NavCard /> */}
      </SimpleBar>
    </>
  );
}
