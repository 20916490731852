import React from 'react';
// material-ui
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// project import
import MainCard from './components/MainCard.jsx';
import AnalyticEcommerce from './components/AnalyticEcommerce';
import MonthlyBarChart from './MonthlyBarChart';
import ReportAreaChart from './ReportAreaChart';
import UniqueVisitorCard from './UniqueVisitorCard';
import SaleReportCard from './SaleReportCard';
import OrdersTable from './OrdersTable';
import Results from '../Results/Results.js';
import IssuedTable from '../Results/IssuedTable.js';
// assets
import GiftOutlined from '@ant-design/icons/GiftOutlined';
import MessageOutlined from '@ant-design/icons/MessageOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import icon from '../../assets/images/icon.png';

// avatar style
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

// action style
const actionSX = {
  mt: 0.75,
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',
  transform: 'none'
};
//Date Calcs
const today = new Date();
const january = new Date(today.getFullYear(), 0, 1);
const hoursSinceJanuary = Math.floor((today - january) / (1000 * 60 * 60));
// console.log(hoursSinceJanuary);
const STARTING_INDIVIDUALS = 903234;
const STARTING_CREDENTIALS = 1420000;
const STARTING_ISSUERS =60;
const STARTING_INSPECTORS = 13;
const INDIVIDUALS_PER_HOUR = 12;
const CREDENTIALS_PER_HOUR = 76;//extrapolated from 2024 data
const ISSUERS_PER_HOUR = 0.003056;
const INSPECTORS_PER_HOUR = 0.0005;
const excessIndividuals = hoursSinceJanuary * INDIVIDUALS_PER_HOUR;
const excessCredentials = hoursSinceJanuary * CREDENTIALS_PER_HOUR;
const excessInspectors = Math.round(hoursSinceJanuary * INSPECTORS_PER_HOUR);
const excessIssuers = Math.round(hoursSinceJanuary * ISSUERS_PER_HOUR);
const PERC_INDIVIDUALS = ((excessIndividuals / STARTING_INDIVIDUALS) * 100).toFixed(2);
const PERC_CREDENTIALS = ((excessCredentials / STARTING_CREDENTIALS) * 100).toFixed(2);
const PERC_ISSUERS = ((excessIssuers / STARTING_ISSUERS) * 100).toFixed(2);
const PERC_INSPECTORS = ((excessInspectors / 13) * 100).toFixed(2);
//Number formatting
function numberWithCommas(x) {
  return x.toLocaleString();
}
// ==============================|| DASHBOARD - DEFAULT ||============================== //

export default function DashboardDefault() {

  const [offerData, setOfferData] = React.useState([]);
  const [issuedData, setIssuedData] = React.useState([]);

  const childSetData = (data) => {
    // console.log("DashboardDefault childSetData",data);
    if(data?.offerData) setOfferData(data.offerData);
    if(data?.issuedData) setIssuedData(data.issuedData);
  }

  React.useEffect(() => {
    // console.log("DashboardDefault useEffect",issuedData);
  }, [offerData, issuedData]);


  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75} pl={2} pt={5}>
      {/* row 1 */}
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Typography variant="h5">Dashboard</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticEcommerce title="Total Individuals on the Network" count= {numberWithCommas(STARTING_INDIVIDUALS + (excessIndividuals))} percentage={PERC_INDIVIDUALS} extra={numberWithCommas(excessIndividuals)} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticEcommerce title="Total Credentials Issued" count={numberWithCommas(STARTING_CREDENTIALS+excessCredentials)} percentage={PERC_CREDENTIALS} extra={numberWithCommas(excessCredentials)} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticEcommerce title="Total Issuers" count={numberWithCommas(STARTING_ISSUERS+excessIssuers)} percentage={PERC_ISSUERS} color="warning" extra={numberWithCommas(excessIssuers)} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticEcommerce title="Total Inspectors" count={numberWithCommas(STARTING_INSPECTORS+excessInspectors)} percentage={PERC_INSPECTORS} color="warning" extra={excessInspectors} />
      </Grid>

      <Grid item md={12} sx={{ display: { sm: 'none', md: 'block', lg: 'none' } }} />

      {/* row 2 */}
      <Grid item xs={12} md={12} lg={12}>
        {/* <UniqueVisitorCard /> */}
        <Typography variant="h5">Credential Offers Created</Typography>
      <MainCard sx={{ mt: 2 , height:'95%' }} content={false} elevation={5}>
        {/* Loads Table */}
         <Results type='Offers' setOfferData={childSetData}/>

        </MainCard>
      </Grid>
      {/* Income OverView Graph */}

      {/* row 3 */}
      <Grid item xs={12} md={12} lg={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Issued Credentials</Typography>
          </Grid>
          <Grid item />
        </Grid>

        <MainCard sx={{ mt: 2 ,height:'93%'}} content={false} elevation={5}>
          {/* Issued Table*/}
          <IssuedTable setIssuedData={childSetData}/>
        </MainCard>

      </Grid>
      {/* <Grid item xs={12} md={5} lg={4}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Credentials Report</Typography>
          </Grid>
          <Grid item />
        </Grid>
        <MainCard sx={{ mt: 2 }} content={false}>
          <List sx={{ p: 0, '& .MuiListItemButton-root': { py: 2 } }}>
            <ListItemButton divider>
              <ListItemText primary="Company Finance Growth" />
              <Typography variant="h5">+45.14%</Typography>
            </ListItemButton>
            <ListItemButton divider>
              <ListItemText primary="Company Expenses Ratio" />
              <Typography variant="h5">0.58%</Typography>
            </ListItemButton>
            <ListItemButton>
              <ListItemText primary="Business Risk Cases" />
              <Typography variant="h5">Low</Typography>
            </ListItemButton>
          </List>
          <ReportAreaChart />
        </MainCard>
      </Grid> */}

      {/* row 4 */}
      <Grid item xs={12} md={12} lg={11.9} pb={5}>
        <SaleReportCard offerData={offerData} issuedData={issuedData}/>
      </Grid>
      {/* <Grid item xs={12} md={5} lg={4}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Transaction History</Typography>
          </Grid>
          <Grid item />
        </Grid>
        <MainCard sx={{ mt: 2 }} content={false}>
          <List
            component="nav"
            sx={{
              px: 0,
              py: 0,
              '& .MuiListItemButton-root': {
                py: 1.5,
                '& .MuiAvatar-root': avatarSX,
                '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
              }
            }}
          >
            <ListItemButton divider>
              <ListItemAvatar>
                <Avatar sx={{ color: 'success.main', bgcolor: 'success.lighter' }}>
                  <GiftOutlined />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={<Typography variant="subtitle1">Order #002434</Typography>} secondary="Today, 2:00 AM" />
              <ListItemSecondaryAction>
                <Stack alignItems="flex-end">
                  <Typography variant="subtitle1" noWrap>
                    + $1,430
                  </Typography>
                  <Typography variant="h6" color="secondary" noWrap>
                    78%
                  </Typography>
                </Stack>
              </ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton divider>
              <ListItemAvatar>
                <Avatar sx={{ color: 'primary.main', bgcolor: 'primary.lighter' }}>
                  <MessageOutlined />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={<Typography variant="subtitle1">Order #984947</Typography>} secondary="5 August, 1:45 PM" />
              <ListItemSecondaryAction>
                <Stack alignItems="flex-end">
                  <Typography variant="subtitle1" noWrap>
                    + $302
                  </Typography>
                  <Typography variant="h6" color="secondary" noWrap>
                    8%
                  </Typography>
                </Stack>
              </ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton>
              <ListItemAvatar>
                <Avatar sx={{ color: 'error.main', bgcolor: 'error.lighter' }}>
                  <SettingOutlined />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={<Typography variant="subtitle1">Order #988784</Typography>} secondary="7 hours ago" />
              <ListItemSecondaryAction>
                <Stack alignItems="flex-end">
                  <Typography variant="subtitle1" noWrap>
                    + $682
                  </Typography>
                  <Typography variant="h6" color="secondary" noWrap>
                    16%
                  </Typography>
                </Stack>
              </ListItemSecondaryAction>
            </ListItemButton>
          </List>
        </MainCard>
        <MainCard sx={{ mt: 2 }}>
          <Stack spacing={3}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Stack>
                  <Typography variant="h5" noWrap>
                    Help & Support Chat
                  </Typography>
                  <Typography variant="caption" color="secondary" noWrap>
                    Typical replay within 5 min
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <AvatarGroup sx={{ '& .MuiAvatar-root': { width: 32, height: 32 } }}>
                  <Avatar alt="Remy Sharp" src={icon} />
                  <Avatar alt="Travis Howard" src={icon} />
                  <Avatar alt="Cindy Baker" src={icon} />
                  <Avatar alt="Agnes Walker" src={icon} />
                </AvatarGroup>
              </Grid>
            </Grid>
            <Button size="small" variant="contained" sx={{ textTransform: 'capitalize' }}>
              Need Help?
            </Button>
          </Stack>
        </MainCard>
      </Grid> */}
    </Grid>
  );
}
