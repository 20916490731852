import React, { useState } from 'react';
import { TextField, Button, Box, Slider, Stack, duration, Switch } from '@mui/material';
import { H2 } from '../MUI/Typography';
import SelectComponent from '../MUI/SelectComponent';
import MultiSelect from '../MUI/MultiSelect';
import { allOptions, hyphenToCamel, layer2 } from '../CredentialForm/CredentialSection';
import { types } from 'util';
import CancelIcon from '@mui/icons-material/Cancel';
import { createDisclosure } from '../../Hooks/disclosures';
import NotifyPopup from '../MUI/NotifyPopup';
import NonVelocitySwitch from '../MUI/NonVelocitySwitch';


const mapTypes = (types) => {
    if(types === undefined || types.length === 0){
        return Object.keys(hyphenToCamel).map((key) => {
            return {type: hyphenToCamel[key]};
        }).filter((type) => !layer2.includes(type.type));
    }
    var mappedTypes = [];
    Object.keys(hyphenToCamel).forEach((type) => {
      for(var i=0;i<types.length;i++){
        const d = types[i];
          if(type.includes(d)){
            // console.log('Type:',type,"included in",d);
              mappedTypes.push({type: hyphenToCamel[type]});
            //   break;
          }
      }
    });
    return mappedTypes;
}


const DisclosureRequestForm = ({onCancel,onSuccess}) => {
    const [error, setError] = useState(undefined);
const [formData, setFormData] = useState({
    description: '',
    purpose: '',
    types: [],
    duration: '6m',
    sendPushOnVerification: false,
    nonVelocity: false,
});

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log({
            ...formData,
            types: mapTypes(formData.types),
        });
        createDisclosure({
            ...formData,
            types: mapTypes(formData.types),
        }).then((data) => {
            if(data?.error){
                setError(data.error);
            }else{
                onSuccess('Disclosure Created');
            }
        }).catch((e) => setError(e?.mesage || e));
    };
    function valuetext(value) {
        return `${value}m`;
      }
    return (<>
        {error && <NotifyPopup message={error} severity="error" onClose={()=>setError(undefined)}/>}
        <Box component='form' onSubmit={handleSubmit}>
            <TextField
                label="Purpose"
                value={formData.purpose}
                onChange={(e) => setFormData({ ...formData, purpose: e.target.value })}
                fullWidth
                placeholder='Enter a purpose for the disclosure'
                margin="normal"
                required
            />
            <TextField
            required
                label="Description"
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                fullWidth
                placeholder='Enter a description for the disclosure'
                margin="normal"
            />
            <Stack direction='row' spacing={2} alignItems='center'>
                <H2>Duration (months)</H2>
                <Box sx={{ width: 300 }}>
                <Slider
                    aria-label="Duration (months)"
                    defaultValue={6}
                    getAriaValueText={valuetext}
                    valueLabelDisplay={'auto'}
                    shiftStep={10}
                    step={1}
                    marks
                    min={1}
                    max={24}
                    onChange={(e) => setFormData({ ...formData, duration: `${e.target.value}m` })}
                />
                </Box>
            </Stack>
            <MultiSelect
              options={[...allOptions.map((option) => option.key)]}
              name="Credential Types (leave blank for all types)"
              onChange={(e)=>setFormData((prevData) => ({ ...prevData,types: e.target.value }))}
              />
            <NonVelocitySwitch onChange={(e) => setFormData({ ...formData, nonVelocity: !e.target.checked })} value={formData.nonVelocity} title='Inspection infrastructure'/> 
              <Stack spacing={2} direction='row'>
                <H2>Notify me when completed:</H2>
                <Switch onChange={(e) => setFormData({ ...formData, sendPushOnVerification: e.target.checked })} value={formData.sendPushOnVerification}/>
            </Stack>
           <Stack spacing={1} direction='row'>
            <Button type="submit" variant="contained" color="primary" sx={{width:'100px'}}>
                Create
            </Button>
            <Button type="submit" variant="outlined" color="error" endIcon={<CancelIcon/>} width='100px' onClick={()=>onCancel()}>
                Cancel
            </Button>
            </Stack>
        </Box>
    </>);
};

export default DisclosureRequestForm;