import React from 'react';

import Typography from '@mui/material/Typography';

export const H1 = ({children,...props}) => {
    return <Typography component='h1' variant='h3' color='text.secondary' fontWeight='bold'{...props} >{children}</Typography>;
}

export const P2 = ({children}) =>{
    return <Typography component='p' variant='h4' color='text.primary' ml={2}>{children}</Typography>;
}

export const H2 = ({children,...props}) =>{
    return <Typography component='h6' variant='h6' pt={1} color='text.secondary' fontWeight='bold' {...props}>{children}</Typography>;
}
export const H3 = ({children,...props}) =>{
    return <Typography component='h6' variant='h6' pt={1} color='grey.600' fontWeight='bold' px = {props.spacing}>{children}</Typography>;
}
export const P3 = ({children,...props}) =>{
    return <Typography component='p' variant='h6' color='text.primary' ml={2} pr = {props.spacing} {...props}>{children}</Typography>;
}
export const H = ({children}) =>{
   return <Typography component='h6' variant='h4' color='grey.500'>{children}</Typography>;
}
export const P = ({children,...props}) =>{
    return <Typography component='p' variant='body1' color='text.primary' {...props}>{children}</Typography>;
}
export const P4 = ({children}) =>{
    return <Typography component='p' variant='body2' color='grey.600' fontSize='small'>{children}</Typography>;
}