import React, { useEffect } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import ReactJson from 'react-json-view'
import { CircularProgress, Fade, IconButton, Stack, useTheme } from '@mui/material';
import { H, H2, P } from '../MUI/Typography';
import {TextField} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box } from '@mui/system';
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import CheckIcon from '@mui/icons-material/Check';
import parsers from '../../schemas/parsers'
import OfferCard from '../MUI/OfferCard';
import sendOffer from '../../Hooks/sendOffer';
import { getClrEmail, getClrName } from '../../schemas/parsers/Clr.parser';
import { proccessOfferFormData } from '../../Hooks/sendOffer';
import NotifyPopup from '../MUI/NotifyPopup';
import NonVelocitySwitch from '../MUI/NonVelocitySwitch';

const getEmail = (json) => json?.email || getClrEmail(json);
const getName=(json)=> getClrName(json);
const addIssuanceDate = (obj) => {
  console.log("Adding issuance date",obj);
  if(obj['awardedDate']){
  obj['issuanceDate'] = obj['awardedDate'];
  }
  delete obj['awardedDate'];
  obj?.credentialSubject?.verifiableCredential?.forEach(cred => {
    if(cred['awardedDate']){
    cred['issuanceDate'] = cred['awardedDate'];
    delete cred['awardedDate'];
    }
  })
  return obj;
}
export const FileInput = ({ onChange, accept,value }) => {
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
      });
    return(
         <Stack direction="row" spacing={1} >
        <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        title='Upload file'
      >
        Upload file
        <VisuallyHiddenInput type="file" onChange={onChange} accept={accept}/>
      </Button>
        {<P color={value.name ? 'text.secondary':'primary'} fontSize={20}>{value.name || `Accepted Formats: ${accept.toString().replaceAll(',',' ')}`}</P>}
      </Stack>
    );
}
const validateJSON = async (json, schema) => {
  return new Promise((resolve, reject) => {
    try {
      const ajv = new Ajv({ allErrors: true });
      addFormats(ajv);
      const validate = ajv.compile(schema);
      const valid = validate(json);
      if (!valid) {
        // console.log(validate.errors.toLocaleString() );
        resolve({ error: validate.errors.map((err) => <p style={{ color: 'red' }}>{err.message}</p>)});
      }
      resolve(true);
    } catch (err) {
      console.log("Error in validating JSON", err);
      reject(err);  
    }
  });
};
const RecordReader = ({schema,type,alignments}) => {
  // console.log("Schema",schema);
    const [fileName, setFileName] = React.useState(undefined)
    const [file, setFile] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [madeOffers, setMadeOffers] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [success,setSuccess] = React.useState(null);  
    const [nonVelocity, setNonVelocity] = React.useState(true);
    const theme = useTheme();
    useEffect(() => {
      setFile(null);
      setFileName(undefined);
      setError(null);
      setMadeOffers(null);
     
    },[type]);
    useEffect(() => {
      console.log('useEffect',nonVelocity);
    },[nonVelocity]);
    useEffect(() => {
      setSuccess(null);
    },[fileName]);
    //sends Made offers to the backend to be issued
    const issueMadeCredentials = async () => {
      if(madeOffers.length === 0){
        setError("No offers to issue");
         return;
      }
      var flag =false;
      while(madeOffers.length > 0 && !flag){
        var offer = {...madeOffers[0],nonVelocity};
        if(alignments?.length > 0){
          if(!offer.alignment){ offer.alignment = alignments}else{;
            offer.alignment = [...offer.alignment, ...alignments];
          }
        }
      const res = await sendOffer(offer).catch(err => setError("Error in issuing credentials",err));
      console.log("Issuing credentials",res);
      if(res.error){
        flag = true;
        await setError(res.error);
      }else{
        //removes offer from display
        madeOffers.shift();
        await setMadeOffers(madeOffers);
      }
      await new Promise(r => setTimeout(r, 100));
      }
      if(!flag) setSuccess("All offers created successfully");
      setFile(null);
      //return res;
    }

    const handleFileChange = (e) => {
        setError(null);
        setLoading(true);
        try{
        setFileName(e.target.files[0].name);
        
        if(!e.target.files[0]) return;
        if(e.target.files[0].size > 1000000) throw new Error("File size is too large");

        if(e.target.files[0]?.value){
          console.log("Reading file",e.target.files[0].value);
          setLoading(false);
          setFile(addIssuanceDate(JSON.parse(e.target.files[0]?.value)))?.catch(err => {
            setError(err?.message || err)
            // console.log("Error in parsing JSON",err);
          });
          
        }else{ 
          
        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
          try{
          setFile(addIssuanceDate(JSON.parse(e.target.result)));
          // setFile(addIssuanceDate(file));
          }catch(err){
            setError(err?.message || err)
            // console.log("Error in parsing JSON",err);
          }
          setLoading(false);  
        };
      }
    }catch(err){
        setError(err?.message || err)
        console.log("Error in reading file",err);
    }
    };
    return (
      
        <Box width='100%' p={2} pb={5} >
            <Stack direction="row" spacing={2} justifyContent='flex-start' minWidth='100%' p={2}>
              {!file ? (<><P fontSize={20} textDecoration='underline' sx={{color : theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[500]}}>Upload a file:</P> <FileInput accept={`.json${type?.includes('clr')? ',.1clr':''}`} onChange={(e) => handleFileChange(e)} value={{name:fileName}}/></>): 
                //File is defined
              (!madeOffers ? 
                !getEmail(file) ? 
                //No email is defined in file (Not required so must add one)
                <Stack direction='row' spacing={1} alignContent='center' width='100%' display='flex' justifyContent='flex-end' alignItems='flex-end'>
                  <H2 color='warning' sx={{maxWidth:'100px'}}>User Email for {getName(file) || 'Recipient'}</H2>
                <TextField error={Boolean(error) }placeholder={'Enter email address for '+getName(file) ||'Recipient'} type='email' variant='outlined' fullWidth onChange={(e) => setEmail(e.target.value)}  onSubmit={(e)=>console.log(e)}/> 
                  <Button variant='contained' color='primary' height='100%' onClick={() => {
                    setError(null);
                    if(!email || !email.includes('@') || !email.includes('.')){
                      setError("Invalid email address");
                      return;
                    }
                    setFile({...file,email})
                    
                    }} endIcon={<CheckIcon/>}>Save</Button>
                </Stack>
              
              
                //Email is defined and file is valid
                :
                <>
                <Button variant='contained' color='success' onClick={() => {
                setLoading(true);
                setError(null);
                validateJSON(file,schema).then(res => {
                  if(res.error) {
                    setError(res.error);
                  }else{
                    // console.log("Valid JSON",res);
                    const parse = parsers[type];
                    if(parse){
                      console.log("Parsing JSON with",type);
                      const parsedOffers = parse(file);
                      var setMade;
                      try{
                        setMade = parsedOffers.map(offer => {
                          return proccessOfferFormData({...offer,email:getEmail(file)});
                        });
                      }catch(err){
                        setError(err?.message || err)
                        setLoading(false);
                        return;
                      }
                      setMadeOffers(setMade); 
                    } else{
                      console.log(parsers)
                      setError(`No parser found for record type '${type}'`)
                    }
                  }
                  setLoading(false);
                }).catch(err => {
                  // console.log("Error in validating JSON",err);
                  setError(err?.message || err)
                  setLoading(false);
                });
              
              }} fullWidth endIcon={<CheckIcon/>}>Verify and Parse</Button></> : 
            //Made offers are presented
            (<Stack spacing={2} width='100%'>
            <NonVelocitySwitch checked={nonVelocity} onChange={(e) => setNonVelocity(!e.target.checked)} />
            <Button variant='outlined' color='warning' onClick={() => {setLoading(true);issueMadeCredentials().then(()=>{setLoading(false)})} } fullWidth endIcon={<CheckIcon/>} title='Issue' >Issue Layer 1 Credentials</Button></Stack>)
            )
              
              }
            
            {loading && <CircularProgress disableShrink />}
            {!loading && file && <IconButton onClick={() => {setFile(null);setFileName(undefined);setError(null);setMadeOffers(undefined)}} sx={{alignSelf:'flex-end'}} title='Remove'><DeleteForeverIcon/></IconButton>}
            </Stack>
            {error && <P color='error'>{error}</P>}
            {success && <NotifyPopup message={success} severity='success' onClose={()=>setSuccess(null)}/>}
            {file ?  
            (madeOffers ? <Stack spacing={2} >
        
              {madeOffers.length ? <H2>Generated Layer 1 Offers (Total:{madeOffers?.length}):</H2> : !success && <P>No offers to show</P>}
              {
            madeOffers.map((offer,index) => 
              <Fade in={true} timeout={1000*(index+1)}>
            <Stack spacing={2} direction='row'>
             
            <OfferCard offerData={offer} border='1px solid black' />
            
            <IconButton onClick={() => {
              madeOffers.splice(madeOffers.indexOf(offer),1);
              setMadeOffers([...madeOffers]);
            }} title={'Remove offer'}><DeleteForeverIcon/></IconButton>
            
            </Stack>
            </Fade>
          )}</Stack>
            :<ReactJson src={file} style={{
              maxWidth:'1000px' 
            }} collapseStringsAfterLength={100} collapsed={3}
            theme={theme.palette.mode ==='dark' ? 'eighties' :'rjv-default'}
            />)
            
            
            :
            <TextField minRows={5} maxRows={10} placeholder='Or paste a record here...' multiline padding={2} variant='outlined'
            error={error}
            sx={{
              width: '100%',
              fontSize: 20,
              fontFamily: 'monospace',
              // border: '1px solid',
              borderRadius: 4,
              color :theme.palette.mode === 'dark' && theme.palette.grey[100]
            }}
            onChange={(e) => {if(e.target.value) handleFileChange({target:{files:[{name:'Pasted json',value:e.target.value}]}})}}
            />
            }
        </Box>
    );
};

export default RecordReader;