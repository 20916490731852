import axios from 'axios';
import requests from '../../Api/requests.js';
import Cookies from 'js-cookie';

export async function getAllReviecedCredentials() {
    try {
        const response = await axios.get(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/recieved-credentials`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (err) {
        return err;
    }
}

export async function deleteRecievedCredential(id) {
    try{
        return fetch(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/recieved-credentials/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(data => data.json()).catch(err => console.log("Error getting:",err));
    }catch(err){
        console.log("error");
    }
}

export async function verifyCredential(rawCredential){
    try{
        const response = await axios.post(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/verify-credential`, rawCredential, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    }catch(err){
        return err;
    }
}