import { CircularProgress, Stack } from '@mui/material';
import React from 'react';
import { H1, H2 } from '../MUI/Typography';
import NotifyPopup from '../MUI/NotifyPopup';
import { deleteNonVelocityCredential, getNonVelocityCredentials } from '../../Hooks/sharing/nonVelocityHandling';
import ReactJson from 'react-json-view';
import IconButton from '@mui/material/IconButton';
import DeleteForever from '@mui/icons-material/DeleteForever';
const NonVelocityDisclosures = () => {
    const [disclosures, setDisclosures] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(undefined);
    React.useEffect(() => {
        getNonVelocityCredentials().then((data) => {
            if(data.error){
                setError(data.error);
            }else{
                setDisclosures(data);
                console.log(data);
            }
            setLoading(false);
        });
    }, []);
    return (
        <Stack spacing={1} pt={5}>
            {error && <NotifyPopup message={error} severity="error" onClose={() => setError(undefined)} />}
                <Stack  justifyContent='space-between'>
            <H1 sx={{borderBottom:'3px solid coral'}} color='grey.600'>Non-Velocity Disclosures</H1>
            <H2>Web Credentials can originate from many different organizations will not be  independently verified by velocitycreds.</H2>
            </Stack>
            {loading ? <CircularProgress/> : disclosures.map((disclosure, index) => (
                <Stack border='1px solid' borderRadius={2} direction='row'>
                <ReactJson src={disclosure?.credentials} key={index} name={false} collapsed={2} />
                    <IconButton onClick={()=>(deleteNonVelocityCredential(disclosure._id)
                        .then((data) => {
                            if(data.error){
                                setError(data.error);
                            }else{
                                setDisclosures(disclosures.toSpliced(index,1));
                            }
                        }).catch((e) => setError(e)))}
                    ><DeleteForever/></IconButton>
                </Stack>
            ))}
        </Stack>
    );
};

export default NonVelocityDisclosures;