import requests from '../Api/requests.js';
import Cookies from 'js-cookie';
export const deleteClient = async() => {
  try{
    return fetch(`${requests.baseURL}/client/${Cookies.get('AUTH_TOKEN')}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'

      },
      body: JSON.stringify({message:'delete'})
    })
      .then(data => data.json());
}catch(err){
    console.log("error");
}
}


async function deleteUser(credentials) {
    if(credentials.username === undefined){
      console.log(JSON.stringify(credentials));
    }
    const extension = (credentials._id === undefined ? 0 : credentials._id);
  
  
  
      try{
      return fetch(`${requests.baseURL}/users/${Cookies.get('AUTH_TOKEN')}/${extension}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'

        },
        body: JSON.stringify(credentials)
      })
        .then(data => data.json(),console.log("Error"));
  }catch(err){
      console.log("error");
  }
     }
  
  
  export default deleteUser;