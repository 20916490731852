import React, { useEffect, useState } from 'react';
import { Card, IconButton, Link, Paper, Popover, Stack, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import PopoverComponent from './PopoverComponent';
import { H1, H2, P } from './Typography';
import ReactJson from 'react-json-view';



export const ObjectDisplayer = ({src,tabs=0}) => {


    const StringDisplayer = ({title,value}) => {
        // {console.log("Key:",title)}
        if(typeof value === 'string' && value?.startsWith('http')){
            return <Stack direction='row' spacing={1}>
                    <Typography variant='h6' fontWeight={'bold'}>{title}:</Typography>
                    <Link target="_blank" rel="noopener"  href={value}>{value}</Link>
                </Stack>
        }else{
            return (
                <Stack direction='row' spacing={1}>
                    <Typography  fontWeight={'bold'} variant='h6'>{title}:</Typography>
                    <Typography color={value?.length <=70 ? 'inherit'  : 'grey.500'}>{value}</Typography>
                </Stack>
            );
        }
    }


    return ( src &&
        <Stack spacing={1} pl={tabs*2}>
            {
            typeof src === 'object' ? (
            Object.keys(src).map((key)=>{
                if(Array.isArray(src[key])){
                    return <><Typography variant='h6' fontWeight={'bold'} >{key}:</Typography>{src[key].map((data)=>(
                         typeof data === 'object' ? <ObjectDisplayer src={data} tabs={tabs+1}/> : <StringDisplayer title={key} value={data}/>
                        // <ObjectDisplayer src={data} tabs={tabs+1}/>
                    ))}</>
                }else if(typeof src[key] === 'object'){
                    return <>
                    <Typography variant='h6' fontWeight={'bold'} >{key}:
                    </Typography>
                    <ObjectDisplayer src={src[key]} tabs={tabs+1}/>
                    </>
                }else if (typeof src[key] === 'string' || typeof src[key] === 'number' || typeof src[key] === 'boolean')    {
                    return <StringDisplayer title={key} value={src[key]}/>
                }else{
                    <Stack direction='row' spacing={1}>
                                <Typography variant='h6'>{key}:</Typography>
                                <Typography color='grey.500'>Undefined</Typography>
                            </Stack>
                }

                })
                ) : (
                    
                <Stack direction='row' spacing={1}>
                    {console.log("Illegal Type:",typeof src)}
                        <Typography variant='h6' fontWeight={'bold'}>{src}</Typography>
                        {/* <Typography color='grey.500'>src</Typography> */}
                        </Stack>
                )
            }
        </Stack>
    );

}

const InfoButtonComponent = ({ src,title }) => {
    const [open,setOpen] = useState(false);
    const [isArray, setIsArray] = useState(false);
    useEffect(() => {
        if(Array.isArray(src)){
            setIsArray(true);
        }else{
            setIsArray(false);
        }
    },[src]);
    return (
        <>
            <IconButton onClick={()=>setOpen(true)}>
                <InfoIcon />
            </IconButton>
            <PopoverComponent open={open} onClose={()=>setOpen(false)} topClose={true}>
                {/* {title&& <H1>{title}</H1>}
                {isArray ? 
                <Stack spacing={2}>{
                src?.map((data)=>(
                    <Card component={Paper} elevation={4} sx={{padding:2}}>
                    <ObjectDisplayer src={data}/>
                    </Card>
                ))}</Stack>
                : <Card component={Paper} elevation={4} sx={{padding:2}}><ObjectDisplayer src={src}/></Card>} */}
                <ReactJson src={src}/>
            </PopoverComponent>
        </>
    );
};

export default InfoButtonComponent;