
import Form from '@rjsf/mui';
// import Form from '@rjsf/material-ui';
import validator from '@rjsf/validator-ajv8';
// import Form from '@rjsf/antd/dist/main.css';

/**
 * Component for rendering a credential form using RJSF.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.schema - The JSON schema for the form.
 * @param {Object} props.uiSchema - The UI schema for the form.
 * @param {Object} props.formData - The initial form data.
 * @param {Function} props.onSubmit - The callback function to handle form submission.
 * @param {string} props.name - The name of the form.
 * @returns {JSX.Element} The rendered credential form component.
 */
const CredentialForm = ({ schema, uiSchema, formData, onSubmit,name }) => {

  
  return (
    <div style={{maxWidth:'1000px'}}>
      
     {schema && uiSchema && 
     <Form
    className = {"cred__form " +name}
      schema={schema}
      uiSchema={uiSchema}
      formData={formData}
      onSubmit={onSubmit}
      validator={validator}
      // focusOnFirstError
      noHtml5Validate
    />
    }
    </div>
  );
}
export default CredentialForm;