import requests from "../Api/requests";
import Cookies from 'js-cookie';
import domtoimage from 'dom-to-image';
import { QRCode } from 'react-qrcode-logo';
import Logo from '../assets/images/icon.png';
async function generateQRCode(data) {
    try {
        console.log("Data:",data);
        const res = fetch(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/offers/generate-qr-code`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({data})
        })
            .then(data =>{
                if(data.status === 200){
                    return data.json();
                }else{
                   return {error:"Error in generating QR Code"};
                }
            } );
        console.log("Res:",res);
        return res;
    } catch (error) {
        console.log("Error:dwdwd");
        return {error:error};
    }
}
//NOT WORKING
export async function generateQRCodeBlob(data) {
    try{
    const uri  = (await generateQRCode(data)).uri;
        if(!uri){
            console.log("Error: No URI");
            return {error:"No URI"};
        }
        
        const code = <QRCode value={uri} id='qrCodeEl' size={500} quietZone={10} 
        logoImage={Logo} 
        bgColor='#F0F0F0'
        //  logoPadding={3} 
        qrStyle='dots'
        eyeRadius={3}
        eyeColor={'#404040'}
        // logoPaddingStyle='circle'
        logoOpacity={1}
        logo
        cloneNode={true}
        />
        console.log("QR Code:",code);
        const blob = await domtoimage.toBlob(document.getElementById('qrCodeEl'))
            console.log("Blob:",blob);  
            return blob;
    }catch(err){
        console.log("Error:",err);
        return {error:err};
    }
}

export default generateQRCode;