import * as vc from '@digitalbazaar/vc';
import {DataIntegrityProof} from '@digitalbazaar/data-integrity';
import axios from 'axios';
import {cryptosuite as eddsaRdfc2022CryptoSuite} from '@digitalbazaar/eddsa-rdfc-2022-cryptosuite';
const {extendContextLoader} = require('jsonld-signatures');

const checkStatus = (response) => {
    return true;
}


export async function VerifyCredential(credential) {
    try {
        const {
            createDiscloseCryptosuite,
            createSignCryptosuite,
            createVerifyCryptosuite
          } = eddsaRdfc2022CryptoSuite;
          
          const suite = new DataIntegrityProof({
            cryptosuite: eddsaRdfc2022CryptoSuite,
            // cryptosuite: createVerifyCryptosuite()
          });

          // @digitalbazaar/vc exports its own secure documentLoader.
            const {defaultDocumentLoader} = vc;
            // a valid json-ld @context.
            // const myCustomContext = await axios.get('https://www.w3.org/2018/credentials/v1');
            const myCustomContext = (await axios.get('https://purl.imsglobal.org/spec/ob/v3p0/context-3.0.3.json')).data;
            console.log('My Custom Context:',myCustomContext);
            const documentLoader = extendContextLoader(async url => {
                console.log('URL:',url);
            if(url === 'did:test:context:foo') {
                return {
                contextUrl: null,
                documentUrl: url,
                document: myCustomContext
                };
            }
            return defaultDocumentLoader(url);
            });

            console.log('Document Loader:',documentLoader, 'Credential:',credential);
          const result = await vc.verifyCredential({credential, suite, documentLoader,checkStatus});
          console.log('Result:',result);
            return{result:result};
    } catch (error) {
        console.error('Error:', error);
        return{error:error};
    }
}