import {useContext,useState} from 'react';
import {AppContext} from '../../AppContext';
import './AccountView.css';
import deleteUser from '../../Hooks/delete';
import { useCookies } from 'react-cookie';
import updateUser from '../../Hooks/patchRequest';
import updateClient from '../../Hooks/updateClient';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import RequestHandler from './RequestHandler/RequestHandler';
import { title } from 'process';
import FileUpload from '../FileUpload/FileUpload';
import requests from '../../Api/requests';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import{ Link} from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { FormHelperText } from '@mui/material';
import logo from '../../assets/images/icon.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UserView from './UserView';
import OrgView from './OrgView';




/**
 * React for viewing and editing account data. Maily uses the <OrgView/> and <UserView/> components.
 * 
 * @returns {JSX.Element} The rendered component.
 */
function AccountView() {

    const defaultTheme = createTheme();

    const {client,setClient} = useContext(AppContext);
    const {user,setUser} = useContext(AppContext);
    const path = useLocation().pathname
    // console.log("Path:",path);

    //only applies if the path is /account
    return (path.toLocaleLowerCase()  ==='/account'?<>
        <ThemeProvider theme={defaultTheme}>
        
        <Grid container component="main" sx={{ height: '100vh',width:window.innerWidth,backgroundColor:'red' }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={6}
            pt={7}
            sx={{
              width: '100%',
              backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            pl={10}
            pb={5}
          >
            
            <OrgView client={client} setClient={setClient}/>

          </Grid>

        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square pt ={5} pl={10}
                    sx={{
                        backgroundColor: (t) =>
                          t.palette.mode === 'light' ? t.palette.grey[100] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        overflowY:'scroll'
                      }}
        > 
        <UserView user={user} setUser={setUser} />
        
          </Grid> 
        

        </Grid>
          </ThemeProvider>
        </> : <>{window.location.reload()}</>);
        }

export default AccountView;