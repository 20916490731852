import React, { useEffect,useRef } from 'react';
import Popover from '@mui/material/Popover';
import { Button, Grid, Link, Paper, Stack, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { P2,H2 ,P3,P, H1} from '../MUI/Typography';
import {typeNames} from './tableData';
import Box from '@mui/material/Box';
import ReactJson from 'react-json-view';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import {CircularProgress} from '@mui/material';
import generateQRCode from '../../Hooks/generateQRCode.js';
import requests from '../../Api/requests.js';
import Logo from '../../assets/images/icon.png';

import { QRCode } from 'react-qrcode-logo';
import { DownloadOutlined } from '@ant-design/icons';
import OfferCard from '../MUI/OfferCard.js';
import NestedCredentialCard from '../RecievedCredentialsComponent/NestedCredentialCard.js';
import { useState } from 'react';
import { JSONPath } from 'jsonpath-plus';
import { getDescriptor } from '../CredentialForm/CredentialSection.jsx';
import { Switch, FormControlLabel } from '@mui/material';
import { ObjectDisplayer } from '../MUI/InfoButtonComponent.js';
export const Img= ({ data }) => <img src={`data:image/png,${data}`} alt='QR Code Failed' style={{width:'300px'}}/>


const OfferView = (props) => {
  const [url, setUrl] = React.useState(undefined);
  const[offerLink,setOfferLink] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const {view,...jsonView} = props.useView;
  const [error, setError] = React.useState(undefined);
  const ref = useRef();
  const [useJSON,setUseJSON] = useState(false);

  const [descriptor,setDescriptor] = useState(undefined);
  // console.log('credential:',rawCredential)
  //respolves path from descriptor
  const resPath = (path) => {
      if(!path?.[0]){return undefined;} 
      // console.log('Path:',path?.[0].replace('$.',''),"Result:",props.useView?.[path?.[0].replace('$.','')]);
      return JSONPath({path: path?.[0], json:props.useView}) || 'undefined';
  } 

  //fetches data from the registry and descriptor from local files
  useEffect(() => {
      getDescriptor(props.useView?.type).then((data) => {
          setDescriptor(data);
          console.log('Descriptor:',data,'Data:',props.useView?.type);
      }).catch((e) => console.log('Error:', e));
  }, []);




  const handleDownload = () => {
		ref.current?.download('png',`${jsonView.email}-offer-QRCode.png`);
	}

    const closeView = () => {
        props.setView(undefined);
    };
    console.log("OfferView:",props.useView);
    // console.log("OfferView:",props);
    const generateQR = async () => {
      await setLoading(true);
      const response = await generateQRCode(props.useView).catch(err => console.log("Error:",err));
     if(response?.error || !response){
        setError( (JSON.stringify(response.error?.message || response.error) || "Error in generating QR code"));
        return;
      }
      const enc_url = response?.uri;
      if(response?.uri){
        console.log("URL:",enc_url);
        setUrl(enc_url);
      }
      if(response?.url){
        setOfferLink(response.url);
      }
      return response.url;
    }
    useEffect(() => {
      if(loading){
        setLoading(false);
      }
    },[url]);
    return (<>
        <Popover 
  anchorReference="anchorPosition"
  open={Boolean(props.useView)}
  anchorPosition={{ top: 0, left: 0 }}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'left',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'left',
  }}
  onClose={closeView}
>{props.useView &&
 <Stack
 width={window.innerWidth-80}
 height={window.innerHeight-80}
 justifyContent='space-between'
 direction={{xs:'column',sm:'column',md:'row'}}
 >
    <Stack ml={5} direction={{sm:'column',md:'row'}} pt={{lg:10,xl:0}} width='100%'>
      <Box pt={5} width='100%'>
        {/* <OfferCard offerData={props.useView} width='100%' height={window.innerHeight-120}/> */}
        

        <FormControlLabel
        color='grey.700'
          control={<Switch checked={useJSON} onChange={() => setUseJSON(!useJSON)} />}
          label={<H2 pb={1}>View as Simplified JSON</H2>}
        />
        {useJSON ?<Box sx={{overflow:'scroll'}} height={'100%'}> <ObjectDisplayer src={jsonView}/></Box>:<>
        <Stack spacing={4}>
        <H1 sx={{textDecoration:'underline'}}>{props.useView?.type} Credential</H1>
        {jsonView?.nonVelocity && <Stack direction={'row'} spacing={1}><H2>Type: </H2><H2 sx={{textDecoration:'underline'}}color='awrning'> Non-Velocity Credential</H2></Stack>}
        <P2>Issued to: {jsonView?.email || jsonView?.credentialSubject?.vendorUserId}</P2>
        </Stack>
        <H2>More Details:</H2>
          {!Array.isArray(jsonView?.type) ? <Grid component={Paper} elevation={4} container sx={{bgcolor:'#fcfcfc'}} p={2}  alignContent='' width='100%' >
              {descriptor?.properties?.map((property,index) => {

                  const value = resPath(property.path)
                  return(value?.length>0 && value[0]!=='' &&<>
                      <Grid item xs={2.5}>
                              <H1>{property.label}:</H1>
                          </Grid>
                      {/* <Stack key={index} direction='row' spacing={1} alignItems='center' justifyItems='center' textAlign='center'  pl={2}> */}
                      <Grid item xs={9.5}>
                          {value?.map((val,index)=>
                              val?.startsWith('http')?
                              val?.endsWith('.png') || val?.includes('.jpg')?
                              <img src={val} alt={val} width={200} height={200}/>
                              :<Link href={val} target='_blank' noWrap height='100%' fontSize={{sm:15,md:17,lg:20}}>{val}</Link>
                              :val?.length > 70 ? <P sx={{fontSize:15}}>{val}</P>
                              :<P2 sx={{fontSize:{sm:15,md:20,lg:25}}}>{val}</P2>
                          )}
                      
                      </Grid>
                  </>)
              })}
          </Grid> : <OfferCard offerData={props.useView} width='100%' height={window.innerHeight-120}/>}</>}





        </Box>
        {(url || loading || error) &&<Stack pt={{lg:0,xl:10}} ml={10}>
          <H2>Offer QR Code:</H2>
          {url ? <><QRCode value={url} id='qrCodeEl' size={500} quietZone={10} 
          ref={ref}
          logoImage={Logo} 
          bgColor='#F0F0F0'
          //  logoPadding={3} 
          qrStyle='squares'
          eyeRadius={3}
          eyeColor={'#404040'}
          // logoPaddingStyle='circle'
          logoOpacity={1}
          logo
          fgColor='#404040'
          // logoHeight={580}
          // logoWidth={580}
          />
          {offerLink &&   <a href={offerLink} style={{color:'coral'}} target='_blank'>Link to Offer</a>}




            <Button fullWidth variant='outlined' pt={3} endIcon={<DownloadOutlined/>} onClick={handleDownload}>Download</Button>
          </> : 
          <Box width='500px' height='500px' border={1} sx={{justifyContent:'center',alignItems:'center'}} display='flex'>
          {error ? <Typography variant='h6'color='error'>{error}</Typography>:<CircularProgress size={60}/>}
          </Box>}
        </Stack>}
    </Stack>
    <Box >
    <Stack flexDirection='column-reverse' p={5} spacing={5} >
      <Stack spacing={3} position='absolute' p={3}
      component={Paper}
      sx={{
        // position:'fixed',
        bottom:10,
        right:'0',
      }}>
    {Object.keys(jsonView).includes('offerCreationDate') &&  <Button onClick={()=>generateQR()} variant='outlined' fullWidth color='inherit' height='100%' endIcon={<QrCodeScannerIcon/>}>Generate QR code</Button>}
    <Button onClick={closeView} variant='contained' fullWidth color='error' endIcon={<CancelIcon/>}>Close</Button>
    </Stack></Stack></Box>
 </Stack>}
</Popover>
    </>);
};

export default OfferView;