import React, { cloneElement } from 'react';
import { IssueNavLinks } from '../Navbar/Nav';
import LibraryAddCheckIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import { Badge, Stack, Tooltip } from '@mui/material';
import { allOptions, extensions, hyphenToCamel } from '../CredentialForm/CredentialSection';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import VerifiedIcon from '@mui/icons-material/Verified';
import QuizIcon from '@mui/icons-material/Quiz';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import SchoolIcon from '@mui/icons-material/School';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import ArchitectureOutlinedIcon from '@mui/icons-material/ArchitectureOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';


export const getIcon = (type) => {
    var icon= <LibraryAddCheckIcon fontSize='large' />;
    for (let i = 0; i < IssueNavLinks.length; i++) {
        if(IssueNavLinks[i].key.includes(type.split(' ')[0].toLowerCase()) || type.includes(IssueNavLinks[i].key)){
            icon=IssueNavLinks[i].icon;
            break;
        }
    }
    return icon;
}

export const credentialSubjectTypes = {
    'EmploymentCurrentV1.1':'Employment',
    'EmploymentPastV1.1':'Employment',
    'OpenBadgeV2.0':'Badge',
    'AssessmentV1.1':'Assessment',
    'CourseAttendanceV1.1':'Course',
    'CourseCompletionV1.1':'Course',
    'CourseRegistrationV1.1':'Course',
    'CertificationV1.1':'CertificationLicense',
    'EducationDegreeRegistrationV1.1':'EducationDegree',
    'EducationDegreeStudyV1.1':'EducationDegree',
    'EducationDegreeGraduationV1.1':'EducationDegree',
    'LicenseV1.1':'CertificationLicense',
}
export const iconData = [
    { key: 'Employment', name: 'Employment',icon: <HowToRegIcon fontSize='large'/> ,closeIcon:<HowToRegOutlinedIcon/>},
    { key: 'Badge', name: 'Badge',icon: <VerifiedIcon fontSize='large'/>,closeIcon:<VerifiedOutlinedIcon/>},
    { key: 'Assessment', name: 'Assessment' ,icon: <QuizIcon fontSize='large'/>,closeIcon:<QuizOutlinedIcon/>},
    { key: 'CertificationLicense', name: 'Certifications',icon: <VerifiedUserIcon fontSize='large'/>,closeIcon:<VerifiedUserOutlinedIcon/>},
    { key: 'Course', name: 'Courses',icon: <ArchitectureIcon fontSize='large'/>,closeIcon:<ArchitectureOutlinedIcon/>},
    { key: 'EducationDegree', name: 'Education',icon: <SchoolIcon fontSize='large'/> ,closeIcon:<SchoolOutlinedIcon/>},
  ];



const getCredType = (type) => {
    if(type === undefined){return undefined;}
    if(credentialSubjectTypes[type]){console.log(credentialSubjectTypes[type]);return credentialSubjectTypes[type];}
    const hyphenType = Object.keys(hyphenToCamel).find((key) => hyphenToCamel[key] === type);
    const noExtension = Object.keys(extensions).find((key) => extensions[key] === hyphenType);
    var credType = type;
    // console.log('HythpenType:',hyphenType,'NoExtension:',noExtension);
    allOptions.forEach((option) => {
        if (option.value.indexOf(noExtension) > -1) {
            credType = option.key;
            // console.log('Option:',option.value.indexOf(noExtension),noExtension);
        }
    });
    // console.log('CredType:',credType);
    return credType;
}
const getCredentialBreakdown = (credential) => {
    const types = {};
    for (let i = 0; i < credential?.length; i++) {
        const cred = credential[i];
        if (cred.credentialSubject?.type) {
            if (types?.[getCredType(cred.credentialSubject.type)]?.number) {
                types[getCredType(cred.credentialSubject.type)].number++;
                types[getCredType(cred.credentialSubject.type)].index.push(i);
            }else{
                types[getCredType(cred.credentialSubject.type)]={number:1,index:[i],type:cred.credentialSubject.type};
            }
        }
    }
    // return Object.keys(types).map((key) => <P>{`(${types[key]}) ${key}`}</P>);
    return types;
}

export const getCredentialStatusColor = (credential) => {
    // console.log('Credential:',credential);
    if(credential === undefined){return undefined;}
    const credentialChecks = credential.credentialChecks;
    if(credentialChecks === undefined){return undefined;}
    var color = 'success';
for (const key in credentialChecks) {
    if (credentialChecks[key] !== 'PASS' && credentialChecks[key] !== 'NOT_APPLICABLE') {

        
        // console.log('Failed:', key, credentialChecks[key]);
        if (key === 'UNREVOKED') {
            color = 'error';
            console.log('Revoked:', key, credentialChecks[key]);
            return color;
        }else{
            color = color === 'success' ? 'error' : 'warning';
        }
    } else {
        // if passed
        color = color === 'error' ? 'warning' : color;
    }
}
    return color;
}


const IconBreakdown = ({credentials}) => {
    const [breakdown,setBreakdown] = React.useState(undefined);
    React.useEffect(() => {
        if(credentials){
            setBreakdown(getCredentialBreakdown(credentials));
            // console.log('Breakdown:',breakdown);
        }
    }, [credentials]);
    return (
        <Stack direction='row' spacing={2} pt={1}>
            {iconData.map((link,index) => {
                const num = breakdown ? breakdown[link.key]?.number : 0;
                const color = getCredentialStatusColor(credentials[breakdown?.[link.key]?.index?.[0]]) || 'error';
             return (

             <Tooltip title={link.name+": "+(num||'0')} placement="top" arrow>
                <Badge badgeContent={num} color={color} >
                {cloneElement(link.icon,{color:(num>0?'inherit':'disabled'),fontSize:'large'})}
                </Badge>
             </Tooltip>)
})}
        </Stack>
    );
};

export default IconBreakdown;