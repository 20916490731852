import requests from '../Api/requests'
import Cookies from 'js-cookie';
import merge from 'deepmerge';

async function sendOffer(data) {
    try{
        return fetch(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/offers`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
          .then(data => data.json());
    }catch(err){
        console.log("error");
        return {error:err};
    }
}
export default sendOffer
export const proccessOfferFormData=(formData)=>{
    console.log("Form Data:",formData);
    console.log("Type:",formData.type);
        switch (formData.type) {
            case 'OpenBadgeV2.0':
                
            case 'AssessmentV1.1':
                
            case 'EmploymentCurrentV1.1':
            case 'EmploymentPastV1.1':
 
            case 'EducationDegreeGraduationV1.1':
            case 'EducationDegreeStudyingV1.1':
            case 'EducationDegreeRegistrationV1.1':

            case 'CourseAttendanceV1.1':
            case 'CourseCompletionV1.1':
            case 'CourseRegistrationV1.1':

            case 'CertificationV1.1':
            case 'LicenseV1.1':
                return proccessAdvancedOfferData(formData);
            default:
                console.log('Invalid form data type');
                return {error: `Invalid form data type ${formData.type}`};
        }
}
export const proccessAdvancedOfferData = (formData) => {
    try{
        if(!formData || !formData.type || !formData.email){
            console.log("Form Data Invalid");
            return {error:"Form Data Invalid"};
        }
        const data = {
            type: formData.type,

        ...formData};  
        data.offerId = Math.floor(Math.random() * 100000000);
        data.exchangeId = Math.floor(Math.random() * 100000000);
        data.offerCreationDate = new Date().toISOString();
        var date = new Date();
        date.setFullYear(date.getFullYear() + 10);
        data.offerExpirationDate = date.toISOString();
        return data;
    }catch(err){
        console.log("Error in processing the data",err);
        return {error:err};

    }
}
export const proccessAdvancedEmploymentOfferFormData = (formData) => {

    return proccessAdvancedOfferData(formData);
}
export const proccessEducationOfferFormData = (formData) => {
    try{
        if(!formData || !formData.type || !formData.email){
            console.log("Form Data Invalid");
            return {error:"Form Data Invalid"};
        }
    const data = {
        type: formData.type,
        email: formData.email,
    ...formData};  
    data.offerId = Math.floor(Math.random() * 100000000);
    data.exchangeId = Math.floor(Math.random() * 100000000);
    data.offerCreationDate = new Date().toISOString();
    var date = new Date();
    date.setFullYear(date.getFullYear() + 10);
    data.offerExpirationDate = date.toISOString();
    return data;
}catch(err){
    console.log("Error in processing the data",err);
    return {error:err};
}
}


export const proccessBadgeOfferFormData = (formData) => {
    try{
            if (!formData || !formData.type || !formData.email || !formData.selectComponent || !formData.description || !formData.narrative) {
                if (!formData.type) {
                    console.log("Type is missing");
                }
                if (!formData.email) {
                    console.log("Email is missing");
                }
                if (!formData.selectComponent) {
                    console.log("Select Component is missing");
                }
                if (!formData.description) {
                    console.log("Description is missing");
                }
                if (!formData.narrative) {
                    console.log("URL is missing");
                }
                console.log("Form Data Wrong",formData);
                return {error:"Form Data Invalid"};
            }
            if (!formData.defaultImage) {
                try {
                    new URL(formData.imageUrl);
                    new URL(formData.narrative);
                } catch (_) {
                    console.log("Invalid URL");
                    return {error:"Invalid URL"};
                }
            } else {
                formData.imageUrl = "https://www.velocitynetwork.foundation/wp-content/uploads/2020/02/fb.jpg";
            }
        const data = {
            type: formData.type,
            // type: 'BadgeOffer',    
            email: formData.email,
            hasCredential:{
                type: "BadgeClass",
                name: formData.name,
                description: formData.description || formData.title,
                criteria:formData.narrative,
                image: formData.imageUrl,
            }
        }  
        data.offerId = Math.floor(Math.random() * 100000000);
        data.exchangeId = Math.floor(Math.random() * 100000000);
        data.offerCreationDate = new Date().toISOString();
        var date = new Date();
        date.setFullYear(date.getFullYear() + parseInt(formData.selectComponent));
        data.offerExpirationDate = date.toISOString();
        return data;
    }catch(err){
        console.log("Error in processing the data",err);
        return {error:err};
    }
}
export const proccessAssessmentOfferFormData = (formData) => {
    try{
        if(!formData || !formData.type || !formData.email){
            console.log("Form Data Invalid");
            return {error:"Form Data Invalid"};
        }
        formData.type = "AssessmentV1.1";
        formData.offerId = Math.floor(Math.random() * 100000000);
        formData.exchangeId = Math.floor(Math.random() * 100000000);
        formData.offerCreationDate = new Date().toISOString();
        var date = new Date();
        date.setFullYear(date.getFullYear() + parseInt('10'));
        formData.offerExpirationDate = date.toISOString();
        return formData;

    }catch(err){
        console.log("Error in processing the data",err);
        return {error:err};
    }

}
export const proccessEmploymentOfferFormData = (formData) => {
    try{
        if(!formData || !formData.type || !formData.title || !formData.email || !formData.startDate || !formData.selectComponent ||
             !formData.givenName || !formData.familyName || !formData.employmentType?.length){
            console.log("Form Data Invalid");
            return {error:"Fields are Missing"};
        }
    const data = {
        type: formData.type,
        title: formData.title,
        description: formData.description || formData.title,
        email: formData.email,
        startDate:formData.startDate.toISOString().split('T')[0],
        recipient:{
            type: "PersonName",
            givenName: formData.givenName,
            familyName: formData.familyName
        },
        employmentType: formData.employmentType
    };  
    if(formData.type === "EmploymentPastV1.1"){
        data.endDate = formData.pastDate.toISOString().split('T')[0];
    }
    data.offerId = Math.floor(Math.random() * 100000000);
    data.exchangeId = Math.floor(Math.random() * 100000000);
    data.offerCreationDate = new Date().toISOString();
    var date = new Date();
    console.log(formData.selectComponent);
    date.setFullYear(date.getFullYear() + parseInt(formData.selectComponent));
    data.offerExpirationDate = date.toISOString();
    return data;
}catch(err){
    console.log("Error in processing the data",err);
    return {error:err?.message || 'Error in processing the data'};
}
}