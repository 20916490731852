import React, { useState,useContext } from 'react';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import sendOffer,{ proccessEmploymentOfferFormData } from '../../Hooks/sendOffer';
// import PickerWheel from '@codeurs/react-simple-wheel-picker';
// import './CredentialSelection.css';
import { FormHelperText } from '@mui/material';
import { Box, TextField } from '@mui/material';
import {H3,H2,P2} from '../MUI/Typography';
import { DesktopDatePicker as DatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider} from "@mui/x-date-pickers";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import MultiSelect from '../MUI/MultiSelect'; 
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import NotifyPopup from '../MUI/NotifyPopup';
const EmploymentTypes = [
    "Contract",
    "Temporary",
    "Permanent",
    "Internship",
    "Full-time",
    "Part-time",
    "Other"
];

const OfferForm = ({alignments}) => {
    // console.log("OfferForm",window.innerWidth);
    const theme = useTheme();
    const expireOptions = Array.from({length: 10}, (_, i) => i + 1).map((data)=>{return{id:data, value:data}});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    // console.log(expireOptions);
    const defaultData = {
        type: 'EmploymentCurrentV1.1',
        selectComponent: 2,
    }
    const [formData, setFormData] = useState(defaultData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        // console.log(formData);
    };

    const handleSubmit = (e) => {
        setError(null);
        setSuccess(null);
        e.preventDefault();
        console.log(formData);
        const sendData = proccessEmploymentOfferFormData(formData);
        if(sendData.error){
            setError(sendData.error || "Error in processing the Offer");
            return;
        }
        // console.log(sendData);
        if(alignments?.length >0){
            sendData.alignment = alignments;
        }
        const res = sendOffer(sendData);
        console.log(res);
        res.catch((err) => {
            setError(err || "Error in sending the offer");
        });
        res.then((data) => {
            if(data.error){
                setError(data.error || "Error in sending the offer");
            }else{
                setFormData(defaultData);
                setSuccess(true);
            }
        })
    };

    return (<>

              {/* <FormHelperText sx={{color: 'red'}}>{""}</FormHelperText> */}
        
        <Box component="form" onSubmit={handleSubmit} sx={{height:'68vh' }} title ='Employment' width={'100%'} >
        <Box component={Paper} px={3} mb={5} sx={{backgroundColor:theme.palette.mode === 'dark' && theme.palette.grey[800]}}>
        <H2 style={{alignSelf : 'center'}}>{formData.type === 'EmploymentCurrentV1.1' ? "Current Employment" : "Past Employment"}</H2>
                <TextField
                value={formData.title || ''}
                margin="normal"
                required
                fullWidth
                variant="outlined"
                id="title"
                label="Job Title"
                name="title"
                autoComplete="title"
                autoFocus
                onChange={handleChange}
              />
                <TextField
                value={formData.givenName || ''}
                margin="normal"
                required
                fullWidth
                variant="outlined"
                id="givenName"
                label="Given Name"
                name="givenName"
                autoComplete="givenName"
                autoFocus
                onChange={handleChange}
              />
                <TextField
                value={formData.familyName || ''}
                margin="normal"
                required
                fullWidth
                variant="outlined"
                id="familyName"
                label="Family Name"
                name="familyName"
                autoComplete="familyName"
                autoFocus
                onChange={handleChange}
              />
                <TextField
                value={formData.email || ''}
                margin="normal"
                required
                fullWidth
                variant="outlined"
                id="email"
                type='email'
                label="Recipient Email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
              />
                <TextField
                value={formData.description || ''}
                margin="normal"
                // required
                fullWidth
                variant="outlined"
                id="description"
                label="Short Description"
                name="description"
                autoComplete="description"
                autoFocus
                onChange={handleChange}
              />
              <MultiSelect
              fullWidth={true}
              options={EmploymentTypes}
              name="Employment Type"
              onChange={(e)=>setFormData((prevData) => ({ ...prevData,employmentType: e.target.value }))}
              />
            <Stack direction='row' spacing={2}>
                <H2 htmlFor="dropdown">Type:</H2>
                <select
                    id="type"
                    name="type" // Update the name attribute to "type"
                    className='typeDropDown'
                    value={formData.type || ''}
                    onChange={handleChange}
                >
                    <option value="EmploymentCurrentV1.1">Current Employment</option>
                    <option value="EmploymentPastV1.1">Past Employment</option>
                </select>
            </Stack>
                <Stack direction='row' py={2} spacing={2}>
                    <H2 htmlFor="startDate">Start Date:</H2>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {window.innerWidth  >1000 ?<DatePicker
                        fullWidth
                        id="startDate"
                        name="startDate"
                        selected={formData.startDate}
                        onChange={(date) => setFormData((prevData) => ({ ...prevData,startDate: date }))}
                    /> : <MobileDatePicker
                    fullWidth
                    id="startDate"
                    name="startDate"
                    selected={formData.startDate}
                    onChange={(date) => setFormData((prevData) => ({ ...prevData,startDate: date }))}
                    />}
                    </LocalizationProvider>
                </Stack>
                {formData.type === "EmploymentPastV1.1" && (
                    <Stack direction='row' spacing ={2}>
                        <H2 htmlFor="pastDate">End Date:</H2>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                        {window.innerWidth  >1000 ? <DatePicker
                            id="pastDate"
                            name="pastDate"
                            selected={formData.pastDate}
                            onChange={(date) => setFormData((prevData) => ({ ...prevData, pastDate: date }))}
                        /> : <MobileDatePicker
                        id="pastDate"
                        name="pastDate"
                        selected={formData.pastDate}
                        onChange={(date) => setFormData((prevData) => ({ ...prevData, pastDate: date }))}
                        />}
                        </LocalizationProvider>
                    </Stack>
                )}
                <div>
                <Stack direction='row' spacing={2} py={3}>
                    <H2>Expires in:</H2>
                    <select
                        id="selectComponent"
                        name="selectComponent"
                        className = 'expireDropDown'
                        value={formData.selectComponent || ''}
                        onChange={handleChange}
                    >
                        {expireOptions.map((data) =>{ return(<option key={data.id} value={data.id}>{data.value} Years</option>)})}
                    </select>
                </Stack>
                
            </div>
            {error && <NotifyPopup message={error} severity='error' onClose={()=>setError(undefined)}/>}
            {success && <NotifyPopup message={'Offer Created Successfully'} severity='success' onClose={()=>setSuccess(undefined)}/>}
            <Button type="submit" sx={{alignSelf:'center',minWidth:'10rem'}} variant='contained' endIcon={<SendIcon/>}>Submit</Button>
        </Box>
        </Box>
        </>);
};

export default OfferForm;