
import React, { useEffect } from 'react';
import { Box, Button, Stack } from '@mui/material';
import EyeIcon from '@mui/icons-material/Visibility';
import PopoverComponent from '../MUI/PopoverComponent';
import { H2 } from '../MUI/Typography';
import axios from 'axios';
import requests from '../../Api/requests';
import Table from '../Table/Table';
import { getIssuedRows, getRows } from '../Table/tableData';
import OfferView from '../Table/OfferView';
/**
 * Renders a component that allows viewing data as a user.
 * 
 * @param {Object} user - The user object.
 * @returns {JSX.Element} The rendered component.
 */
const ViewAsUser = ({user}) => {
    const [open, setOpen] = React.useState(false);
    const [selectOption, setSelectOption] = React.useState('offers');
    const [data, setData] = React.useState(undefined);
    const [view, setView] = React.useState(undefined);
    useEffect(() => {
       if(open){
        setData(undefined);
            if(selectOption==='offers'){
                axios.get(`${requests.baseURL}/api/${user?.authentication?.sessionToken}/offers`).then((data) => {
                    const dataToSet = data.data;
                    for(const d of dataToSet){
                        d.removeOffer = ()=>{};
                        d.view = setView;
                        d.canRemove = true;
                        d.emailLoading =  false;
                        d.emailSent = false;
                      }
                    setData(dataToSet);
                    console.log(data.data);
                }).catch((err) => {
                    console.log(err);
                });
            }else{
                axios.get(`${requests.baseURL}/api/${user?.authentication?.sessionToken}/credentials`).then((data) => {
                    setData(data.data);
                    console.log(data.data);
                }).catch((err) => {
                    console.log(err);
                });
            }
            
       }
    }, [selectOption,open]);
    return (
       !open? <Button
            variant="outlined"
            color="primary"
            fullWidth
            endIcon={<EyeIcon />}
            onClick={() => setOpen(true)}
        >
            View Data As User
        </Button> : <PopoverComponent open={open} onClose={()=>setOpen(false)}>
        <H2>View As User</H2>
<Stack direction="row" spacing={2} pt={0}>
    
        <Button variant="outlined" color={selectOption==='offers' ? 'secondary' : 'primary'}  fullWidth
        onClick={()=>setSelectOption('offers')}
        >
            Offers Created
        </Button>
        <Button variant="outlined" color={selectOption==='offers' ? 'primary' : 'secondary'} fullWidth onClick={()=>setSelectOption('issued')}>
            Credentials Issued
        </Button>
</Stack>    
<Box width='100%' height={window.innerHeight-400}>
        <Table rows={selectOption==='offers' ? getRows(data) : getIssuedRows(data)}/>
</Box>
{view && <OfferView useView={view} setView={setView} />}
        </PopoverComponent>
    );
};

export default ViewAsUser;