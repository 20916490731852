



const get = (props={url:''}) => {
    // console.log("Performing GET on:",`${props.url}`);
    try{
    return fetch(`${props.url}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(data =>{
        // console.log("Data:",data.text());
      return data.json()
    }).catch(err => console.log("Error getting:",{err:err.stack}));
  }catch(err){
    console.log("error");
  }
  
  }

  export default get;