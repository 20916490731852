import React from 'react';
import {useState} from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import OfferView from './OfferView';
import { head } from './tableData';
import './Table.css';
import {H3,H1,H2} from '../MUI/Typography';
import { Box } from '@mui/system';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Button from '@mui/material/Button';
import { NotFoundComponent } from '../CredentialEngineSearch/CredentialEngineSearch';

const Table = (props) => {
  const [anchor, setAnchor] = useState(null);
  // console.log("Table",props.rows);
  return (<Stack  pl ={2} sx={{justifyContent:'center',
  width:'100%',
 
  alignContent:'center',
  alignSelf:'center'
  }}
  className='table-container'
   >

    
    <DynamicTable
      head={props.head || head}
      rows={props.rows}
      rowsPerPage={10}
      defaultPage={1}
      loadingSpinnerSize="large"
      isLoading = {props.isLoading}
      emptyView={<NotFoundComponent title={props.placeHolder || 'Nothing to see here. Created offers can be seen here'}/>}
      isRankable
      defaultSortKey='date'
      
    />

    {/* {props.canRemove && <Button color='error' onClick={props.removeOffer} endIcon={<HighlightOffRoundedIcon/>}
    variant='outlined'
    sx={{
      padding: '10px',
      width:'200px'
    }}
    >Delete All</Button>} */}
  </Stack>);
}
export default Table;