/**
 * This file contains the functions that parse the credential from the CredEngine format to the Velocity format
 */



import axios from "axios";
import { countryRegions } from "../Components/MUI/CountryPicker";


const locale = (value) => {
  return value?.['en-us'] || value?.['en-US'] || value
};

/**Parses a credential from the CredEngine format to 
 * the Velocity format see mapping table here 
 * https://docs.google.com/spreadsheets/d/1O7qxv1NS4GFfLkaLWRGpOKDXKzxHNF_wpxJ6LNoFE5A/edit?gid=0#gid=0
 * */
export const CredEngineToVelocity = async(credential,velocityType)=>{
var result ={description:locale(credential?.['ceterms:description'])};
console.log("CredEngineToVelocity :",credential,velocityType);
const ownedBy = await Promise.all(credential?.['ceterms:ownedBy']?.map(async(data)=>(await axios.get(data))?.data));
const organization = {
  type:'Organization',
  name:locale(ownedBy?.[0]?.['ceterms:name']),
  // description:locale(ownedBy?.[0]?.['ceterms:description']),
  place:{
    type:'Place',
  }
}
const country = locale(ownedBy?.[0]?.['ceterms:address']?.[0]?.['ceterms:addressCountry']);
const locality = locale(ownedBy?.[0]?.['ceterms:address']?.[0]?.['ceterms:addressLocality']);
const region = locale(ownedBy?.[0]?.['ceterms:address']?.[0]  ?.['ceterms:addressRegion']);
console.log("Country:",country);
if(country){
  switch(country){
    case 'United States':
    case 'USA':
    case 'US':
      organization.place.addressCountry = 'US';
      break;
    case 'Canada':
    case 'CA':
      organization.place.addressCountry = 'CA';
      break;
  }
}
if(region && organization?.place?.addressCountry){
  organization.place.addressRegion = countryRegions[organization.place.addressCountry].find((data)=>data.title === region)?.key;
}
if(region?.length === 2){
  organization.place.addressRegion = region;
}
if(locality){
  organization.place.addressLocality = locality;
}
const deliveryType = locale(credential?.["ceterms:learningDeliveryType"]?.[0]?.["ceterms:targetNodeName"]) || credential?.["ceterms:learningDeliveryType"]?.[0]?.["ceterms:targetNode"]?.replace('deliveryType:','');

switch(velocityType){
  case 'assessment':
    result={...result,
      authority : organization,
      name:locale(credential?.['ceterms:name']), 
    };
    if (deliveryType) result.assessmentMethod = deliveryType;

break;
  case 'certification':
    result={...result,
      authority : organization,
      name:locale(credential?.['ceterms:name']),
    }
    break;
  case 'license':
    result={...result,
      authority : organization,
      name:locale(credential?.['ceterms:name']),
    }
    break;
  case 'course':
    result={...result,
      name:locale(credential?.['ceterms:name']),
      courseCode:credential?.['ceterms:ctid'],
      provider:organization,
      contentProvider:organization,
    }
  if (deliveryType) result.courseMode = deliveryType;

    
    break;
  case 'education-degree':
  result= {...result,
      degreeName:locale(credential?.['ceterms:name']),
      programName:locale(credential?.['ceterms:name']),
      institution:organization,
      school:organization,
      degreeMajor:[
      locale(credential?.['ceterms:name']),
    ]
  }
  if (deliveryType) result.programMode = deliveryType;
  break;
  case 'badge':
    result={
        hasCredential:{
          description:locale(credential?.['ceterms:description']),
          name:locale(credential?.['ceterms:name']),
          issuer:organization,
        }
    }
    break;
}
console.log("Result of CredEngineToVelocity :",result);
return result;
}
