// material-ui
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

// project import
import Search from './Search';
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';

// project import
import { GithubOutlined } from '@ant-design/icons';
import { Stack } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import config from '../../../../config';
import { useContext } from 'react';
import { AppContext } from '../../../../AppContext';
// ==============================|| HEADER - CONTENT ||============================== //

export default function HeaderContent() {
  const downLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const {mode,setMode} = useContext(AppContext);
  return (
    <>
      {!downLG && <Search />}
      {downLG && <Box sx={{ width: '100%', ml: 1 }} />}
      <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" mr={3}>
      <IconButton
        color="secondary"
        title="Change Theme"
        sx={{ color: 'grey.500',
          transition: 'all 0.3s ease-in-out',

        }}
        onClick={() => {mode === 'light' ? setMode('dark') : setMode('light')}}
      >
      {mode === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
      </IconButton>

      <Notification />
      {!downLG && <Profile />}
      {downLG && <MobileSection />}
      </Stack>
    </>
  );
}
