import React, { cloneElement, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteForever from '@mui/icons-material/DeleteForever';
import Box from '@mui/material/Box';
import InfoButtonComponent from '../MUI/InfoButtonComponent';
import NotifyPopup from '../MUI/NotifyPopup';
import { H, H1, H2, P, P2, P3 } from '../MUI/Typography';
import { deleteRecievedCredential, verifyCredential } from '../../Hooks/disclosures/recievedCredentials';
import PopoverComponent from '../MUI/PopoverComponent';
import OfferCard from '../MUI/OfferCard';
import { getDescriptor } from '../CredentialForm/CredentialSection';
import ReactJson from 'react-json-view';
import axios from 'axios';
import requests from '../../Api/requests';
import Cookies from 'js-cookie';
import { Button, CircularProgress, Divider, Grid, Link, Paper, TextField, Tooltip } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GppBadIcon from '@mui/icons-material/GppBad';
import {JSONPath} from 'jsonpath-plus';
import { IssueNavLinks } from '../Navbar/Nav';
import CachedIcon from '@mui/icons-material/Cached';
import CheckIcon from '@mui/icons-material/Check';
import IosShareIcon from '@mui/icons-material/IosShare';
import { getShareCode } from '../../Hooks/disclosures/requests.js';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LibraryAddCheckIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import IconBreakdown, { getIcon } from './IconBreakdown';
import NestedCredentialCard from './NestedCredentialCard.js';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { env } from '../../env.js';
const credentialChecks=[
    {key:'TRUSTED_HOLDER',label:'Holder'},
    {key:'TRUSTED_ISSUER',label:'Issuer'},
    {key:'UNREVOKED',label:'Unrevoked'},
    {key:'UNEXPIRED',label:'Up To Date'},
    {key:'UNTAMPERED',label:'Untampered'},
]


const getFirstName = (credential) => credential?.credentials?.[0]?.credentialSubject?.recipient?.givenName || credential?.credentials?.[1]?.credentialSubject?.recipient?.givenName || 'Unknown';
const getLastName = (credential) => (credential?.credentials?.[0]?.credentialSubject?.recipient?.familyName&&' '+credential?.credentials?.[0]?.credentialSubject?.recipient?.familyName) || ' ';
const getName = (credential) => getFirstName(credential)+getLastName(credential) || 'Unknown';





//tooltip icon for if credential is Verified
export const Verified = ({isVerified,success,error,warning}) => {
    return(isVerified && isVerified!=='error' ? isVerified ==='warning' ?
    <Tooltip title={warning} arrow placement='top'>
        <GppMaybeIcon color='warning'/>
    </Tooltip>:
    <Tooltip title={success} arrow placement='top'>
        <VerifiedUserIcon color='success'/>
    </Tooltip>
    : 
    <Tooltip title={error} arrow placement='top'>
        <GppBadIcon color='error'/>
    </Tooltip>

     ) 
}
export const CredentialCheck = ({checkData,rawCredential}) =>{
    const [loading,setLoading] = useState(false);
    const [checks,setChecks] = useState(checkData);
   
    return(<>
        <Stack direction='row' justifyContent='center'>
                <H1 fontSize={18} color='inherit' border='1px solid gray' borderBottom='none' borderRadius='10px' pb={-2}>{checks?'Verifications:':'Verify Credential?'}</H1>
               {rawCredential&&
               <IconButton title='Re-Verify' onClick={() =>{
                    if(!loading){
                        setLoading(true);
                        console.log('Re-Verifying:',rawCredential);
                        verifyCredential(rawCredential).then((data) => {
                            setLoading(false);
                            setChecks(data);
                        }).catch((e) => {
                            setLoading(false);
                            console.log('Error:', e);
                        });
                    }
                } }>{loading?<div><CircularProgress color='inherit' size={25}/></div> : checks? <CachedIcon /> : <CheckIcon/>}</IconButton>}
            </Stack>
        
        {!checks ? 
        
        <Stack alignItems='center' justifyItems='center'>
        </Stack>
        
        :checks?.TRUSTED_ISSUER === 'VOUCHER_RESERVE_EXHAUSTED' ? 
        <Stack alignItems='center' justifyItems='center'>
            <Stack spacing={1} alignItems='center' justifyItems='center' component={Paper} p={1} direction='row' width='100%'>
                <Box width='100%'>
                    <H2>Credential Cannot Be Verified:</H2>
                    <H2 color='error'>VOUCHER_RESERVE_EXHAUSTED.</H2>
                    <P>Please Log into <a href='https://stagingrewardsapp.velocitycareerlabs.io/' target='_blank'>Velocity Payment Hub</a> to add more Vouchers</P>
                </Box>
            </Stack>
        </Stack>
        :
        <Stack alignItems='center' justifyItems='center' width='100%'>
            <Stack spacing={1} alignItems='center' justifyItems='center' component={Paper} p={1} direction='row' width='100%'>
                {credentialChecks.map((check) => (
                    <Stack direction='row' spacing={1} alignItems='center' justifyItems='' width='100%'>
                        <H2>{check.label}:</H2>
                        <Verified isVerified={checks[check.key] === 'PASS' || checks[check.key] === 'NOT_APPLICABLE'} success={`This Credential ${check.label} Status is: ${checks[check.key]}`} error={"Failed: " + checks[check.key]} />
                    </Stack>
                ))}
            </Stack>
        </Stack>}
    </>);
}



const CredentialCard = ({ credential, onClick, onDelete ,rawCredentials,listId}) => {
    const [error, setError] = useState(undefined);
     const [open, setOpen] = useState(false);
    const [shareLink, setShareLink] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [copied, setCopied] = useState(false);
    const [nestedOpen,setNestedOpen] = useState(credential?.credentials?.map(()=>credential?.credentials?.length>4?false:true));
    // const deleteCredential = async (id) => {
    //     console.log('Delete Credential:', id);
    //     return setError('Not Implemented');
    // };
    // console.log('Credential:',credential);
    useEffect(() => {
        setCopied(false);
        if (shareLink==='loading...') {
            setLoading(true);
            getShareCode(listId).then((data) => {
                setLoading(false);
                data?.error ? setError(data.error) :
                setShareLink(`${env.REACT_APP_FRONTEND_URL}/share?credential=${data.code}`)
                // setShareLink(`http://localhost:3000/share?credential=${data.code}`)
            }).catch((e) => setError(e))
        }
    }, [shareLink]);

    useEffect(() => {
        if(open){
            setNestedOpen(credential?.credentials?.map(()=>credential?.credentials?.length>4?false:true));
        }
    }, [open]);
    return (
        <>
            {error && <NotifyPopup message={error} severity="error" onClose={() => setError(undefined)} />}

            <PopoverComponent open={shareLink} onClose={()=>setShareLink(undefined)}>
                <Stack spacing={3}>
                    <H1 borderBottom='3px solid coral'>Share This Disclosure:</H1>
                <Stack direction='row' spacing={2} alignItems='center' justifyItems='center' textAlign='center' pb={3} minWidth={'500px'}>
                <Box textAlign={'center'}><H2 textAlign='center'>Link:</H2> </Box>
                {/* <Link href={shareLink} target='_blank'>{shareLink}</Link> */}
                <TextField value={shareLink} fullWidth contentEditable={false} />
                <IconButton onClick={() => {navigator.clipboard.writeText(shareLink);setCopied(true)}}>{copied ? <LibraryAddCheckIcon/>:<ContentCopyIcon/>}</IconButton>
                </Stack>
                </Stack>
            </PopoverComponent>
            
       
        {/* Collapsed Card Body */}
            <Card sx={{ '&:hover': { backgroundColor: 'lightgray', transition: 'all 200ms ease-in-out' }, width: '100%', cursor: 'pointer' }} title='Click to View'>
                <CardContent>
                    <Stack direction='row'  alignContent=''>
                    {credential?.new && <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'red', margin:'5px'}}></div>}
                        <Stack onClick={() => setOpen(true)}  width='100%'>
                            <Typography variant="h5" component="div" onClick={onClick}>
                                {credential.credentials.length} Credential{credential.credentials.length>1?'s':''} from {getName(credential)}
                            </Typography>
                            {/* <P>{getEmail(credential)}</P> */}
                            <IconBreakdown credentials={credential.credentials} />
                        </Stack>
                       {onDelete &&  <Stack direction='row' spacing={0}>
                            <InfoButtonComponent src={credential} title='Credential Data' />
                            <IconButton onClick={() => {
                                deleteRecievedCredential(credential._id).then((data) => {
                                    data?.error ? setError(data.error) :
                                        onDelete()
                                }).catch((e) => setError(e))

                            }}><DeleteForever /></IconButton>
                        </Stack>}
                    </Stack>
                    <Box onClick={onClick}>

                    </Box>
                </CardContent>
            </Card>
            <PopoverComponent open={open} onClose={() => setOpen(false)} topClose={true}>
            <Stack width={window.innerWidth-100} height={window.innerHeight-200 }px={2} >
            <Stack direction='row' pb={2} pr={4}alignItems='center' width='100%' justifyContent='space-between'>
            <H1  color='inherit' fontSize={30} sx={{borderBottom:'2px solid coral'}}>{'Disclosure Information:'}</H1> 
            {rawCredentials && <Stack direction='row' spacing={2} alignItems='center' justifyItems='center'>
                Share This Disclosure:
            <IconButton onClick={() => setShareLink('loading...')}><IosShareIcon /></IconButton>
            </Stack>}
            </Stack>
            <Stack direction="row" mt={2}>
                <Button variant="outlined" onClick={()=>setNestedOpen(credential?.credentials?.map(()=>true))}>
                    Expand All
                </Button>
                <Button variant="outlined" onClick={()=>setNestedOpen(credential?.credentials?.map(()=>false))}>
                    Collapse All
                </Button>
            </Stack>
            <Grid container spacing={2} direction='row'  alignItems='stretch' pb={4}>
                {credential?.credentials?.map((credential,index) => (
                    <Grid item height='auto' sx={12} lg={6} width='100%'>
                        
                    <NestedCredentialCard key={index} credential={credential} rawCredential={rawCredentials&&{...rawCredentials[index],
                        credentialListId:listId}}
                        open={nestedOpen[index]} 
                        onToggle={()=>setNestedOpen(nestedOpen.map((value,i)=>{
                            console.log('Value:',window.innerWidth);
                            //if window is large enough to display 2 cards side by side
                            if(window.innerWidth>1650){
                            if(i===index+1 && i%2===1){
                                return !value
                            }
                            if(i===index-1 && i%2===0){
                                return !value
                            }
                        }
                            return i===index? !value : value
                        
                        }
                        ) )}
                        />
                    </Grid>
                ))}
            </Grid>
            </Stack>
            </PopoverComponent>
        </>
    );
};

export default CredentialCard;