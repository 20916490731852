import React from 'react';
import Header from '../Components/Header/Header.js';
import Nav from '../Components/Navbar/Nav.js';
import Results from '../Components/Results/Results.js';
import { useContext } from 'react';
import { AppContext } from '../AppContext';
import CredentialSection from '../Components/CredentialForm/CredentialSection.jsx';
import Stack from '@mui/material/Stack';
import MantisHeader from '../Components/MantisHeader/index.jsx';
import { H1 } from '../Components/MUI/Typography';
import Grid from '@mui/material/Grid';  
const IssueScreen = () => {
    const {role} = useContext(AppContext);
    return (
    <MantisHeader >

    {(role === 'admin' || role === 'normal') ?

        <Grid container spacing={1} pt={7}>
            <Grid item md={3} xs='auto' >
            <Nav /> 
            </Grid>
            <Grid item md={7}xs={10}lg={7} xl={7}>
            <CredentialSection />   
            </Grid>
        </Grid>
    //       <Stack direction='row' pt={{xs:8,sm:7,md:7,lg:7}} justifyContent='space-between'>
          
       
    //    </Stack>
     
     : <Stack justifyContent='center' pt={10}><H1>Looks Like Your Not Authorized to Issue Creds</H1></Stack>}

    </MantisHeader>
    );
};
export default IssueScreen;