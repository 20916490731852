import React from 'react';
// import Header from '../Components/Header/Header.js';
// import Nav from '../Components/Navbar/Nav.js';
// import Results from '../Components/Results/Results.js';
import Form from '../Components/LoginForm/Form.jsx';
import Login from '../Components/Login/Login.js';


const LoginScreen = props => {

    function parentCallback(childData) {
        props.parentCallback(childData);
    }
    return (
    <div className="app">
    {/* <Header /> */}
    {/* <Nav /> */}
    {/* <Form parentCallback = {parentCallback}/> */}
    <Login parentError={props.parentError} loading={props.loading}/>
    </div>
    )
};
export default LoginScreen;