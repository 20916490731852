import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import PopoverComponent from '../MUI/PopoverComponent';
import { H1 } from '../MUI/Typography';
import { Stack, TextField } from '@mui/material';
import ReactJson from 'react-json-view';
import { VerifyCredential } from '../../Api/VerifyCredential';
import NotifyPopup from '../MUI/NotifyPopup';

const VerifyCredentialComponent = () => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [input, setInput] = useState('');
    const [credential, setCredential] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [success, setSuccess] = useState(undefined);

    const handleButtonClick = () => {
        setIsPopoverOpen(!isPopoverOpen);
    };
    useEffect(() => {
        if(input){
            try{
                const credential = JSON.parse(input);
                setCredential(credential);
                console.log(credential);
            }catch(e){
                console.error(e);
            }
        }
    }, [input]);

    const verify = () => {
        VerifyCredential(credential).then
        (data => {
            console.log('Verification result:',data);
            if(data.error){
                setError(data.error);
            }else if (data.results?.verified){
                setSuccess('Credential Verified');
            }else{
                setError('Credential not verified');
            }
        });
    }
    return (
        <div>
            {error && <NotifyPopup message={error} severity='error' onClose={()=>setError(undefined)}/>}
            {success && <NotifyPopup message={success} severity='success' onClose={()=>setSuccess(undefined)}/>}
            <Button onClick={handleButtonClick}>Verify Web Credential</Button>
            {isPopoverOpen && 
            <PopoverComponent onClose={()=>setIsPopoverOpen(false)} open={isPopoverOpen}>
                <H1>Verify Credential</H1>
                {credential? 
                <Stack>
                    <Button variant='contained' color='success'fillWidth onClick={verify}>Verify</Button>
                    <Button variant='outlined' onClick={()=>setCredential(undefined)}>Clear</Button>
                <ReactJson src={credential}/>
                </Stack>
                :<TextField multiline onChange={(e)=>setInput(e.target.value)} placeholder='Paste a credetial here'/>
                }
                </PopoverComponent>
                }
        </div>
    );
};

export default VerifyCredentialComponent;