import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { H3 } from "./Typography";
import Switch from '@mui/material/Switch';
import HelpOutlinedIcon from '@mui/icons-material/Warning';
import SelectComponent from "./SelectComponent";
import React from "react";
import { useEffect } from "react";
import { Share } from "@mui/icons-material";
import Diversity2Icon from '@mui/icons-material/Diversity2';
    const options = [
        { value: 'velocity', label: <Stack direction='row' spacing={1} textAlign='center'><Typography color='grey.600' textAlign='center'>Velocity Network</Typography><Share/></Stack> },
        { value: 'w3c', label: <Stack direction='row' spacing={1} textAlign='center'><Typography color='grey.600' textAlign='center'>W3C VC Standard</Typography><Diversity2Icon/></Stack> },
    ];

const NonVelocitySwitch = ({onChange, value,
    title='Credential Infrastructure',
    tooltip='Non-velocity credentials are issued without the use of the Velocity Network. This means that the credential will not be stored on the blockchain and will not be verifiable by the Velocity Network.'
}) => {
    const [selectValue, setSelectValue] = React.useState(options[0].value);
    useEffect   (() => {
        console.log("NonVelocitySwitch:Value:useEffect",selectValue);
        selectValue === 'velocity' ? onChange({target:{checked:true}}) : onChange({target:{checked:false}});
    }
    ,[selectValue]);
    return (
        <Stack direction='row' spacing={1} alignItems='center'>
        <H3>{title}</H3>
        {selectValue !=='velocity'&&<Tooltip title={tooltip} arrow>
            <HelpOutlinedIcon fontSize='small'/>
        </Tooltip>}
        <SelectComponent options={options} title={title} value={selectValue} onChange={(e)=>setSelectValue(e.target.value)}/>
        </Stack>
    );
}
export default NonVelocitySwitch;