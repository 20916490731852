import { Editor as EditorComponent } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './Editor.css';
import { Stack } from '@mui/material';



export const Editor = ({state,setState, defaultProp}) =>{

    const changeParentState = (e) => {
        setState(e);
    }
  return (
    <Stack border={1} height='900px'>
    <EditorComponent
    // contentState={state}
    wrapperClassName='wrapper-class'
    editorClassName='editor-class'
    toolbarClassName='toolbar-class'
    onChange={(e)=>changeParentState(e)}
    defaultContentState={defaultProp}
  
    /></Stack>
  );
}