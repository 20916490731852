import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
const AutoComplete = ({options,onChange}) => {

    return (
        <Autocomplete
        clearIcon={<ClearIcon/>}
        // popupIcon={<ClearIcon/>}
        onChange={(event, newValue) =>onChange(newValue?.label)}
            options={options}
            renderInput={(params) => (
                <TextField {...params} 
                label="" 
                placeholder='🔍 Search user email...'
                variant="outlined" 
                />
            )}
        />
    );
};

export default AutoComplete;