import React from 'react';
import Header from '../Components/Header/Header.js';
import Nav from '../Components/Navbar/Nav.js';
import Results from '../Components/Results/Results.js';
import { useContext } from 'react';
import { AppContext } from '../AppContext';
import CredentialSection from '../Components/CredentialForm/CredentialSection.jsx';
import Stack from '@mui/material/Stack';
import MantisHeader from '../Components/MantisHeader/index.jsx';
import { H1 } from '../Components/MUI/Typography';
import Grid from '@mui/material/Grid';  
import DisclosureComponent from '../Components/DisclosureComponent/DisclosureComponent.js';
import ReceivedCredentialsComponent from '../Components/RecievedCredentialsComponent/RecievedCredentialsComponent.js';
import { Navigate } from 'react-router-dom';
import NonVelocityDisclosures from '../Components/DisclosureComponent/NonVelocityDisclosures.js';
/**
 * Renders the InspectScreen component.
 * 
 * @returns {JSX.Element} The rendered InspectScreen component.
 */
const InspectScreen = () => {
    const {role,type,setType} = useContext(AppContext);


    /**
     * Opens the Rewards Hub in a new window and sets the type to 'disclosures'.
     */
    const openRewardsHub = () => {
        window.open('https://stagingrewardsapp.velocitycareerlabs.io/', '_blank');
        setType('disclosures');
    }

    return (
    <MantisHeader >

    {(role === 'admin' || role === 'normal' || role ==='basic') ?
            <Grid container spacing={0} pt={7}>
            <Grid item md={3} xs='auto'>
            <Nav /> 
            </Grid>
            <Grid item md={7}xs={10}>
           {type==='disclosures' ? <DisclosureComponent/> : type==='tokens' ? <>{openRewardsHub()}</> :   
           type==='nonVelocity'? <NonVelocityDisclosures/>: <ReceivedCredentialsComponent/>}
            </Grid>
        </Grid>

    //       <Stack direction='row' pt={{xs:8,sm:7,md:7,lg:7}} justifyContent='space-between'>
          
       
    //    </Stack>
     
     : <Stack justifyContent='center' pt={10}><H1>Looks Like Your Not Authorized to Inspect Creds</H1></Stack>}

    </MantisHeader>
    );
};
export default InspectScreen;