import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import Email from '@mui/icons-material/Email';
import CircularProgress from '@mui/material/CircularProgress';
import Logo from '../../assets/images/icon.png';
import {defaultEmailTemplate,defaultEmailHtml, defaultDisclosureHtml } from '../../config';

import draftToHtml from 'draftjs-to-html';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import generateQRCode from '../../Hooks/generateQRCode.js';
import { QRCode } from 'react-qrcode-logo';
import { getDefaultMailTemplate } from '../../Hooks/mailing/index.js';
import html2canvas from 'html2canvas';
import NotifyPopup from '../MUI/NotifyPopup.js';
import SendIcon from '@mui/icons-material/Send.js';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import Button from '@mui/material/Button';
import { getDisclolsureQRCode } from '../../Hooks/disclosures/requests.js';
import { sendDisclosureEmail } from '../../Hooks/mailing/sendDisclosureEmail.js';

const sendEmail =  async (data) => {
    console.log("Data from sendEmail:",data);;
    var template = {template: draftToHtml(data.template?.template || data.template)};
    
    const res = sendDisclosureEmail({
        offerId: data._id,
        recipients: data.recipients,
        subject: data.subject || 'Credential Offer Notification',
        html: template.template,
        qrCode: data.qrCode,
        uri: data.uri,
        url: data.url,
    }).catch(err => {console.log("Error in sending email",err);return new Promise((resolve,_) => resolve({error:"Error in sending email"}));});
   console.log("Res:",res);
    return res;
    // return {message:"Error in sending email"};
}
//converts blob to base64 for QR code sending
function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
const SendDisclosureEmailComponent = ({disclosureId,recipients,template,onSend,...props }) => {
    const [loading, setLoading] = React.useState(false);
    const [done,setDone] = React.useState(false);
    const [error, setError] = React.useState(undefined);
    const [uri, setUri] = React.useState(undefined);
    const [url, setUrl] = React.useState(undefined);
    const [blob, setBlob] = React.useState(undefined);
    const ref = React.useRef();    


    React.useEffect(() => {
        if(blob){
        sendEmailStart();
        }
    }, [blob]);

    const startEmailProccess =()=>{
        setLoading(true);
        getDisclolsureQRCode(disclosureId).then((data) => {
            if(data.error){
                setError(data.error);
                setLoading(false);
                return;
            }
            if(!data.uri){
                return {error:"Error in generating QR code"};
            }   
            if(data.url){
                setUrl(data.url);
            }
            setUri(data.uri);
        });
    }
    const getQrCodeBlob = () => {
       try{
        console.log(document.querySelector("#qrCodeEl"))
        //converts component to image
        const d =  html2canvas(document.querySelector("#qrCodeEl"),{scale : 0.4}).then(canvas => {
            console.log("Canvas:",canvas);
            canvas.toBlob((blob) => {
                blobToBase64(blob).then((data) => {
                // console.log("Blob:",data);
                setBlob(data);
                }).catch(err => console.log("Error in converting blob to base64",err));
            });
        }).catch(err => console.log("Error in getting blob",err));
        return d;
        }catch(err){
            console.log("Error in getting blob",err);
        }
    }

    const sendEmailStart =  () => {
        setError(undefined);
        const res = sendEmail({qrCode:blob,uri:uri,url:url,recipients,template}).then((data) => {
            setLoading(false);  
            if(data.error){
                setError(data.error);
            }else{
                setDone(true);
            }
        }).catch(err => {
            console.log("Error in sending email",err);
            setError("Error in sending email"+err);setLoading(false);});  
        setUri(undefined);
        setBlob(undefined); 
        
    };
    useEffect(() => {
        if(loading){
            setLoading(false);
        }
        if(done){
            onSend();
        }
    }, [error,done]);    
    return (<>

        {error && <NotifyPopup message={"Error Sending Email:"+error?.message} severity='error' />}

        {uri && <QRCode value={uri} id='qrCodeEl' size={500} quietZone={10}
        style={{zIndex: -100000,position: 'absolute',top: -10000,left: 0}}
            logoImage={Logo} 
            bgColor='#F0F0F0'
            //  logoPadding={3} 
            qrStyle='squares'
            fgColor='#404040'
            eyeRadius={3}
            eyeColor={'#404040'}
            logoOnLoad={!done ? ()=> getQrCodeBlob() : ()=>{setUri(undefined);setLoading(false);}}
            logoOpacity={1}
            logo
            cloneNode={true}
            />}
        
        <Button onClick={(e)=>{
            recipients.length>0 ? startEmailProccess() : setError(new Error("No Recipients"));
            // setError(new Error("Error in sending email"));
            }} 
            color={ error ? 'error' : done ? 'success' : 'primary'}
            variant='contained'
            title='Send Email'
            endIcon={Boolean(error) ? <CancelScheduleSendIcon/> : (loading ? <CircularProgress size={24} color='inherit' /> : (done ? <MarkEmailReadIcon/> : <SendIcon/>))}
            {...props}
            >
            Send Email Request
            
        </Button></>
    );
};



export default SendDisclosureEmailComponent;