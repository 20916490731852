import { useContext, useRef, useState,useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
// project import
import MainCard from '../../../MantisDashboard/components/MainCard';
import Transitions from '../../../MantisDashboard/components/@extended/Transitions';
// import { AppContext } from '../../../../AppContext';
// assets
import BellOutlined from '@ant-design/icons/BellOutlined';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import GiftOutlined from '@ant-design/icons/GiftOutlined';
import MessageOutlined from '@ant-design/icons/MessageOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import { AppContext } from '../../../../AppContext';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

const nameToIcon = {
  'offer': <GiftOutlined />,
  'message': <MessageOutlined />,
  'setting': <SettingOutlined />,
  'notification': <BellOutlined />  
}
const typeToColor ={
  'offer': 'success',
  'message': 'warning',
  'setting': 'info',
  'notification': 'error'
}

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

export default function Notification() {
  const navigate = useNavigate();
  const {notifications} = useContext(AppContext);
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const anchorRef = useRef(null);
  const [read, setRead] = useState(notifications?.length || 0);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    setRead(notifications?.length || 0);
  }, [notifications]);
  const iconBackColorOpen = theme.palette.mode === 'light' ? 'grey.100' : 'grey.700';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{ color: 'grey.500', bgcolor: open ? iconBackColorOpen : 'transparent' }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={read} color="primary">
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{ modifiers: [{ name: 'offset', options: { offset: [matchesXs ? -5 : 0, 9] } }] }}
        sx={{
          overflowY: 'scroll',
          maxHeight: 500,
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
            <Paper sx={{ boxShadow: theme.customShadows.z1, width: '100%', minWidth: 285, maxWidth: { xs: 285, md: 420 } }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Notification"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <>
                      {read > 0 && (
                        <Tooltip title="Mark as all read">
                          <IconButton color="success" size="small" onClick={() => setRead(0)}>
                            <CheckCircleOutlined style={{ fontSize: '1.15rem' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  }
                >
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 0.5,
                        '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                      }
                    }}
                  >
                   
                    {notifications.length>0 ? notifications.map((not, index) => (
                      <>
                       <ListItemButton selected={read > 0} 
                        onClick={not.to ? () => not.to.startsWith('http') ? window.open(not.to, '_blank') : navigate(not.to) :() => {}}
                        >
                       <ListItemAvatar>
                         <Avatar sx={{ color: `${typeToColor[not.type]}.secondary`, bgcolor: `${typeToColor[not.type]}.primary` }}>
                           {not.icon || nameToIcon[not.type]}
                         </Avatar>
                       </ListItemAvatar>
                       <ListItemText
                         primary={
                           <Typography variant="h6">
                             {not.message}
                           </Typography>
                         }
                         secondary={not.secondary || not.time}
                       />
                       <ListItemSecondaryAction>
                         <Typography variant="caption" noWrap>
                           {not.time}
                         </Typography>
                       </ListItemSecondaryAction>
                     </ListItemButton>
                     <Divider/></>
                     )) : <Typography variant="h6" color='grey.500' align="center" py={10} sx={{textDecoration:'underline'}}>No Notifications</Typography>}
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
}
