import React from 'react';
import { Avatar, LinearProgress, Paper, Stack,CircularProgress ,Button, Divider} from '@mui/material';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import MainCard from '../MantisDashboard/components/MainCard';
import { Box } from '@mui/system';
import Typography from '../../themes/typography';
import { H1, H2, H3 } from '../MUI/Typography';
import { getOrgData } from '../../Hooks/super';
import PopoverComponent from '../MUI/PopoverComponent';
import OrgView from '../AccountView/OrgView';
import AccountCard from '../Card/AccountCard';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import UserView from '../AccountView/UserView';
import EyeIcon from '@mui/icons-material/Visibility';
import { TextField } from '@mui/material';
import RegistrarComponent from './RegistrarComponent.js';
import { useTheme } from '@mui/material/styles';
import InvitationComponent from './InvitationComponent.js';
import ViewAsUser from './ViewAsUser.js';
import AvatarIcon from '@mui/icons-material/AccountCircle';
import { useCookies } from 'react-cookie';
import VerifyCredential from './VerifyCredentialComponent.js';
/**
 * Render a card component for an organization.
 * 
 * @param {Object} org - The organization data to display.
 * @param {function} setSelected - The function to set the selected organization.
 * @returns {JSX.Element} - The rendered organization card component.
 */
const OrgCard = ({org,setSelected}) => {
    return (
        
        <MainCard>
            <Button fullWidth sx={{padding:3}} onClick={()=>setSelected(org)}>
            <Stack direction="row" spacing={2}>
                
                <Avatar alt={org.name} sx={{bgcolor:'grey'}}><CorporateFareIcon/></Avatar>
               <H3>{org.name}</H3> 
            </Stack>
             </Button>
        </MainCard>
       
    );
};


/**
 * React Used to manage the super admin console.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AdminComponent = () => {

    const {user,role} = useContext(AppContext);
    const [orgData, setOrgData] = React.useState(undefined);
    const [users, setUsers] = React.useState(undefined);
    const [loading, setLoading] = React.useState(true);
    const [selected,setSelected] = React.useState(undefined);
    const [selectedUser,setSelectedUser] = React.useState(undefined);
    const [cookies, setCookie] = useCookies(['AUTH_TOKEN']);
    React.useEffect(() => {
        if(!orgData){
        getOrgData().then(data => {
            console.log(data);
            setOrgData(data?.orgs);
            setUsers(data?.users)
            setLoading(false);
        });
    }
    },[orgData]);

    return (<Box pt={5} width='100%' >
        {user.super ? (
            <Stack component={Paper} p={5} width='100%' alignItems='center' justifyContent='center'> 
            <H3>Super Admin: {user.email}</H3>
            <H1>Select An Organization or User</H1>
            <InvitationComponent/>
            <VerifyCredential/>
            {loading ? <CircularProgress  sx={{margin:20}}size={60}/> :
            
               (<>
               <Stack direction="row" spacing={2} pt={5} useFlexGap flexWrap="wrap" >

               <PopoverComponent open={Boolean(selected)} onClose={()=>setSelected(undefined)}>
                <Box sx={{px:30}} minWidth='1000px'> 
                   {!selected?.did?.match(/^did:[a-z]+:[a-zA-Z0-9_#:;?&=\\.-]+$/) && <RegistrarComponent orgData={selected}/>}   
                   
                   </Box>
                    <Stack pb={3} alignItems='center'>
                        <H1>{selected?.name}</H1>
                        {/* <H3 variant='body1'>Description:{selected?.description}</H3> */}
                        <OrgView client={selected} setClient={(client)=>{setSelected(client);}} isSuper/>
                    </Stack>
                </PopoverComponent>

               {orgData?.map((org) => <OrgCard org={org} setSelected={setSelected}/>)}
              </Stack> 
              <Box pt={5}/>
              <H1>Users</H1>
               <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap" >
                
               <PopoverComponent open={Boolean(selectedUser)} onClose={()=>setSelectedUser(undefined)}>
                    <Stack pb={3}>
                        <H1>{selectedUser?.email}</H1>
                        <Stack spacing={2} alignItems='center'>
                        <ViewAsUser user={selectedUser}/>
                        <Button onClick={()=>{
                            setCookie('AUTH_TOKEN',selectedUser?.authentication?.sessionToken);
                            window.location.reload();
                        }} fullWidth variant='outlined' color='warning'endIcon={<AvatarIcon/>}>Log in as user (You Will be logged out)</Button>
                        </Stack>
                        {/* <H3 variant='body1'>Description:{selected?.description}</H3> */}
                        <UserView user={selectedUser} setUser={(user)=>{setSelectedUser(user)}} isSuper/>
                    </Stack>
                </PopoverComponent> 

       

               {users?.map((user) => <AccountCard user={user} onClick={setSelectedUser}/>)}
              </Stack> 
              </>)
            }
            
            </Stack>
        ): ( role === 'admin') ? (
            <MainCard title="Admin - Not Authorized">
                <Avatar alt="Remy Sharp" src="https://mui.com/static/images/avatar/1.jpg" />
            </MainCard>
        ):(
            <MainCard title="Not Authorized">
                <Avatar alt="Remy Sharp" src="https://mui.com/static/images/avatar/1.jpg" />
            </MainCard>
        )}

    </Box>);
};

export default AdminComponent;