


export const getAchievementFromClrType = (achievementType,cred,recipient,email) => {

    switch (achievementType) {
    case "Achievement":
    case "Badge":
    case "CommunityService":
    case "Fieldwork":
    case "GeneralEducationDevelopment":
    case "Membership":
    case "MicroCredential":
    case "Assignment":
    case "Award":
    case "Competency":
    case "CoCurricular":
    case "ResearchDoctorate":
    case "LearningProgram":
        //using open Badge type
        return {
            type:'OpenBadgeV2.0',
            hasCredential:{
                name: cred?.name || 'Achievement Name',
                description:cred?.description || 'Achievement Description',
                criteria:cred?.criteria || 'https://www.imsglobal.org/spec/clr/v1p0/',
                issuer:{
                    name: cred?.creator?.name,
                    type: 'Issuer'
                },
                alignment:mapAlignment(cred?.alignment),
            }
        }
    case "ApprenticeshipCertificate":
    case "Certificate":
    case "CertificateOfCompletion":
    case "Certification":
    case "JourneymanCertificate":
    case "Diploma":
    case "MasterCertificate":
    case "SecondarySchoolDiploma":
    case "QualityAssuranceCredential":
        //using Certification type
        return {
            type:'CertificationV1.1',
            recipient:recipient,
            authority:{
                name: cred?.creator?.name,
                type: 'Organization'
            },
            alignment:mapAlignment(cred?.alignment),
            name: cred?.name || 'Certification Name',
            description:cred?.description || 'Certificate Description',
            identifier: cred?.id,  
        }
    case "Assessment":
    case "CompetencyAssessment":
        //uses Assessment type
        return {
            type:'AssessmentV1.1',
            recipient:recipient,
            assesses:cred?.criteria?.narrative || 'Assessment Criteria',
            authority:{
                name: cred?.creator?.name,
                type: 'Organization'
            },
            alignment:mapAlignment(cred?.alignment),
            name: cred?.name || 'Assesment Name',
            description:cred?.description || 'Apprenticeship Certificate Description',
        }
    case "AssociateDegree":
    case "BachelorDegree":
    case "Degree":
    case "DoctoralDegree":
    case "ProfessionalDoctorate":
    case "MasterDegree":
        //uses Degree type
        return {
            type:'EducationDegreeGraduationV1.1',
            recipient:recipient,
            assesses:cred?.criteria?.narrative || 'Degree Criteria',
            degreeName: cred?.name || 'Degree Name',
            programName: cred?.fieldOfStudy || cred?.name || 'Degree Program Name',
            degreeMajor:[
                cred?.fieldOfStudy
            ],
            institution:{
                name: cred?.creator?.name,
                type: 'Organization'
            },
            school:{
                name: cred?.creator?.name,
                type: 'Organization'
            },
            alignment: mapAlignment(cred?.alignment),
            conferredDate: cred?.date || new Date().toISOString().split('T')[0],
            description:cred?.description || 'Degree Description',
        }
    case "License":
        //uses License type
        return {
            type:'LicenseV1.1',
            hasCredential:{
                name: cred?.name || 'License Name',
                description:cred?.description || 'License Description',
                criteria:cred?.criteria || 'https://www.example.com/criteria',
                authority:{
                    name: cred?.creator?.name,
                    type: 'Organization'
                },
                alignment:mapAlignment(cred?.alignment),
        }
    }
    case "Course":
        //uses Course type
        return {
            type:'CourseCompletionV1.1',    
            courseName: cred?.name || 'Course Name',
            description: cred?.description || 'Course Description',
            recipient:recipient,
            provider:{
                name: cred?.creator?.name,
                type: 'Organization'
            },
            alignment: mapAlignment(cred?.alignment),
            completionDate: cred?.date || new Date().toISOString().split('T')[0],
        }
    default:
        return {error: 'Invalid achievement type'};
    }
}
const mapAlignment = (alignment) => {
    if(!alignment){
        return undefined;
    }
    return alignment.map(alignment=>{
        return{
        type: 'Alignment',
        targetName: alignment?.targetName || 'Alignment Target Name',
        targetUrl: alignment?.targetUrl || 'https://www.imsglobal.org/spec/clr/v1p0/',
        targetDescription: alignment?.targetDescription,
        targetCode: alignment?.targetCode,
        targetFramework: alignment?.targetFramework
}});
}
export const getClrEmail = (offer) => {
    const email = offer?.credentialSubject?.identifier?.find(ident=>{
        return (ident.identityType === 'EmailAddress' && !ident?.identityHash)
    })?.identityHash
    return email;
}
export const getClrName = (offer) => {
    return offer?.credentialSubject?.identifier?.find(ident=>{ return ident.identityType === 'name' })?.identityHash || offer?.name
}
const parseClr = (offer)=>{

    var firstName="",lastName="";
    const email = getClrEmail(offer);
    if(email){
        offer.email = email
    }
    const name = getClrName(offer);
    if(name){
        name.split(' ').forEach((name,index)=>{
            if(index === 0){
                firstName = name;
            }else{
                lastName = name;
            }
        })
    }
    //No 'Place' provided in CLRs,gets filled in during the credential issuance from org data
    const recipient = {
        type:'PersonName',
        givenName: firstName || 'No Name Provided',
        familyName: lastName || ''
    }
    const achievements = offer?.credentialSubject?.achievement?.map(achievement=>{

        return getAchievementFromClrType(achievement?.achievementType,achievement,recipient);
                
    });
    //Email is used for velocitycreds
    const achievementWithEmail = achievements.map(achievement=>{
       achievement.email = offer.email;
        return achievement;
    });

    return achievementWithEmail || [];

}
export default parseClr;