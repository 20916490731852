import requests from "../Api/requests";
import Cookies from 'js-cookie';


export async function sendEmail(data) {
    try {
        console.log("Data:",data);
        return fetch(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({data})
        })
            .then(data => data.json());
    } catch (error) {
        console.log("Error:", error);
        return {error:error};
    }
}
export default sendEmail;