import React, { useEffect, useState } from 'react';
// import Header from '../Components/Header/Header.js';
// import Nav from '../Components/Navbar/Nav.js';
// import Results from '../Components/Results/Results.js';
import { NotFoundComponent } from '../Components/CredentialEngineSearch/CredentialEngineSearch.js';
import { useLocation } from 'react-router-dom';
import { CircularProgress, Paper, Stack } from '@mui/material';
import Markdown from 'markdown-to-jsx'
import axios from 'axios';
import { H1, H2, P, P3 } from '../Components/MUI/Typography.js';
import Header from '../Components/Header/Header.js';
// import { fontFamily } from 'html2canvas/dist/types/css/property-descriptors/font-family.js';

//page imports
import pro from '../assets/markdown/pro.md';
import terms from '../assets/markdown/terms.md';
import privacypolicy from '../assets/markdown/privacypolicy.md';
import welcome from '../assets/markdown/welcome.md';
const pages = {pro,terms,privacypolicy,welcome};
//Page Screen uses path /info/:page
const PageScreen = () => {

    const location = useLocation();
    // const page = location.pathname.split('/')[2]?.toLocaleLowerCase();
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get('page')?.toLocaleLowerCase();
    console.log(page);
    const [md,setMd] = useState(undefined);
    const [loading, setLoading] = useState(true);
    
    const fetchMd = async () => {
        if(pages[page]){

                setMd(await axios.get(pages[page]).data);
            setLoading(false);
        }else{
            setLoading(false)

        }
    }




    useEffect(() => {
        import(`../assets/markdown/${page}.md`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => {setMd(res);setLoading(false)})
                    .catch(err => console.log(err));
            })
            .catch(err => {console.log(err);setLoading(false)});
    });









    // useEffect(() => {
    //     fetchMd();
    // }, [page]);
   useEffect(() => {
        console.log(md);
    }, [md]);   
    return (<><Header/>{loading ? <CircularProgress size={60} sx={{alignContent:'center'}} /> : md ? <>
    
    <Stack border={'1px solid'} component={Paper} py = {10}px={5}><Markdown
        options={{
            overrides: {
                h3: {
                    component: H1,
                    props: {
                        color: 'grey.700',
                        sx: {
                           borderBottom: '4px solid coral',
                           fontSize: '2.5rem',
                           width: 500,
                           fontFamily: 'Roboto',
                        }
                    }
                },
                h4: {
                    component: H2,
                    props: {
                        color: 'grey.700',
                        sx: {
                           borderBottom: '3px solid coral',
                           width: 300,
                           fontFamily: 'Roboto',
                        }
                    }
                },  

                p: {
                    component: P,
                    
                },
                li:{
                    component: P3,
                    props:{
                        variant:'li'
                    }
                }
                
            },
          }}
    
    
    >{md}</Markdown></Stack></> :
    <Stack width={'100%'} pt={15}>
        <NotFoundComponent title={"Page: "+page+' Not Found'}/>
    </Stack>
}</>)
};
export default PageScreen;