import React, { useContext ,useState} from 'react';
import './RequestHandler.css';
import  handleRequest  from '../../../Hooks/handleRequest';
import { AppContext } from '../../../AppContext';
import { useCookies } from 'react-cookie';
import { H3 } from '../../MUI/Typography';
import { Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonIcon from '@mui/icons-material/Person';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { useTheme } from '@mui/material/styles';
/**
 * React component for handling requests.
 * 
 * @param {Object} props - The component props.
 * @param {string} props.email - The email associated with the request.
 * @param {string} props.role - The role of the user making the request.
 * @returns {JSX.Element} The rendered component.
 */
const RequestHandler = props => {
    const theme = useTheme()    
    const [cookies, setCookie] = useCookies(['AUTH_TOKEN']);
    const [accepted,setAccepted] = useState(false);
    const [error,setError] = useState("");
    const handleAccept = (useRole) => {
        handleRequest(props.email, true,useRole,cookies.AUTH_TOKEN).then((res)=>{
            if(res.error){
                setError(res.error);
            }else{
            window.location.reload();
            }
        }
        ).catch((err)=>{
            console.log("Error Handling Request",err);
            setError("Error Handling Request");
        });
    };
    const handleAdmin = () => {
        handleAccept('admin');
    }
    const handleNormal = () => {
        handleAccept('normal');
    }
    const handleBasic = () => {
        handleAccept('basic');
    }
    const handleDeny = (useRole) => {
        handleRequest(props.email, false,'denied',cookies.AUTH_TOKEN).then((res)=>{
            if(res.error){
                setError(res.error);
                console.log(error);
            }else{
                window.location.reload();
            }
        }
        );
    };

    return (
        props.role === 'admin' ?(<div className='request__wrapper__wrapper'>
            {accepted ?(<div>
            <H3>Choose Role:</H3>
            <Typography color='error'>{error}</Typography>
            <Stack direction='row' spacing={2} p={2}>
            <Button color='warning' onClick={handleAdmin} variant= 'contained' endIcon={<AdminPanelSettingsIcon/>} fullWidth>Admin</Button>
            <Button color='primary' onClick={handleNormal}  variant= 'contained' endIcon={<PersonIcon/>}fullWidth>Normal</Button>
            <Button color='error' onClick={handleBasic}  variant= 'contained' endIcon={<PersonRemoveIcon/>}fullWidth>Basic</Button>
            </Stack>

            </div> )
            :(<div>
                <Typography color='error'>{error}</Typography>
                <Stack direction='row' spacing={2} p={2}>
            <Button color='success' variant='contained' fullWidth onClick={setAccepted} endIcon={<CheckIcon/>}>Accept</Button>
            <Button color='error' variant='contained' fullWidth onClick={handleDeny} endIcon={<DeleteForeverIcon/>}>Deny</Button></Stack></div>)
            
            }
        </div>) : <div></div>
    );
};

export default RequestHandler;