// import  { useContext } from 'react';
// import requests from '../Api/requests.js';
// import useFetch from '../Hooks/useFetch';
// import API from '../Api';
// import React, { useState,useEffect } from "react";
// import {AppContext} from "../AppContext.js";
import requests from '../Api/requests.js';

async function loginOrg(credentials,isRegister) {
  // if(isRegister){
  //   console.log(JSON.stringify(credentials));
  // }
  const extension = (!isRegister ? "login" : "register");


    console.log(JSON.stringify(credentials))
    try{
    return fetch(`${requests.baseURL}/auth/client/${extension}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data =>{
        if(isRegister){
          return data.json();
        }
        return {name:credentials.name};
      } 
        ,console.log("Error"));
}catch(err){
    console.log("error");
}
   }


export default loginOrg;