import React from 'react';
import MantisHeader from '../Components/MantisHeader/index.jsx';
import AdminComponent from '../Components/AdminComponent/AdminComponent.js';

const AdminScreen = () => {

    


    return (
    <MantisHeader>
        <AdminComponent />
    </MantisHeader>
    );
};
export default AdminScreen;