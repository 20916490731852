import React, { useState,useEffect,useContext } from "react";
import useOrgForm from "./useOrgForm.jsx";
import './styles.css';
import {validateOrg} from "./LoginFormValidationRules.jsx";
import { Navigate ,Link,useNavigate, isRouteErrorResponse} from "react-router-dom";
import loginOrg from '../../Hooks/loginOrg.js';
import { AppContext } from '../../AppContext.js';
import { CookiesProvider, useCookies } from 'react-cookie'
import { H1, P,H3,P4, H2 } from '../MUI/Typography';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import SendIcon from '@mui/icons-material/Send';
import { Box } from "@mui/material";
import Button from '@mui/material/Button';
import ComboBox from "./ComboBox.js";
import requests from "../../Api/requests.js";
import get from "../../Hooks/getRequest.js";
import CountryPicker from '../MUI/CountryPicker';


const OrgForm = props => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['AUTH_TOKEN']);
  const {user,setUser} = useContext(AppContext);
  const {client,setClient,setRole} = useContext(AppContext);
  const [errorMessage,setErrorMessage] = useState(null);
  const [names, setNames] = useState([]);

  const getNames = async () => {
    const names = await get({url:requests.baseURL + '/clients'});
    console.log(names);
    setNames(names);
  }
  if(!props.isRegister && names.length === 0){
    getNames();
  }
  function parentCallback(childData) {
    props.parentCallback(childData);
  }
  const login = async e => {
    const {name,domain} = values;
    // e.preventDefault();
    console.log(values);
    // return;
    try{
    const credentials = await loginOrg({
      name,
      admin: user,
      domain: domain,
      ...values
    },props.isRegister)
    if(credentials.error){
      setErrorMessage(credentials.error);
      return;
    }
    
    window.location.reload()
    // // parentCallback(true);
    // // setUser(credentials);
    return <Navigate to="/Login" />;
  }catch(err){
    console.log(err);
    setErrorMessage(err?.message || "Error in Logging in");
  }

  }
  const { values, errors, handleChange, handleSubmit } = useOrgForm(
    login,
    validateOrg
  );
  const clientFields = [
    // {title: 'Organization Name', value: client &&client.name, key: 'name'},
    {title: 'Domain', value: client &&client.domain, key: 'domain'},
    // {title: 'DID', value: client &&client.did, key: 'did'},
    {title: 'address', value: client &&client.address, key: 'address'},
    {title: 'Website URL', value: client &&client.websiteURL, key: 'websiteURL'},
    // {title: 'Support Email', value: client &&client.supportEmail, key: 'supportEmail'},
    {title: 'Technical Support Email', value: client &&client.techSupportEmail, key: 'techSupportEmail'},
    {title: 'Description', value: client &&client.description, key: 'description'},
    {title: 'Company Identifier(DUNS,LEI,etc)', value:client && client.identifier, key: 'identifier'},
    {title: 'Credential Engine CTID (If Registered)', value:client && client.ctid, key: 'ctid'},
    // {title: 'Service Endpoint URL', value: client &&client.serviceEndpointURL, key: 'serviceEndpointURL'}
  ];

  const [loggedIn, setLoggedIn] = useState(false);
  // const [rejected, setRejected] = useState(false);


  return (
    <div className="section is-fullheight">
      {user.client && <Navigate to="/" />}
      <div className="container">
        <div className="column is-6 is-offset-3">
          <div className="box">
            <div id="form__wrapper">
            <form onSubmit={handleSubmit} noValidate className='loginForm'>

            <H1>{props.isRegister ? <CorporateFareIcon fontSize="xlarge"/> : <SupervisedUserCircleIcon fontSize="xlarge"/>}{props.isRegister ?" Register An Organization":" Join an Organization" } </H1>
            
            <div className="field">
                   
                  
                  {props.isRegister&&   <P className="label">Organization Name</P>}
                <div className="control">
                  {props.isRegister ? <input
                    autoComplete="off"
                    className={`input`}
                    type="name"
                    name="name"
                    onChange={handleChange}
                    value={values.name || ""}
                    required
                  /> : <ComboBox options={names}
                  onChange={handleChange}
                  type="name"
                    name="name"
                    value={values.name || ""}
                  />}
                 
                  {errors.name && (
                    <p className="help is-danger">{errors.name}</p>
                  )}
                </div>
                {/* <label className="label">Primary Domain</label>
                <div className="control">
                  <input
                    autoComplete="off"
                    className={`input`}
                    type="domain"
                    name="domain"
                    onChange={handleChange}
                    value={values.domain || ""}
                    required
                  />
                  </div> */}

                {props.isRegister && <> {clientFields.map((data)=><div>
                  <P className="label">{data.title}</P>
                  <div className="control">
                  <input
                    autoComplete="off"
                    className={`input`}
                    type={data.key}
                    name={data.key}
                    onChange={handleChange}
                    // value={values.domain || ""}
                    // required
                  />
                  </div></div>)}
                  <CountryPicker
                  required
                value={'CA'}
                onChange={(value) =>{
                        
                    return handleChange({target:{name:'place.addressCountry',value:value},persist:()=>{}})
                    }}
                     
                /></>}
                  <div>{props.isRegister ? (<P className="switch__register">Your Current account will be registered as an Admin.</P>):(<P className="switch__register">Your Request will be sent to an Admin</P>)}</div>
              </div>
              {errorMessage && (
                    <H2 color='error'>{errorMessage}</H2>
                  )}
              <Button
                type="submit"
                className="button is-block is-info is-fullwidth"
                variant="contained"
                endIcon={<SendIcon/>}
                fullWidth
              >
                {props.isRegister ? "Register" : "Send Request" }
              </Button> 
             
              <div>
              {props.isRegister &&<P4 className = 'switch__register'> More settings can be configured in the Account tab.</P4>}
              <Box p={1}></Box>
                {!props.isRegister ? (<P className="switch__register">Want to Create an Organization? <Link to = "../RegisterOrg" style={{color:'blue'}}>Create</Link></P>) : (<P className="switch__register">Want to Join an Organization? <Link style={{color:'blue'}} to = "../LoginOrg">Join</Link></P>)}
                </div> 
            </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgForm;
