
import requests from '../Api/requests.js';

async function updateClient(credentials,sessionToken) {
  const extension = (credentials._id === undefined ? "" : credentials._id);

    try{
    return fetch(`${requests.baseURL}/client/${extension}/${sessionToken}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json(),console.log("Error"));
}catch(err){
    console.log("error");
}
}

export default updateClient;