import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import backgroundImage from '../../assets/images/VCLogoBG.png';
import { Divider } from '@mui/material';

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h1" marked="center"sx={{
        textDecoration: 'none',
        borderBottom: '5px solid #ccc', // Modified border width
        pt:5,
        textShadow: '2px 2px 2px #888',
        fontSize: '3rem',
      }}>
        Upgrade your Credentials
      </Typography>
      <Divider />
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
        With Secure, Verifiable, and Portable Credentials for Everyone. Issued by You.
      </Typography>
      <Button
        color="primary"
        variant="contained"
        size="large"
        href="/Register"
        sx={{ minWidth: 300,borderRadius: '0px',border: '1px solid #ccc', }}
      >
        Register
      </Button>
  
    </ProductHeroLayout>
  );
}
