import React, { useEffect, useState } from 'react';

import { Navigate, useLocation,Link } from 'react-router-dom';
import { Button, CircularProgress,  Paper, Slide, Stack } from '@mui/material';
import { H1, H2, P } from '../../Components/MUI/Typography.js';
import Header from '../../Components/Header/Header.js';
import PlayIcon from '@mui/icons-material/PlayArrow';
import * as CredentialHandlerPolyfill from 'credential-handler-polyfill';
import ReactJson from 'react-json-view';
import ShareIcon from '@mui/icons-material/Share.js';
import NotifyPopup from '../../Components/MUI/NotifyPopup.js';
import { getOfferDID, getVerifiableCredential } from '../../schemas/parsers/VerifiableCredentialParser.js';

import requests from '../../Api/requests.js';
import axios from 'axios';
import Cookies from 'js-cookie';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HomeIcon from '@mui/icons-material/Home';

import { decodePresentationRequest, setOfferClaimed, shareCredential } from '../../Hooks/sharing/nonVelocityHandling.js';
import OfferCard from '../../Components/MUI/OfferCard.js';
//Load the polyfill once the app is ready to work with credentials
await CredentialHandlerPolyfill.loadOnce();
console.log('Ready to work with credentials!', CredentialHandlerPolyfill.WebCredential);




const PresentationScreen = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const disclosureCode = queryParams.get('disclosure');
    const [disclosure, setDisclosure] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [from,setFrom] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [logo,setLogo] = useState(undefined);
    const [presented,setPresented] = useState(false);
    useEffect(() => {
    
        decodePresentationRequest(disclosureCode).then((res) => {
            if(res.error){
                setLoading(false);
                setError(res.error);
                return;
            }
            setLogo(res.logo);
            setDisclosure(res.disclosure);
            setFrom(res.from);
            //load org data
            axios.get(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/${getOfferDID(res.offer.credentialSubject)}/verified-profile`).then((data) => {
                if(data.data?.credentialSubject?.logo){
                    setLogo(data.data?.credentialSubject?.logo);
                }
                
            }).catch((e) => console.log('Error:', e));
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        });
    }, [disclosureCode]);
 
    const makePresentationRequest = () => {
        //make presentation Wrapper
        const Vpr = {
            query: [{
              type: "QueryByExample",
              credentialQuery: {
                reason: disclosure.purpose,
                example: {
                  "@context": [
                    "https://w3id.org/credentials/v1",
                    "https://www.w3.org/2018/credentials/examples/v1",
                    'https://service.velocitycreds.com/images/layer1context.json',
                  ],
                  type: ["VerifiableCredential"],
                  credentialSubject: {
                    "id": "did:example:ebfeb1f712ebc6f1c276e12ec21"
                  }
                }
              }
            }]
          };
          const credentialInterfaceQuery = {
            web: {
              VerifiablePresentation: Vpr,
              recommendedHandlerOrigins: [
                "https://demo.vereswallet.dev/"
              ]
            }
          };
        //Store the credential in the web wallet
        navigator.credentials.get(credentialInterfaceQuery).then((result) => {
        if(!result) {
            setError('Credential not stored');
        }else{
            setPresented(result);
        }
    }).catch((error) => {
        setError(error.message || error +" ");
    });
    }

    useEffect(() => {
        console.log('Logo:',logo) ;
    }, [logo]);


    useEffect(() => {
        if(presented){
            shareCredential(disclosureCode,presented?.data?.verifiableCredential).then((data) => {
                console.log('Claimed:',data);
            }).catch((error) => {
                setError(error.message || error +" ");
            });
        }

    }, [presented]);


    return (disclosureCode ? 

        loading ? <CircularProgress sx={{ pt: 30 }} size={60} /> 
        : disclosure ?
        

        <>
        {error && <NotifyPopup message={error} severity="error" onClose={()=>setError(undefined)} bottom={true}/>}
        <Header/>
        <Stack  alignItems='center' justifyContent='center' sx={{pt:10}} width='100%' component={Paper} pb={1}>
        <H1 color='grey.600' sx={{textDecoration:'underline'}}>Flexible, portable, verified. </H1>
        <H2>VelocityCreds enables organizations to focus on what credentials to issue, not how to issue them. We have support for both velocity enabled blockchain credentials and web wallet enabled W3C Verifiable Credentials</H2>
        <Button variant='outlined' color='inherit' component={Link} startIcon={<PlayIcon/>} to='https://youtu.be/A9e7Ow8pzBc' target='_blank'>Learn More</Button>
        </Stack>
       {presented ? 
        <>
     <Stack justifyContent='center' alignItems='center' pt={2}>
            <Slide in direction="up">
                <Stack alignItems='center' spacing={2}>
            <Stack direction='row' alignItems='center' spacing={2}>
                <CheckCircleIcon color='success'sx={{transform:'scale(1.7)'}}/>
            <H1 color='inherit' fontSize={25}>Your Credentials have been Sent!</H1>
           </Stack>
            <H2 color='grey.600'>Thank you for using VelocityCreds.</H2>
            <Button variant='outlined' component={Link} to='/landing?redirect=/' startIcon={<HomeIcon />} >
            Home
        </Button>
            </Stack>
        </Slide>
        
        </Stack>
        <ReactJson src={presented} />
       </> :
       <>
       <Stack direction='row'>
        {logo&&<img src={logo} alt='logo' style={{width:'100px',height:'100px',padding:'5px'}}/>}
       <H1 ><H2 color='black'>{from || 'An Organization'}.</H2> <H1 color='grey.600'>Would like to inspect your credentials:</H1></H1>
       
       </Stack>
        <Stack width='90%' alignContent='center' pl='5%'>
            <Button variant='contained' sx={{boarderRadius:0}} endIcon={<ShareIcon/>} onClick={makePresentationRequest}>Share Credentials</Button>
        
        
        
        
        </Stack>
        </>}
        </>


        : <Navigate to='/NotFound'/>
        : 
        <Navigate to='/NotFound'/>);
};
export default PresentationScreen;