// import  { useContext } from 'react';
// import requests from '../Api/requests.js';
// import useFetch from '../Hooks/useFetch';
// import API from '../Api';
// import React, { useState,useEffect } from "react";
// import {AppContext} from "../AppContext.js";

import requests from '../Api/requests.js';


async function handleRequest(email,accept,role,sessionToken) {
  console.log("validating client on:",`${requests.baseURL}/client/request/${sessionToken}`);
  try{
  return fetch(`${requests.baseURL}/client/request/${sessionToken}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      accept,
      role
    })
  })
    .then(data => data.json()).catch(()=> {return{error: "Error Handling Request"}});
}catch(err){
  console.log(err);
}

}
export default handleRequest;