import { Stack } from '@mui/material';
import React,{useEffect,useRef, useState} from 'react';
import { useCookies } from 'react-cookie';
import { GoogleLogin } from 'react-google-login';
import { loginWithGoogle } from '../../Hooks/login';
import NotifyPopup from '../MUI/NotifyPopup';
import {env} from '../../env'
/**
 * GoogleLoginComponent is a component that provides a Google login functionality.
 * 
 * @param {Object} props - The component props.
 * @param {boolean} props.isRegister - Indicates whether the component is used for registration.
 * @returns {JSX.Element} The rendered GoogleLoginComponent.
 */
const GoogleLoginComponent = ({isRegister=false}) => {
    const [cookies, setCookies] = useCookies(['AUTH_TOKEN']);
    const [error, setError] = useState(undefined);
    
    const responseGoogle = (response) => {
        console.log(response.profileObj);

        loginWithGoogle(response.profileObj,isRegister).then(credentials => {
            if(credentials){
                if(credentials.error){
                    setError(credentials.error);
                    return;
                }
                setCookies('AUTH_TOKEN', credentials.authentication.sessionToken, { path: '/'});
                window.location.reload();
            }
        });
    };
    const googleButtonRef = useRef(null);
    useEffect(() => {
        if (googleButtonRef.current) {
            const button = googleButtonRef.current.querySelector('button');
            if (button) {
                button.style.width = '100%';
                // button.style.display = 'block';
            }
        }
        // console.log('Google clientId:', process.env.REACT_APP_GOOGLE_CLIENTID);
    }, []);
    return (
        <Stack id='signInButton' ref={googleButtonRef} boxShadow='0px 1px 2px rgba(0, 0, 0, 0.25)' border='1px solid' borderRadius='5px' my={1}>
            {error && <NotifyPopup message={error} severity='error' onClose={() => setError(undefined)} />}
            <GoogleLogin
                clientId={env.REACT_APP_GOOGLE_CLIENTID}
                buttonText={(isRegister? 'Sign up' : 'Sign in')+" with Google"}
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
                style={{ width: '100%', }} // Add this line to make the button long and add shadow
            />
        </Stack>
    );
};

export default GoogleLoginComponent;