import React, { useEffect, useState } from 'react';
import { getAllDisclosures } from '../../Hooks/disclosures';
import NotifyPopup from '../MUI/NotifyPopup';
import DisclosureCard from './DisclosureCard';
import { Box, Button, Stack } from '@mui/material';
import { H1, H2 } from '../MUI/Typography';
import { NotFoundComponent } from '../CredentialEngineSearch/CredentialEngineSearch';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import DisclosureRequestForm from './DisclosureRequestForm';
import PopoverComponent from '../MUI/PopoverComponent';
import CancelIcon from '@mui/icons-material/Cancel';
import DisclosureRequestComponent from './DisclosureRequestComponent';

const DisclosureComponent = () => {
    const [disclosures,setDisclosures] = useState(undefined);  
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const[ create,setCreate] = useState(false);
    const [success, setSuccess] = useState(undefined);
    const [openDisclosure, setOpenDisclosure] = useState(undefined);
    useEffect(() => {
        async function getDisclosures(){
            try{
                const disclosures = await getAllDisclosures();
                setDisclosures(disclosures);
            }catch(e){
                console.log("Error:",e);
            }
        }
        if(!create){getDisclosures();}
    }, [create]);

    return (<>
    <Box sx={{marginTop:'80px'}} width='100%' alignContent='center' >
        {error && <NotifyPopup message={error} severity="error" onClose={()=>setError(undefined)}/>}
        {success && <NotifyPopup message={success} severity="success" onClose={()=>setSuccess(undefined)}/>}
        <Stack width='100%' px={2}>
        <H1 color='inherit' fontSize={30} sx={{borderBottom:'2px solid coral', width:'3 50px'}}>{create ?'New Disclosure': openDisclosure? "Send Disclosure Request":'Select a Disclosure:'}</H1>
        {create?
        <DisclosureRequestForm onCancel={()=>setCreate(false)} onSuccess={(msg)=>{setCreate(false);setSuccess(msg)}}/>
        
        : openDisclosure ? <>
    <Stack pt={1}>
        <Button type="submit" variant="outlined" color="error" endIcon={<CancelIcon/>} sx={{width:'100px'}} onClick={()=>{setCreate(false);setOpenDisclosure(undefined)}}>
                Cancel
            </Button>
        <H1>New Disclosure Request</H1>
        <DisclosureRequestComponent disclosure={openDisclosure}/>
        </Stack>
        </>:
        <Stack spacing={1} width='100%' pt={1} alignItems='center'>
            <Button onClick={()=>setCreate(true) } startIcon={<AddBoxOutlinedIcon fontSize='large'/>}
             variant='contained' 
             color='primary' 
             sx={{maxWidth:'50%'}}
             >
                Create New Disclosure
             </Button>
        {disclosures?.length>0 ? disclosures.map((disclosure,index) => (
            <DisclosureCard key={index} disclosure={disclosure} onDelete={()=>setDisclosures(disclosures.toSpliced(index,1))} onClick={()=>setOpenDisclosure(disclosure)}/>
        )) : <NotFoundComponent title='No Disclosures Created'/>}
        </Stack>}
        </Stack>
    </Box>  
    </>
    );
};

export default DisclosureComponent;