import React from 'react';
import { Box, Card, CardContent, IconButton, Stack, Typography } from '@mui/material';
import InfoButtonComponent, { ObjectDisplayer } from '../MUI/InfoButtonComponent';
import DeleteForever from '@mui/icons-material/DeleteForever';
import NotifyPopup from '../MUI/NotifyPopup';
import { deleteDisclosure } from '../../Hooks/disclosures';
import { H2, P } from '../MUI/Typography';

const feilds = [
    {key:'nonVelocity',label:'**Non-Velocity**'},
    {key:'description',label:'Description'},
    {key:'duration',label:'Duration'},
    {key:'sendPushOnVerification',label:'Notify on Complete'},
    {key:'disclosureId',label:'CAO ID'},
]


const DisclosureCard = ({disclosure,onDelete,onClick=()=>{}}) => {
    const [error, setError] = React.useState(undefined);
    return (<>
        {error && <NotifyPopup message={error} severity="error" onClose={()=>setError(undefined)}/>}
        <Card sx={{ '&:hover': { backgroundColor: 'lightgray' ,transition:'all 200ms ease-in-out '} , width:"100%"}}>
            

            <CardContent>
                <Stack direction='row' justifyContent='space-between' alignContent='' >
                <Typography variant="h5" component="div" onClick={onClick}>
                    {disclosure.purpose}
                </Typography>
                <Stack direction='row' spacing={0}>
                <InfoButtonComponent src={disclosure} title='Disclosure Data'/>
                <IconButton onClick={()=>{deleteDisclosure(disclosure._id).then( (data)=>{
                     data?.error ? setError(data.error) :
                     onDelete()
                    }
                    ).catch((e)=>setError(e))
              
                }}><DeleteForever/></IconButton>
                </Stack>
                </Stack>
                <Box onClick={onClick}>
                {feilds.map(feild=>(
                disclosure?.[feild.key] ? 
                <><H2>{feild.label}:</H2> <P>{disclosure?.[feild.key]}</P></> : null
                
                ))
                }
                <H2>Types:</H2>
                {disclosure.types?.length > 11 ? 'All Types' :disclosure.types.map((type,index)=>(<P>{type.type}</P>))}
                </Box>
            </CardContent>
        </Card>
    </>);
};

export default DisclosureCard;