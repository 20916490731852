import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
import { useState } from 'react';
import{ Link} from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Divider, FormHelperText, Stack } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { ParentError } from './Login';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import validate from '../LoginForm/LoginFormValidationRules';
import { useCookies } from 'react-cookie';
import loginUser from '../../Hooks/login';
import { Navigate } from 'react-router-dom';
import logo from '../../assets/images/VCLogoBG.png';
import GoogleLoginComponent from './GoogleLoginComponent';
import VelocityLoginComponent from './VelocityLoginComponent';



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

/**
 * Register component for user registration.
 * 
 * @param {Object} props - Component props.
 * @param {boolean} props.loading - Flag indicating if the component is in a loading state.
 * @param {Object} props.parentError - Error object from the parent component.
 * @returns {JSX.Element} - JSX element representing the Register component.
 */
export default function Register ({loading,parentError}) {

    const [cookies, setCookie] = useCookies(['AUTH_TOKEN']);
    const [error, setError] = useState({});

    const login = async e => {
        console.log(e);
        const {email, password,username} = e;
        // e.preventDefault();
        try{
        const credentials = await loginUser({
          email,
          password,
          username
        });
        // console.log(credentials); 
        setCookie('AUTH_TOKEN', credentials.authentication.sessionToken, { path: '/'});
        // parentCallback(true);
        // setUser(credentials);
        window.location.reload();
        return <Navigate to="/" />;
      }catch(err){
        console.log(err);
        console.log("register rejected");
        setError({status: "Error Creating Account,try changing your email or username"});
      }
    
      }




  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const errors = validate({
        email: data.get('email'),
        password: data.get('password'),
    });
    if(errors.email || errors.password){
        setError(errors);
        return;
    }
    console.log(errors);
    login({
      username: data.get('username'),
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
        
        <Box
        width={'100%'}
        border='1px solid'
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='100vh'
        sx={{
          background: `url(${logo})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backdropFilter: 'blur(100px)',
          'backdrop-filter': 'blur(5px)',
          '::-webkit-backdrop-filter': 'blur(10px)',
        }}
      >
        <Box
          sx={{

            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '10px',
            backdropFilter:'none'
          }}
          elevation={24}
          width={'600px'}
          component={Paper}
        >
            <Box sx={{my:5}}><Typography component="Typography" variant="h2" >Welcome</Typography></Box>
            {loading ? parentError ? <ParentError error={parentError}/> : <CircularProgress size={70} sx={{my:10}} disableShrink/> :<>
      
            
            <Avatar sx={{ m: 1, bgcolor: 'primary.error' }}>
              <LockOutlinedIcon/>
            </Avatar>
            <Typography component="h6" variant="h5">
              Create an Account
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{minWidth: '400px',
                    maxWidth: '500px'
                }}>
                   <Stack spacing={2} py={1}>
            <GoogleLoginComponent isRegister={true}/>
            <VelocityLoginComponent isRegister={true}/>
            </Stack>
                  <Divider >OR</Divider>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="👤Username"
                name="username"
                autoComplete="username"
                autoFocus
       
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="✉️Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                
              />
              <FormHelperText sx={{color: 'red'}}>{error.email}</FormHelperText>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="🔐Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormHelperText sx={{color: 'red'}}>{error.password}</FormHelperText>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <FormHelperText sx={{color: 'red'}}>{error.status}</FormHelperText>
              <Grid container>
                <Grid item>
                    <FormHelperText >{"Already Have an Account? "}
                      <Link to="/login" component='h1'>{"Log In."}</Link></FormHelperText>
                  
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box></>}
          </Box>
          </Box>
  
    </ThemeProvider>
  );
}