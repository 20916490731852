import { JSONPath } from "jsonpath-plus";


const velocityToSchemaType ={
"EmploymentCurrentV1.1":'Employment',
"EmploymentPastV1.1":'Employment',
'EducationDegreeGraduationV1.1':'EducationDegree',
'EducationDegreeStudyV1.1':'EducationDegree',
'EducationDegreeRegistrationV1.1':'EducationDegree',
'AssessmentV1.1':'Assessment',
'CertificationV1.1':'CertificationLicense',
'LicenseV1.1':'CertificationLicense',
'OpenBadgeV2.0':'Badge',
'CourseRegistrationV1.1':'Course',
'CourseCompletionV1.1':'Course',
'CourseAttendanceV1.1':'Course',
}

export const getVerifiableCredential = (credential,descriptor,image,context) => {
    // console.log(descriptor)
    if(!credential){
      console.log('No Credential Found');
      return null;
    }
    console.log('Credential:',credential);
    return  {
      '@context':[
        "https://www.w3.org/2018/credentials/v1",
        // "https://w3id.org/vdl/v1",
        // "https://w3id.org/vdl/aamva/v1",
        // "https://w3id.org/security/data-integrity/v2",
        'https://service.velocitycreds.com/images/layer1context.json',
      ],
      "id": "http://velocitycreds.com/credentials/3732",
      "type": [
        "VerifiableCredential",
        // ...credential.type
      ],
      // image,
      // "description": resPath(descriptor?.default?.title?.path,credential),
      // name: resPath(descriptor?.default?.subtitle.path,credential),
      "issuer": {
        "id": getOfferDID(credential.credentialSubject),
        
      },
      issuanceDate: new Date().toISOString(),
      
      "credentialSubject": {
        ...filter(credential.credentialSubject,context?.['@context']?.[velocityToSchemaType[credential.type?.[0]]]?.['@context']),
          type: velocityToSchemaType[credential?.type?.[0]] || credential.type?.[0],
        
      //  "id": "did:example:ebfeb1f712ebc6f1c276e12ec21"
      },
    };

}

const filter = (credential,context) => {
  if(!context) return credential;
 
  const filtered = {...credential};
  Object.keys(credential).forEach((key) => {
    if(!context[key]){
      delete filtered[key];
      console.log('Deleted:',key);
    }
  });
  return filtered;
}


const variableNames = [ 
    "legalEmployer", 
    "authority", 
    "institution",
     "school", 
     "provider", 
     "contentProvider",
     "hasCredential",
];

export const getOfferDID = (offer) => {
  if(!offer){
    console.log('No Offer Found');
    return null;
  }
    var did = null;
    variableNames.forEach((variableName) => {
        if (offer[variableName] && (offer[variableName].identifier || offer[variableName]?.id || offer[variableName].issuer?.id)){
            
            did = offer[variableName].identifier || offer[variableName].id || offer[variableName].issuer.id;
        }
    })
    if(!did){
        console.log('No DID Found:', offer);
    }else{
        console.log('DID found:',did);
    }
    return did;
}

export const resPath = (path,credential) => {
    if(!path?.[0]){return undefined;}
    return JSONPath({path: path?.[0], json:credential?.credentialSubject})[0];
}