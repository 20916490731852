import React, { useEffect, useMemo } from 'react';
import { useState, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { AppContext } from '../../AppContext';
import RequestHandler from './RequestHandler/RequestHandler';
import { useNavigate } from 'react-router-dom';
import updateClient from '../../Hooks/updateClient';
import FileUpload from '../FileUpload/FileUpload';
import Dropdown from 'react-dropdown';
import requests from '../../Api/requests';
import DomainIcon from '@mui/icons-material/Domain';
import { deleteClient } from '../../Hooks/delete';
import { H,H2, P2, H3, P3, P} from '../MUI/Typography';
import Button from '@mui/material/Button';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import EditNoteSharpIcon from '@mui/icons-material/EditNoteSharp';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Stack from '@mui/material/Stack';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import CountryPicker from '../MUI/CountryPicker';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import Box from '@mui/material/Box';
import { useTheme } from '@emotion/react';
import { superDeleteOrgData } from '../../Hooks/super';
import getClientFields from '../../Api/fields';
import {getOrgData} from '../../Api/fields';
/**
 * React component managing and seeing org data.
 * 
 * @param {Object} client - The client for the user.
 * @param {Function} setClient - The setState associated with the client data
 * @param {boolean} isSuper - if the component is being rendered in the superAdmin console
 * @returns {JSX.Element} The rendered component.
 */
const OrgView = ({client,setClient,isSuper}) => {
    // const theme = useTheme();
    // console.log(client);
    const [orgEditable, setOrgEditable] = useState(false);
    //coolies used for updating org
    const [cookies, setCookie] = useCookies(['AUTH_TOKEN']);
    const {role} = useContext(AppContext);
    const [orgData, setOrgData] = useState(undefined);
    // console.log(orgData);
    const navigate = useNavigate();
    const DeleteClient = async () => {
        if(isSuper){
            await superDeleteOrgData(client);
            window.location.reload();
        }else{
        const res = await deleteClient(client);
        console.log(res);
        window.location.reload();
        }
    }
    //fonction for updating client
    const changeClient = async e => {
        setOrgEditable(false);
        console.log(orgData);

        try{
            console.log(orgData);
            var credentials;
            credentials = await updateClient(orgData,cookies.AUTH_TOKEN);     
            setClient({...client,...orgData});
          }catch(err){
            console.log("Update rejected");
          }
    }
    //updates the orgData when the client changes
    useMemo(() => {
        setOrgData(getOrgData(client));
    },[client]);

    const clientFields = getClientFields(client);
    if(isSuper){
        clientFields.push({title:'Default DisclosureId',value:client?.defaultDisclosureId,key:'defaultDisclosureId'});
    }
    const changeData = (key,value) => {
        let data = orgData;
        data[key] = value;
        return data;
    }
    return (
        <div>
                   <div className='org__box'>
            <H><DomainIcon/> Organization Data <P3 style={{textDecoration:'underline'}}>{role && "Role: "+role}</P3></H>
            {client ? (<div>
                {client.requests?.length > 0 && <H2>Requests:</H2>}
                <div>
                    <div>{client.requests?.map((data) => (<div className = 'request__wrapper'><P3 >{data.username + " , "+ data.email}</P3><RequestHandler email={data.email} role={role} ></RequestHandler></div>))}</div>
                </div>
                <H2>Logo:</H2>
                {orgEditable && <FileUpload/> }
                {client.imageNames?.map((data)=><img className='org__logo' src={requests.baseURL+'/images/'+data} alt='Organization Logo'/>)}

                
                {clientFields.map((field,index) => (
                <H2 key={index}>{field.title+":"}
                {orgEditable ? <p><input className = "name__input"  defaultValue={field.value} type = "text" onChange={(e) => setOrgData(changeData(field.key,e.target.value))} onSubmit={changeClient}/></p> :
                     <P3 className = "userData">{field.value ?field.value : "Undefined"}</P3>}
                </H2>
                
                ))}

                <H2>Service Type:</H2>
                {orgEditable ? <Dropdown className = 'dropdown__serviceType' options={['Credential Agent Operator', 'Relying Party', 'Issuer of Career Credentials', 'Issuer of Identity Credentials', 'Notary Issuer',' Wallet App Provider',' Node Operator']} onChange={(e) => setOrgData(changeData('serviceType',e.value))} value={orgData.role} placeholder={client.serviceType} /> : 
                <P3 className = "userData">{client.serviceType ? client.serviceType : "Undefined"}</P3>
                }
                <H2>Country:</H2>
                {orgEditable ? <p><CountryPicker
                value={orgData.place?.addressCountry}
                onChange={(value) =>{
                        const data = orgData;
                        console.log("data",orgData);
                        if(data.place){
                            data.place.addressCountry = value;
                        }
                    return setOrgData(data)
                    // return console.log(orgData);
                    }}
                     
                /></p> :
                 <P3 className = "userData">{client.place?.addressCountry ? client.place?.addressCountry : "Undefined"}</P3>}
                 <H2>Region:</H2>
                {orgEditable ? <CountryPicker
                addressCountry={orgData.place?.addressCountry}
                value={orgData.place?.addressRegion}
                onChange={(value) =>{
                        const data = orgData;
                        console.log("data",orgData);
                        if(data.place){
                            data.place.addressRegion = value;
                        }
                    return setOrgData(data)
                    // return console.log(orgData);
                    }}
                     
                /> :
                 <P3 className = "userData">{client.place?.addressRegion ? client.place?.addressRegion : "Undefined"}</P3>}
                <H2>City Name:</H2>
                {orgEditable ? <input className = "name__input"  defaultValue={orgData.place?.addressLocality} type = "text" onChange={(e) => setOrgData(changeData('place',{...orgData.place,addressLocality:e.target.value}))} onSubmit={changeClient}/> :
                <P3 className = "userData">{client.place?.addressLocality ? client.place?.addressLocality : "Undefined"}</P3>}
                     
                <H2>Admins: <div className = "">{client.admin?.map((data) => (<P3 >{data.username + " , "+ data.email}</P3>))}</div></H2>
                <H2>Users:</H2>
                <div className='list__wrapper'>
                    <div>{client.users?.map((data) => (<P3 className='list__element'>{data.user.username + " , "+ data.user.email + ", Role: "+data?.role}</P3>))}</div>
                </div>
               
                <H2>Actions:</H2>
                {role === 'admin' && <>
                  <Button variant='outlined'
                  sx={{
                      minWidth: '80%',
                      minHeight: '50px',
                  }} 
                  onClick={orgEditable ? changeClient : setOrgEditable}
                  endIcon={orgEditable ? <FileDownloadDoneIcon/> : <EditNoteSharpIcon />}
                  color= {orgEditable ? 'success':'primary'}
                  >
                   {orgEditable ? "Save Changes" :"Edit Organization"}</Button>
                   <Box margin={1}></Box>
                   <Button variant='outlined'
                  sx={{
                      minWidth: '80%',
                      minHeight: '50px',
                  }} 
                  onClick={DeleteClient}
                  endIcon={<DeleteForeverSharpIcon />}
                  color= 'error'
                  >Delete Organization</Button></>
                   
                   
                   }
            </div>) : 
            
            (<div><H2>Looks Like you aren't registered to an organization</H2>{role ? <P2>Request: <H2>{role}</H2></P2> :
            <Stack className='org__wrapper' spacing={4}>
                <Button sx={{height:'60px'}} color='primary' variant='contained' onClick={()=>navigate('/LoginOrg')}
                endIcon={<SupervisorAccountIcon/>}
                fullWidth
                >Register to an Organization</Button>
                
                <Button sx={{height:'60px'}} color='warning' variant='contained' onClick={()=>navigate('/RegisterOrg')}
                fullWidth
                endIcon={<CorporateFareIcon/>}
                >Create an Organization</Button>
            </Stack>}
            
            </div>)}
            </div>
        </div>
    );
};

export default OrgView;