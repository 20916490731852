import React, { useState, useEffect, cloneElement, useRef, useCallback } from 'react';
import { Stack, Paper, Box, CircularProgress, Link, Grid, useMediaQuery } from '@mui/material';
import { H1, H2, P, P2 } from '../MUI/Typography';
import { JSONPath } from 'jsonpath-plus';
import axios from 'axios';

import Cookies from 'js-cookie';
import { getCredentialStatusColor, getIcon } from './IconBreakdown';
import { getDescriptor } from '../CredentialForm/CredentialSection';
import { CredentialCheck, Verified } from './CredentialCard';
import requests from '../../Api/requests';
import InfoButtonComponent from '../MUI/InfoButtonComponent';
const NestedCredentialCard=({credential,orgInfo,rawCredential,open,onToggle})=>{
    const [descriptor,setDescriptor] = useState(undefined);
    const [orgData,setOrgData] = useState(orgInfo);
    const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    // console.log('credential:',rawCredential)
    //respolves path from descriptor
    const resPath = (path) => {
        if(!path?.[0]){return undefined;}
        // console.log('Path:',path?.[0].replace('$.',''),"Result:",credential?.credentialSubject?.[path?.[0].replace('$.','')]);
        // console.log(credential?.credentialSubject?.school?.name);
        // return credential?.credentialSubject?.[path?.[0].replace('$.','')] || 'undefined';
        // console.log(JSONPath({path: path?.[0], json:credential?.credentialSubject}) || 'undefined');    
        return JSONPath({path: path?.[0], json:credential?.credentialSubject}) || 'undefined';
    } 

    // useEffect(() => {
    //     console.log('xs:',isXs);
    // }, [isXs]);
   
    

    //fetches data from the registry and descriptor from local files
    useEffect(() => {
        getDescriptor(credential?.type[0]).then((data) => {
            setDescriptor(data);
        }).catch((e) => console.log('Error:', e));
        if(!orgData){
        axios.get(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/${credential?.issuer?.[0]?.id}/verified-profile`).then((data) => {
            setOrgData(data.data);
        }).catch((e) => console.log('Error:', e));
        }
    }, []);

    return (
        (descriptor && orgData) ? 
        <>
            <Stack
                id='nested-credential-card'
                spacing={1}
                pl={1}
                // width='800px'
                width='100%'
                component={Paper}
                elevation={10}
                sx={{
                    bgcolor:'#fcfcfc',
                    transition:'all 0.2s ease-in-out',
                    '&:hover':{
                        cursor:'pointer',
                        boxShadow:'0px 0px 10px 5px #888888'
                    }
                }}
                height='100%'
                m={1}
            >
                
                <Stack
                    direction='row'
                    alignItems='center'
                    spacing={3}
                    pl={2}
                    borderBottom='3px solid gray'
                    borderRadius='5px'
                    textAlign='center'
                    width='100%'
                    onClick={()=>onToggle()}
                >
                    {open ? 
                        <>
                            <H1 fontSize={50}>
                                {cloneElement(getIcon(descriptor?.default?.description?.text || credential?.type[0]),{sx:{transform:'scale(1.5)'}})}
                            </H1>
                            <H1   textAlign='center' >
                                {" " +descriptor?.default?.description?.text || credential?.type[0]}
                            </H1>
                            
                        </> :  
                        <>
                            {/* Org Logo and title */}
                            <img src={orgData?.credentialSubject?.logo} alt={'Organization Logo'} width={isSm?50:100} height={isSm?50:100}/>
                            <H1  color='inherit' textAlign='center' width={isSm?'100px':'450px'} fontSize={isSm&&12}>
                                {resPath(descriptor?.default?.subtitle?.path) || descriptor?.default?.description?.text}
                            </H1>
                            {!isSm && <H2 textAlign='center' sx={{width:'100px'}} >
                                {resPath(descriptor?.default?.title?.path) || credential?.type[0]}
                            </H2>}
                            <Verified isVerified={getCredentialStatusColor(credential)} success={'Credential is Verified'} warning='Credential is Partially Verified' error='Credential is not Verified'/>
                        </>
                    }
                </Stack>
                {open && 
                    <>
                    <Box textAlign='center'>
                    <H2 color='inherit' fontSize={20} sx={{textDecoration:'underline'}}>{resPath(descriptor?.default?.subtitle?.path) || descriptor?.default?.description?.text}</H2>
                    </Box>
                        <Stack
                            direction='row'
                            spacing={1}
                            alignCItems='center'
                            justifyItems='center'
                            textAlign='center'
                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <Box Justify>
                                <img src={orgData?.credentialSubject?.logo} alt={'Organization Logo'} width={100} height={100}/>
                            </Box>
                            <Box >
                                <P2 >Issued By <a style={{color:'#4682B4'}} href={orgData?.credentialSubject?.website} target={'_blank'}>{orgData?.credentialSubject?.name}</a></P2>
                            </Box>
                            <Verified isVerified={orgData?.credentialChecks?.TRUSTED_ISSUER} success={orgData?.credentialSubject?.name+" is a Trusted Issuer"}/>
                        </Stack>
                        <CredentialCheck checkData={ credential?.credentialChecks} rawCredential={rawCredential}/>
                        <H2>Details:</H2>
                        <Grid component={Paper} elevation={4} container sx={{bgcolor:'#fcfcfc'}} p={2} height='100%' alignContent='' width='100%' >
                            {descriptor?.properties?.map((property,index) => {
                                const value = resPath(property.path)
                                return(value?.length>0 && value[0]!=='' &&<>
                                    <Grid item xs={2.5}>
                                            <H2>{property.label}:</H2>
                                        </Grid>
                                    {/* <Stack key={index} direction='row' spacing={1} alignItems='center' justifyItems='center' textAlign='center'  pl={2}> */}
                                    <Grid item xs={9.5}>
                                        {value?.map((val,index)=>
                                            val?.startsWith('http')?
                                            val?.endsWith('.png') || val?.includes('.jpg')?
                                            <img src={val} alt={val} width={100} height={100}/>
                                            :<Link href={val} target='_blank' noWrap height='100%'>{val}</Link>
                                            :val?.length > 50 ? <P sx={{fontSize:15}}>{val}</P>
                                            :<P2>{val}</P2>
                                        )}
                                    
                                    </Grid>
                                </>)
                            })}
                        </Grid>
                    </>
                }
            </Stack>
        </>:
        <Box component={Paper} elevation={3} p={1}sx={{width:'800px', display: 'flex', alignItems: 'center', justifyContent: 'center',minHeight:'500px'}}>
            <CircularProgress/>
        </Box>
    );
}

export default NestedCredentialCard;