import axios from 'axios';
import requests from '../Api/requests';
import Cookies from 'js-cookie';

export const getInvtations = async () => {
    try {
        return await axios.get(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/invitations`)
            .then(data => data.data);
    } catch (error) {
        console.log("Error:", error);
        return { error: error };
    }
}
export const createInvitation = async (data) => {
    try {
        return await axios.post(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/invitations`, data)
            .then(data => data.data);
    } catch (error) {
        console.log("Error:", error);
        return { error: error };
    }
}
export const resendInvitation = async (data) => {
    try {
        const {id,...rest} =data
        return await axios.put(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/invitations/${id}`, rest)
            .then(data => data.data);
    } catch (error) {
        console.log("Error:", error);
        return { error: error };
    }
}
export const deleteInvitation = async (id) => {
    try {
        return await axios.delete(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/invitations/${id}`)
            .then(data => data.data);
    } catch (error) {
        console.log("Error:", error);
        return { error: error };
    }
}