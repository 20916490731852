import React from 'react';
import Header from '../Components/Header/Header.js';
import Nav from '../Components/Navbar/Nav.js';
import Results from '../Components/Results/Results.js';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import MantisDashboard from '../Components/MantisDashboard/index.jsx';
import MantisHeader from '../Components/MantisHeader/index.jsx';
import { useGetMenuMaster } from '../Api/menu';
import { drawerWidth } from '../config.js';
import { Box } from '@mui/material';
const HomeScreen = () => {
  const { menuMaster } = useGetMenuMaster();
  const drawerOpen = menuMaster?.isDashboardDrawerOpened;
    return (
    <div className="app">
    {/* <Header />
    <Grid container component="main" sx={{ height: '100vh' }}>
    <Grid
    // boxShadow={3}
          item
          xs={false}
        //   sm={4}
          md={3}
          pt={6}
        >

        <Nav />
        </Grid>
        <Grid item xs={12} 
        // sm={8} 
        md={7}
        >
        <Results />
        </Grid>
        <Grid item xs={12} 
        // sm={8} 
        md={2} 
        // boxShadow={3}
        ></Grid>
    </Grid> */}
    <MantisHeader>
      <MantisDashboard />
    </MantisHeader>
      <div style={{display:'none'}}><Nav/></div>
    
    
    </div>
    );
};
export default HomeScreen;