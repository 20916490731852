import React,{useState,useContext} from 'react';
import './Header.css';
import { Outlet, Link,useLocation } from "react-router-dom";
import { AppContext } from '../../AppContext';
import AppAppBar from '../MUI/AppAppBar';





function Header({options=['Home']}) {
  const {client} = useContext(AppContext);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const location = useLocation().pathname;

  return (<>
    <AppAppBar mode="light" client ={client} options={options}/>
    <header className="header"/>
    {/* <header className="header">
      <div className="header__icons">
      <Link to='../' className = {"header__link"}>
        <div className= {location === '/' ? 'header__icon--active' :'header__icon' }>
          <svg viewBox="0 0 20 20" fill="currentColor" className="home w-6 h-6">
            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
          </svg>
          <p>Home</p>
        </div>
        </Link>
        <Link to='../Issue' className = {"header__link"}>
        <div className={location === '/Issue' ? 'header__icon--active' :'header__icon' }>
          <svg
            viewBox="0 0 20 20"
            fill="currentColor"
            className="check-circle w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
          <p>Issue a Credential</p>
        </div>
        </Link>
        <div className="header__icon">
          <svg
            viewBox="0 0 20 20"
            fill="currentColor"
            className="collection w-6 h-6"
          >
            <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>
          </svg>
          <p>My Wallet</p>
        </div>
        <Link to='../Account' className = {"header__link"}>
        <div className= {location === '/Account' ? 'header__icon--active' :'header__icon' }>
        
          <svg viewBox="0 0 20 20" fill="currentColor" className="user w-6 h-6">
            <path
              fillRule="evenodd"
              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
              clipRule="evenodd"
            ></path>
            {client && (client.requests.length && <circle r="3" cx="17" cy="5" fill="red" />)}
          </svg>
          <p>Account</p>
          
        </div>
        </Link>
        <button
          onClick={() => setIsSearchOpen(!isSearchOpen)}
          className={`header__icon ${isSearchOpen ? "search__open" : ""}`}
        >
        <div className="header__icon">
          <svg
            viewBox="0 0 20 20"
            fill="currentColor"
            className="search w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            ></path>
          </svg>
          <p>Search</p>

        </div>
        </button>
        {isSearchOpen && (<input className='input__search'/>)}
      </div>
      <img
        src="https://www.velocitynetwork.foundation/wp-content/uploads/2020/02/fb.jpg"
        alt="Velocity Network Foundation"
      />
    </header> */}
</>);
}

export default Header;
