import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {IconButton, Stack } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Dot from '../MantisDashboard/components/@extended/Dot';
import ThemeCustomization from '../../themes';
import { grey } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EmailIcon from '@mui/icons-material/Email';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingIconButton from '../MUI/LoadingIconButton.js';



export const typeNames={
  'EmploymentCurrentV1.1': 'Current Employment',
  'EmploymentPastV1.1': 'Past Employment',
  'Assessmentv1.1': 'Assessment',
  'Certificationv1.1': 'Certification',
  'Licensev1.1': 'License',
  'CourseAttendancev1.1': 'Course Attendance',
  'CourseCompletionv1.1': 'Course Completion',
  'CourseRegistrationv1.1': 'Course Registration',
  'EducationDegreeGraduationv1.1': 'Education Degree Graduation',
  'EducationDegreeRegistrationv1.1': 'Education Degree Registration',
  'EducationDegreeStudyv1.1': 'Education Degree Study',
  'OpenBadgeV2.0': 'Badge',
}
const theme = createTheme({
  palette: {
    primary: grey,
    secondary: {
      main: "#000000",
    },
  },
});
const getName = (data) => (data?.hasCredential?.name || data?.role || data?.name || data?.courseName ||data?.degreeName)
const fontSize = {xs:10,sm:11,md:12,lg:12,xl:15}
  // applied as rows in the form
  export const getRows =  (data) =>{ 
    if(data === undefined || data.message){
      return [];
    }

    return data?.map((data, index) => {
    try{
      // console.log("Data:",data.credentialSubject.title.localized.en);
      // console.log("removeOffer:",data);
    return ({
    key: `row-${index}-${data.__v}`,
    cells: [
      {
        key: typeNames[data.type]+'-'+index,
        content: (
          <div>
            <Typography  color='grey.500' pr={0} fontSize={fontSize}>{typeNames[data.type] || data.type}</Typography>
          </div>
        ),
      },
      {
        key: getName(data)+'-'+index,
        content: (
          <div>
            <Typography  color='text.primary' pr={0} fontSize={fontSize}>{getName(data)}</Typography>
          </div>
        ),
      },
      {
        key: data.email,
        content:(<Typography component='p' variant='h6' color='text.primary' ml={0} pr={0} fontSize={fontSize}> {data.email}</Typography>),
      },
      {
        key: data.offerCreationDate,
        content:(<Typography   component='p' variant='h6' color='grey.500' ml={0} pr={0} fontSize={fontSize} >{new Date(data.offerCreationDate).toLocaleDateString()}</Typography>),
      },
      {
        key: (data.status || 'pending')+'-'+data.index,
        content:(<Stack direction="row" spacing={1} alignItems="center">
          <Dot color={data.status ? 'success' :'warning'}/>
        <Typography   component='p' variant='h6' color='grey.500' ml={0} pr={0} fontSize={fontSize} >{data.status || 'Unclaimed'}</Typography>
        </Stack>),
      },
      {
        key: `${data._id}-${index}`,
        content:(
        <Stack direction='row'
        pr={0}
        justifyContent={'flex-start'}
        spacing={0} 
        >
        <ThemeProvider theme={theme}><IconButton
        id={data._id}
        onClick={()=>{
          // console.log("IssuedTable:childSetView",data);
          return data.view(data)}}
        variant='outlined'
        color='secondary'
        title='Expand Offer'  
        ><OpenInFullIcon/></IconButton>

          <LoadingIconButton
            title='Send Email Notification'
            variant='outlined'
            color='info'
            id={data._id} 
            icon={<EmailIcon/>}
            isLoading={data.emailLoading}
            isDone={data.emailSent}
            doneIcon={<MarkEmailReadIcon/>}
            onClick={data.sendEmail}
            useView={data}
            />
           


          {data.canRemove && <IconButton
          title='Delete Offer'
          variant='outlined'
          color='error'
            id={data._id} 
            onClick={data.removeOffer}><DeleteForeverIcon/></IconButton>
          }
        </ThemeProvider>
      </Stack>),
      }
    ]
  })
}catch(err){
    console.log("Error:",err);
  }})};

  //FOR ISSUING TABLE
  export const getIssuedRows =  (data) =>{ 
    if(data === undefined || data.message){
      return [];
    }

    return data?.map((data, index) => {
    try{
      // console.log("Data:",data.credentialSubject.title.localized.en);
      // console.log("removeOffer:",data);
    return ({
    key: `row-${index}-${data.__v}`,
    cells: [
      {
        key: (typeNames[data.type[0]] ||data.type[0])+'-'+index,
        content: (
          <div>
            <Typography  color='grey.500' pr={0} fontSize={fontSize}>{typeNames[data.type[0]] ||data.type[0]}</Typography>
          </div>
        ),
      },
      {
        key: getName(data)+'-'+index,
        content: (
          <div >
            <Typography  color='grey.400' pr={0} fontSize={fontSize}>{getName(data) || data?.exchangeId}</Typography>
          </div>
        ),
      },
      {
        key: data.credentialSubject?.vendorUserId,
        content:(<Typography component='p' variant='h6' color='text.primary' ml={0} pr={0} fontSize={fontSize}> {data.credentialSubject?.vendorUserId}</Typography>),
      },
      {
        key: data.issued,
        content:(<Typography   component='p' variant='h6' color='grey.500' ml={0} pr={0} fontSize={fontSize} >{new Date(data.issued).toLocaleDateString()}</Typography>),
      },
      {
        key: (data.status || 'issued')+'-'+data.index,
        content:(<Stack direction="row" spacing={1} alignItems="center">
          <Dot color={data.status ? 'error' :'success'}/>
        <Typography   component='p' variant='h6' color='grey.500' ml={0} pr={0} fontSize={fontSize} >{data.status || 'Issued'}</Typography>
        </Stack>),
      },
      {
        key: `${data._id}`,
        content:(
        <Stack direction='row'
        pr={0}
        justifyContent={'flex-start'}
        spacing={2}
        >
          <ThemeProvider theme={theme}>
        <Button
        id={data.id}
        onClick={()=>{
          console.log("IssuedTable:childSetView",data);
          return data.view(data)}}
        variant='outlined'
        color='secondary'
        title='View Credential Details'  
        ><OpenInFullIcon/></Button>
          {data.canRemove && <IconButton
          title='Revoke Credential'
          variant='outlined'
          color='error'
            id={data.id} 
            onClick={()=>data.removeOffer(data)}><DeleteForeverIcon/></IconButton>}
        </ThemeProvider>
      </Stack>),
      }
    ]
  })
}catch(err){
    console.log("Error:",err);
  }})};

  // const createKey = (input) => {
  //   return input ? input.replace(/^(the|a|an)/, "").replace(/\s/g, "") : input;
  // }

  export const head = {
    cells: [
      {
        key: "type",
        content: "Type",
        isSortable: true,
      },
      {
        key: "title",
        content: "Title",
        isSortable: true,
      },
      {
        key: "issued-To",
        content: "Issued To",
        shouldTruncate: true,
        isSortable: true,
      },
      {
        key: "date",
        content: "Creation Date",
        shouldTruncate: true,
        isSortable: true,
      },
      {
        key: "status",
        content: "Status",
        shouldTruncate: true,
        isSortable: true,
      },
      {
        key: "actions",
        content: "Actions",
        shouldTruncate: true,
        // isSortable: true,
      },
    ],
  };

  export const issuedHead = {
    cells: [
      {
        key: "type",
        content: "Type",
        isSortable: true,
      },
      {
        key: "title",
        content: "Offer Id",
        isSortable: true,
      },
      {
        key: "issued-To",
        content: "Issued To",
        shouldTruncate: true,
        isSortable: true,
      },
      {
        key: "date",
        content: "Creation Date",
        shouldTruncate: true,
        isSortable: true,
      },
      {
        key: "status",
        content: "Status",
        shouldTruncate: true,
        isSortable: true,
      },
      {
        key: "actions",
        content: "Actions",
        shouldTruncate: true,
        // isSortable: true,
      },
    ],
  }