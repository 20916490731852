import ThemeCustomization from './themes'
import React, { createContext, useState } from 'react';
import { createTheme } from '@mui/material';
import GiftOutlined from '@ant-design/icons/GiftOutlined';
import MessageOutlined from '@ant-design/icons/MessageOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
export const AppContext = createContext();
const AppProvider = ({ children }) => {
  const [type, setType] = useState('Offers');
  const [user,setUser] = useState({
    username: "",
    password: ""
  });
  const [client,setClient] = useState(undefined);
  const [role,setRole] = useState(undefined);
  const [notifications,setNotifications] = useState([]);
  const [mode, setMode] = useState('light');
  return (
    <AppContext.Provider value={{ type, setType,user,setUser,client,setClient,role,setRole,notifications,setNotifications,mode,setMode}}>
      <ThemeCustomization>
      {children}
      </ThemeCustomization>
    </AppContext.Provider>
  );
};

export { AppProvider };
