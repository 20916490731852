import axios from 'axios';
import requests from '../../Api/requests.js';
import Cookies from 'js-cookie';

export async function getDisclolsureQRCode(disclosureId) {
    try {
        const response = await axios.get(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/disclosures/${disclosureId}/qr-code`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (err) {
        return err;
    }
}

export async function getShareCode(credentialId) {
    try {
        const response = await axios.get(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/share-credential/${credentialId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (err) {
        return err;
    }
}
export async function decodeShareCode(code) {
    try {
        const response = await axios.get(`${requests.baseURL}/api/share-credential/${code}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (err) {
        return err;
    }
}