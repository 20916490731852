import { Box, Paper ,Stack, useTheme} from '@mui/material';
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import {H1, H2,P,P2,P3} from './Typography.js';
import { typeNames } from '../Table/tableData';
import ReactJson from 'react-json-view';
import get from '../../Hooks/getRequest.js';
import { ObjectDisplayer } from './InfoButtonComponent.js';


const getDescription = (description) => {
    if(description?.length > 50){
        return <P>{description}</P>
    }
    return <P2>{description}</P2>
}
const getEmail=(offerData)=>offerData?.email || offerData?.vendorUserId
const OfferCard = ({offerData,...props}) => {
    // console.log ("props",props)
    const theme= useTheme();
    useEffect(()=>console.log('Offer Data:',offerData),[offerData]);
    return (
        <Grid container spacing={2}  component={Paper} 
        
        sx={{ 
            background: theme.palette.mode ==='dark' ? "linear-gradient(to right, #444, #555)" : "linear-gradient(to right, #ffffff, #efefef )",
            transition: "all 0.2s ease-in-out",
        "&:hover": { transform: "scale(1.03)",
        },
        
        }} {...props}>
            <Grid item xs={6} sm={6}>
            {offerData?.status=== 'Issued' && <Stack direction={'row'} spacing={1}><H2>Status: </H2><H2 sx={{textDecoration:'underline'}}color='green'> Issued</H2></Stack>}
            {offerData?.nonVelocity && <Stack direction={'row'} spacing={1}><H2>Type: </H2><H2 sx={{textDecoration:'underline'}}color='awrning'> Non-Velocity Credential</H2></Stack>}
            <H2>Credential Type:</H2>
            <P2> {offerData?.type}</P2>
            {offerData?.type?.includes('Employment') &&<EmploymentView offerData={offerData}/>}
            {offerData?.type?.includes('Degree') &&<DegreeView offerData={offerData}/>}
            {offerData?.type?.includes('Course') &&<CourseView offerData={offerData}/>}
            {offerData?.type?.includes('License') &&<LicenseView offerData={offerData}/>}
            {offerData?.type?.includes('Certification') &&<CertificationView offerData={offerData}/>}
            {offerData?.type?.includes('Assessment') &&<AssesmentView offerData={offerData}/>}    
            {offerData?.type ==='Badge' &&<BadgeView offerData={offerData}/>}
            {offerData?.type?.includes('VerifiableCredential') &&<IssuedView offerData={offerData}/>}


                {/* Offer Footer */}
                {offerData.offerCreationDate && <Stack spacing={1} pt={5}>
                    <P fontSize={10}>Offer Creation Date: {offerData?.offerCreationDate}</P>
                    <P fontSize={10}>Offer Expiration Date: {offerData?.offerExpirationDate}</P>
                    <P fontSize={10}>Offer Creation Date: {offerData?.offerCreationDate}</P>
                    <P fontSize={10}>Offer Expiration Date: {offerData?.offerExpirationDate}</P>
                    <P fontSize={10}>Offer ID: {offerData?.offerId}</P>
                </Stack>}
            </Grid>
            <Grid item xs={6} sm={6} sx={
                {
                     maxHeight:'500px',
                    overflowY:'scroll',
                    overflowX:'hidden',
                    height:'100%',
                    border:'1px solid #ccc',
                    borderRadius:2,
                }
            } >
                <Stack height={'100%'}>
                    <H2>Offer JSON:</H2>
            {/* <ReactJson src={offerData} displayDataTypes={false} displayObjectSize={false} theme={theme.palette.mode ==='dark' ? 'eighties' :'rjv-default'}/> */}
            <ObjectDisplayer src={offerData}/>
            </Stack>
            </Grid>
        </Grid>
    );
};

export default OfferCard;

const IssuedView = ({offerData}) => {
    return (<Stack>
    <H2>Issued To: </H2>
    <P2>{offerData?.credentialSubject?.vendorUserId}</P2>
    <H2>Offer Type:</H2>
    <P2> {offerData?.type}</P2>
    <H2>Created Date:</H2>
    <P3> {offerData?.createdAt}</P3>
    <H2>Issued Date:</H2>
    <P3> {offerData?.issued}</P3>
    {offerData?.expirationDate && <H2>Expiration Date: </H2>}
    <P3>{offerData?.expirationDate}</P3>
    </Stack>);
};


const LicenseView = ({offerData}) => {
    return (<Stack>
    <H2>License Name:</H2>
    <P2> {offerData?.name}</P2>
    <H2>License Description:</H2>
    <P2>{offerData?.description}</P2>
    {offerData?.authority?.name &&<>
    <H2>License Issuer:</H2>
    <P2>{offerData?.authority?.name}</P2></>}
    <H2>License Recipient:</H2>
    <P2>{offerData.recipient.givenName + " "+ offerData.recipient.familyName}({offerData.email})</P2>
    <H2>License Expiration:</H2>
    <P2> {offerData?.validity?.validUntil}</P2>
    </Stack>);
}

const DegreeView = ({offerData}) => {
    return (<Stack>
    <H2>Degree Name:</H2>
    <P2> {offerData.degreeName}</P2>
    <H2>From University: </H2>
    <P2>{offerData?.institution?.name}</P2>
    <H2>Issued To: </H2>
    <P2>{offerData?.recipient?.givenName +" "+ offerData.recipient.familyName + " ("+getEmail(offerData)    +")"}</P2>
    <H2>Offer Type:</H2>
    <P2> {offerData?.type}</P2>
    <H2>Conferred Date:</H2>
    <P3> {offerData?.conferredDate}</P3>
    {offerData.endDate && <H2>End Date: </H2>}
    <P3>{offerData.endDate}</P3>
    </Stack>);
};

const CourseView = ({offerData}) => {
    return (<Stack>
    <H2>Course Name:</H2>
    <P2> {offerData?.courseName}</P2>
    <H2>Course Description:</H2>
    {getDescription(offerData?.description)}
    <H2>Course Issuer:</H2>
    <P2>{offerData?.provider?.name}</P2>
    <H2>Course Recipient:</H2>
    <P2>{offerData?.recipient?.givenName + " "+ offerData?.recipient?.familyName}({getEmail(offerData)})</P2>
    <H2>Course Expiration:</H2>
    <P2> {offerData?.offerExpirationDate}</P2>
    </Stack>);
}

const BadgeView = ({offerData}) => {
    return (<Stack  >
    <H2>Badge Name:</H2>
    <P2> {offerData.hasCredential.name}</P2>
    <H2>Badge Description:</H2>
    {getDescription(offerData?.description)}
    <H2>Badge Issuer:</H2>
    <P2>{offerData.hasCredential.issuer.name}</P2>
    <H2>Badge Recipient:</H2>
    <P2>({getEmail(offerData)})</P2>
    <H2>Badge Expiration:</H2>
    <P2> {offerData.offerExpirationDate}</P2>
    <Stack spacing={1} pt={5}>
    <P>Badge Creation Date: {offerData.offerCreationDate}</P>
    <P>Offer ID: {offerData.offerId}</P>
    </Stack>
    <Stack>
    <H2>Badge Image:</H2>
    <img src={offerData.hasCredential.image} alt='Badge Image' style={{width:'300px'}}/>
    </Stack>
    
    </Stack>);
}

const EmploymentView = ({offerData}) => {
    console.log(offerData);
  return (<div>
  <H2>Job Title:</H2>
  <P2> {offerData.role}</P2>
  <H2>From Company: </H2>
  <P2>{offerData.legalEmployer.name}</P2>
  <H2>Issued To: </H2>
  <P2>{offerData.recipient.givenName +" "+ offerData.recipient.familyName + " ("+getEmail(offerData)+")"}</P2>
  <H2>Offer Type:</H2>
  <P2> {offerData?.type}</P2>
  <H2>Start Date:</H2>
  <P3> {offerData.startDate}</P3>
  {offerData.endDate && <>
  <H2>End Date: </H2>
  <P3>{offerData.endDate}</P3></>
  }

  </div>);
};

const CertificationView = ({offerData}) => {
    return (<Stack>
    <H2>Certification Name:</H2>
    <P2> {offerData?.name}</P2>
    <H2>From Company: </H2>
    <P2>{offerData?.authority?.name}</P2>
    <H2>Issued To: </H2>
    <P2>{offerData?.recipient?.givenName +" "+ offerData.recipient.familyName + " ("+getEmail(offerData)+")"}</P2>
    <H2>Offer Type:</H2>
    <P2> {offerData?.type}</P2>
    <H2>Description:</H2>
    {getDescription(offerData?.description)}
    {offerData.endDate && <H2>End Date: </H2>}
    <P3>{offerData.endDate}</P3>
    </Stack>);
};


const AssesmentView = ({offerData}) => {
    return (<Stack>
    <H2>Assesment Name:</H2>
    <P2> {offerData?.name}</P2>
    <H2>From Company: </H2>
    <P2>{offerData?.authority?.name}</P2>
    <H2>Issued To: </H2>
    <P2>{offerData?.recipient?.givenName +" "+ offerData.recipient.familyName + " ("+getEmail(offerData)+")"}</P2>
    <H2>Assesses:</H2>
    {getDescription(offerData?.assesses)}
    <H2>Description:</H2>
    {getDescription(offerData?.description)}
    {offerData.endDate && <H2>End Date: </H2>}
    <P3>{offerData.endDate}</P3>
    </Stack>);
};

