import React from 'react';
import { useState, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { AppContext } from './../../AppContext';
import updateUser from '../../Hooks/patchRequest';
import deleteUser from '../../Hooks/delete';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CSSBaseline from '@mui/material/CssBaseline';
import EditNoteSharpIcon from '@mui/icons-material/EditNoteSharp';
import MeetingRoomSharpIcon from '@mui/icons-material/MeetingRoomSharp';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Stack } from '@mui/material';
import { H,H2, P2, H3, P3, P } from '../MUI/Typography';
import PopoverComponent from '../MUI/PopoverComponent';

/**
 * React component for updating and managing user data.
 * 
 * @param {Object} user - The object for the userdata.
 * @param {Function} setUser - The setState associated with the user data
 * @param {boolean} isSuper - if the component is being rendered in the superAdmin console
 * @returns {JSX.Element} The rendered component.
 */
const UserView = ({user,setUser,isSuper}) => {

    const [cookies, setCookie] = useCookies(['AUTH_TOKEN']);
    
    const [editable, setEditable] = useState(false);
    const [newName, setNewName] = useState(user && user.username);
    const [newGoogleId, setNewGoogleId] = useState(user && user.googleId);
    const [newImageUrl, setNewImageUrl] = useState(user && user.imageUrl);
    const [error, setError] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const updateName = async e => {
        setEditable(false);
        user.username = newName;
        user.googleId = newGoogleId;
        user.imageUrl = newImageUrl;
        try{
            const credentials = await updateUser(user);
            setUser(credentials);
            // return <Navigate to="/" />;
          }catch(err){
            console.log("Update rejected");
          }
    }
    const removeUser = () => {
        deleteUser(user);
        if(!isSuper){
        setCookie('AUTH_TOKEN', '', { path: '/' });
        }
        window.location.reload();
        
    }
    const logout = () => {
        setCookie('AUTH_TOKEN', '', { path: '/' });
        window.location.reload();
    }

    return (
        <div>
            <CSSBaseline />
            <div className='org__box'>
            <H><AccountCircleIcon/> Account Data:</H>
{user ? (<div>
    <H2>Username:
                    {editable ? <p><input className = "name__input"  defaultValue={user.username} type = "text" onChange={(e) => setNewName(e.target.value)} onSubmit={updateName}/></p> :
                     <P2>{user.username && user.username}</P2>
                     }
                   
                     </H2>
    
                     <H2>Email:
                     <P2>{user.email && user.email}</P2>
                     <H2>Google ID:
    {editable ? <p><input className = "name__input"  defaultValue={user.googleId} type = "text" onChange={(e) => setNewGoogleId(e.target.value)} onSubmit={updateName}/></p> :
                     <P>{user.googleId && user.googleId}</P>
                     }
                   
                     </H2>
    <H2>Profile Image:
    {editable ? <p><input className = "name__input"  defaultValue={user.imageUrl} type = "text" onChange={(e) => setNewImageUrl(e.target.value)} onSubmit={updateName}/></p> :
                     <img src={user.imageUrl && user.imageUrl} alt="profile" width="32" height="32" style={{borderRadius:'32px',marginLeft:'15px'}}/>
                     }
                   
                     </H2>
                      </H2>
                     <H2>Authentication:</H2>
                     <Stack spacing={2} ml={4}>
                    <H3>Identifier: <P3 className='auth'>{user._id && user._id}</P3></H3>
                    <div>{user.authentication && (<div>
                        <P3>Token: <p className='auth'>{(user.authentication.sessionToken && user.authentication.sessionToken)}</p></P3>
                    <H3>Password: <P3 className='auth'>{user.authentication.password && user.authentication.password}</P3></H3></div>)}</div>
                    </Stack>
             
                
                <div className = "actions">
                <H2>Actions:</H2>
                <Stack  spacing={2} maxWidth={{xs:'500px',sm:'200px',md:'300px',lg:'500px',xl:'600px'}} >
                <Button variant='outlined'
                fullWidth
                sx={{
                    minHeight: '50px',
                }} 
                onClick={editable ? updateName : setEditable}
                endIcon={editable ? <FileDownloadDoneIcon/> : <EditNoteSharpIcon />}
                color= {editable ? 'success':'primary'}
                >
                    
                 {editable ? "Save Changes" :"Edit Account"}</Button>
                 {!isSuper &&<Button variant='outlined'
                 fullWidth
                sx={{
                    
                    minHeight: '50px',
                }} 
                onClick={logout}
                endIcon={<MeetingRoomSharpIcon />}
                color='warning'
                >
                 Log Out</Button>}
                 <Button variant='outlined'
                 fullWidth
                sx={{
                   
                    minHeight: '50px',
                }} 
                
                onClick={()=>setConfirm(true)}
                endIcon={<DeleteForeverSharpIcon />}
                color='error'
                >
                 Delete Account</Button>
                    {confirm && <PopoverComponent open={confirm} onClose={()=>setConfirm(false)} showBackground={true} topClose={true}>
                        <Stack width='100%' spacing={2}>
                            <H2>Are you sure you want to delete your account?</H2>
                            <P2>This action is irreversible</P2>
                            <Stack direction='row' spacing={2}>
                                <Button variant='outlined' onClick={removeUser} color='error'>Yes</Button>
                                <Button variant='outlined' onClick={()=>setConfirm(false)} color='success'>No</Button>
                            </Stack>
                        </Stack>
                        </PopoverComponent>}
                {/* <button className = "logout__btn delete__btn" onClick={logout}>Log Out</button> */}
                {/* <button className = "delete__btn" onClick={removeUser}>Delete Account</button> */}
                </Stack>
                </div>
                
            </div>) : (<div><h2>Looks Like you aren't registered</h2></div>)}
        </div>
        </div>
    );
};

export default UserView;