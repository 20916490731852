
import React, { useCallback, useEffect } from 'react';
import { Button, Stack,Box } from '@mui/material';
import PopoverComponent from '../MUI/PopoverComponent';
import { H1, H2 } from '../MUI/Typography';
import EditIcon from '@mui/icons-material/Edit';
import {CircularProgress} from '@mui/material';
import { deleteInvitation, getInvtations, resendInvitation } from '../../Hooks/invitations';
import ReactJson from 'react-json-view';
import DeleteForever from '@mui/icons-material/DeleteForever';
import ScheduleSend from '@mui/icons-material/ScheduleSend';


const resendInvite = ({id,inviteeEmail,callback}) => {
    resendInvitation({id,inviteeEmail}).then((data) => {
        if(data.error){
            console.log(data.error);
            callback("Error :"+ data.error?.message || "Error in resending the invite");
        }else{
            callback("Success: Invite Resent Successfully");
        }
        
    });
}

const deleteInvite = ({id,callback}) => {
    deleteInvitation(id).then((data) => {
        if(data.error){
            console.log(data.error);
            callback && callback("Error :"+ data.error?.message || "Error in Deleting Offer");
        }else{
            callback && callback("Success: Invite Deleted Successfully");
        }
    });
}
/**
 * Renders a component for managing invitations.
 *
 * @component
 * @returns {JSX.Element} The rendered InvitationComponent.
 */
const InviteCard = ({invite,callback}) => {
    const [open, setOpen] = React.useState(false);
    const [shown,setShown] = React.useState(true);
    return (
        
        shown&& !open ?<>
        <Box fullWidth border='1px solid black' component='label' color="inherit" variant='outlined' borderRadius='4px' sx={{'&:hover':{scale:1.1},py:3}}>
        <Stack direction='row'  spacing={2} px={2}>
            <H2 color='inherit'>{invite?.inviteeProfile?.name || "Org Name"}</H2>
            <H2>{invite?.inviteeEmail || "Email"}</H2>
            <Stack direction='row' spacing={1}>
            <Button variant='outlined' color='inherit' endIcon={<EditIcon/>} onClick={()=>setOpen(true)} >View</Button>
            <Button variant='outlined' color='warning' endIcon={<ScheduleSend/>} onClick={()=>resendInvite({id:invite?.id,inviteeEmail:invite?.inviteeEmail,callback:callback})} fullWidth>Resend</Button>
            <Button variant='outlined' color='error' endIcon={<DeleteForever/>} onClick={()=>{deleteInvite({id:invite?.id,callback:callback});setShown(false);}} >Delete</Button>
            </Stack>
        </Stack>
        </Box> </>: 
        <PopoverComponent open={open} onClose={()=>setOpen(false)}>
            <ReactJson src={invite} style={{paddingX:'5px'}}/>
        </PopoverComponent>
    );
}
/**
 * Renders a component for managing invitations.
 *
 * @component
 * @returns {JSX.Element} The rendered InvitationComponent.
 */
const InvitationComponent = () => {
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState(undefined);
    const [error, setError] = React.useState(null);
    const [text, setText] = React.useState(null);
    useEffect(() => {
        if(open){
            setText(null);
        setData(undefined);
        getInvtations().then((data) => {
            if(data.error){
            setError(data.error?.message || "Error in fetching the data" );
            }else{
                setData(data);
            }
            console.log(data);
        });
    }
    },[open]);


    return (
            open ? 
            <PopoverComponent open={open} onClose={()=>setOpen(false)}>
                <Stack spacing={2} pb={5}>
                <H1>Invites</H1>
                {data ? 
                <Stack spacing={1}>
                    {text && <H2 color={text?.includes('Error') ? 'error' : 'success'}>{text}</H2>}
                    {
                    
                data?.invitations.map((invite) => (
                    <InviteCard invite={invite} callback={(e)=>setText(e)}/>
                ))}
                </Stack>
                 : <Box sx={{px:50,py:25}}>{error? <H2 color='error'>{error}</H2> : <CircularProgress size={100}/> }</Box>}
                </Stack>
            </PopoverComponent>
            : <Button onClick={()=>setOpen(true)} variant='outlined' color='inherit' fullWidth sx={{maxWidth:'800px'}} endIcon={<EditIcon/>}>Manage Invitations</Button>
    );
}





export default InvitationComponent;