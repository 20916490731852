import React, { useState } from 'react';

import axios from 'axios';
import requests from '../../Api/requests';
import Cookies from 'js-cookie';

export const getOrgData = async () => {
    try {
        return await axios.get(`${requests.baseURL}/super/${Cookies.get('AUTH_TOKEN')}/clients`)
            .then(data => data.data);
    } catch (error) {
        console.log("Error:", error);
        return { error: error };
    }
}

export const superUpdateOrgData = async (data) => {
    try {
        return await axios.put(`${requests.baseURL}/super/${Cookies.get('AUTH_TOKEN')}/client/${data._id}`, data)
            .then(data => data.data);
    } catch (error) {
        console.log("Error:", error);
        return { error: error };
    }
}

export const superUpdateUser = async (data) => {
    try {
        return await axios.put(`${requests.baseURL}/super/${Cookies.get('AUTH_TOKEN')}/user/${data._id}`, data)
            .then(data => data.data);
    } catch (error) {
        console.log("Error:", error);
        return { error: error };
    }
}

export const superDeleteOrgData = async (id) => {
    try {
        return await axios.delete(`${requests.baseURL}/super/${Cookies.get('AUTH_TOKEN')}/client/${id}`)
            .then(data => data.data);
    } catch (error) {
        console.log("Error:", error);
        return { error: error };
    }
}
export const superDeleteUser = async (id) => {
    try {
        return await axios.delete(`${requests.baseURL}/super/${Cookies.get('AUTH_TOKEN')}/user/${id}`)
            .then(data => data.data);
    } catch (error) {
        console.log("Error:", error);
        return { error: error };
    }
}