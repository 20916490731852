import React from 'react';
// import Header from '../Components/Header/Header.js';
// import Nav from '../Components/Navbar/Nav.js';
// import Results from '../Components/Results/Results.js';
import Register from '../Components/Login/Register';



const LoginScreen = props => {

    function parentCallback(childData) {
        props.parentCallback(childData);
    }

    return (
    <div className="app">
    {/* <Header /> */}
    {/* <Nav /> */}
    <Register parentCallback = {parentCallback} isRegister ={true} parentError={props.parentError} loading={props.loading}/>

    </div>
    )
};
export default LoginScreen;