import React, { useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { H2 } from './Typography';





const SelectComponent = ({title,options,onChange,selectedId,value,fullWidth=true,...props}) => {
    // console.log("MenuOptions:",options);
    const findId = (value) => {
        // console.log("Value:",value,"");
        return options?.find(option => option.value === value)?.id;
    }
    // useEffect(() => {
    //     console.log("SelectComponent:Value:useEffect",value);
    //     console.log("SelectComponent:options:",options);
    // },[value]);
    return (
        <FormControl fullWidth={fullWidth} {...props}>
            <InputLabel>{title}</InputLabel>
            <Select 
            color='error'
                value={value}
                label={title}
                onChange={(e)=>onChange({target:{value:e.target.value,id:findId(e.target.value)}})}
            >
                {Array.isArray(options) && options?.map((option) => (
                    <MenuItem value={option.value}><Typography color='grey.600'>{option.label}</Typography></MenuItem>
                ))}
            </Select>
</FormControl>
    );
};

export default SelectComponent;