import React, { useContext, useEffect } from 'react';
// import './Nav.css';
import { AppContext } from './../../AppContext';
import { useLocation } from 'react-router-dom';
import MuiDrawer from '@mui/material/Drawer'
import { styled} from '@mui/material/styles';
import { Toolbar,Divider,List,ListItemButton,ListItemIcon,ListItemText, Icon, Stack} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import QuizIcon from '@mui/icons-material/Quiz';
import SchoolIcon from '@mui/icons-material/School';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import VerifiedIcon from '@mui/icons-material/Verified';
import MenuIcon from '@mui/icons-material/Menu';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import ArchitectureOutlinedIcon from '@mui/icons-material/ArchitectureOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import HistoryEduTwoToneIcon from '@mui/icons-material/HistoryEduTwoTone';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { H2 } from '../MUI/Typography';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import MobileScreenShareTwoToneIcon from '@mui/icons-material/MobileScreenShareTwoTone';
import MobileScreenShareOutlinedIcon from '@mui/icons-material/MobileScreenShareOutlined';
import SecurityUpdateWarningOutlinedIcon from '@mui/icons-material/SecurityUpdateWarningOutlined';
import TokenIcon from '@mui/icons-material/Token';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import WebhookIcon from '@mui/icons-material/Webhook';
export const IssueNavLinks = [
  { key: 'employment', name: 'Employment',icon: <HowToRegIcon fontSize='large'/> ,closeIcon:<HowToRegOutlinedIcon/>},
  { key: 'badge', name: 'Badge',icon: <VerifiedIcon fontSize='large'/>,closeIcon:<VerifiedOutlinedIcon/>},
  { key: 'assessment', name: 'Assessment' ,icon: <QuizIcon fontSize='large'/>,closeIcon:<QuizOutlinedIcon/>},
  { key: 'certification', name: 'Certifications',icon: <VerifiedUserIcon fontSize='large'/>,closeIcon:<VerifiedUserOutlinedIcon/>},
  { key: 'course', name: 'Courses',icon: <ArchitectureIcon fontSize='large'/>,closeIcon:<ArchitectureOutlinedIcon/>},
  { key: 'education-degree', name: 'Education',icon: <SchoolIcon fontSize='large'/> ,closeIcon:<SchoolOutlinedIcon/>},
];
const issueSecondaryListItems = [
  'Layer 2',
  { key: 'ncsbn-multi-license', name: 'NCSBN MultiLicense',icon: <MedicalServicesIcon fontSize='large' /> ,closeIcon:<MedicalServicesOutlinedIcon/>},
  { key: 'clr', name: 'Learner Record (CLR)',icon: <HistoryEduTwoToneIcon fontSize='large' /> ,closeIcon:<HistoryEduIcon/>},
];

const homeSecondaryListItems = [
  'More Options',
  { key: 'nonVelocity', name: 'Non-Velocity Disclosures',icon: <WebhookIcon  fontSize='large' /> ,closeIcon:<WebhookIcon/>},
  { key: 'tokens', name: 'Payment & Rewards Hub',icon: <TokenIcon  fontSize='large' /> ,closeIcon:<TokenOutlinedIcon/>},
]

const drawerWidth = 240;
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const Nav = () => {
  const { type, setType,client } = useContext(AppContext);
  const HomeNavLinks = [
    { key: 'disclosures', name: 'Create New Request',icon: <AddBoxIcon  fontSize='large' /> ,closeIcon:<AddBoxOutlinedIcon/>},
    { key: 'recievedCredentials', name: 'Completed Disclosures',icon: <MobileScreenShareTwoToneIcon fontSize='large'/>,closeIcon: client?.hasNewCredential ? <SecurityUpdateWarningOutlinedIcon color='error'/> :<MobileScreenShareOutlinedIcon/> },
    
  ];
  const theme = useTheme();
  const [open,setOpen] = React.useState((window.innerWidth > 600));
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const location = useLocation().pathname;

  const NavLinks = (location.toLowerCase() === '/issue') ? IssueNavLinks : HomeNavLinks;
  const secondaryListItems = (location.toLowerCase() === '/issue') ? issueSecondaryListItems : homeSecondaryListItems;
  useEffect(() => {
  if(!NavLinks.find( ({key}) => key === type) && !secondaryListItems.find( ({key}) => key === type)){
    setType(NavLinks[0].key);
  }

  }, [type]);
  return (<>
  
  <Drawer variant="permanent" open={open} sx={{paddingTop:1,zIndex:1}}>
      <Box sx={{backgroundColor: theme.palette.mode === 'dark' && theme.palette.grey[800]}}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
              
            }}
          >    {open&& <H2 style={{paddingRight:'40px'}}>{location.toLowerCase() === '/issue' ? 'Cred Type' : 'Disclosures'}</H2> }
            <IconButton onClick={toggleDrawer} pl={5}>
              {open ? <ChevronLeftIcon /> : <MenuIcon />}
            </IconButton>
          </Toolbar>
          <Divider sx={{ my: 1 }}  ><Typography variant='body1' sx={{transition:'all 0.2s ease-in-out'}}fontSize={open? 15:10}>{location.toLowerCase() === '/issue' ? 'Layer 1' : 'options'}</Typography></Divider>
          <List component="nav">
            {NavLinks.map(({ key, name,icon ,closeIcon}) => (
            
              <ListItemButton
              onClick={() => setType(key)}
              title={name}
              >  
              <Stack direction='row' alignItems='center' spacing={1}>
              <ListItemIcon>
                {type === key ? icon : closeIcon}
              </ListItemIcon>
              {open && <ListItemText primary={name} sx={{color:theme.palette.mode === 'dark' && theme.palette.grey[100]}}/>}
              </Stack>
            </ListItemButton>


            ))}
           <>
            {secondaryListItems.map((item,index) => ( 
              index === 0? 
              <Divider sx={{ my: 1 }}  ><Typography variant='body1' sx={{transition:'all 0.2s ease-in-out'}}fontSize={open? 15:10}>{item}</Typography></Divider> :
              <ListItemButton
              onClick={() => setType(item.key)}
              title={item.name}
              >
                <Stack direction='row' alignItems='center' spacing={1}>
              <ListItemIcon>
                {type === item.key ? item.icon : item.closeIcon}
              </ListItemIcon>
              {open && <ListItemText primary={item.name} sx={{color:theme.palette.mode === 'dark' && theme.palette.grey[100]}}/>}
              </ Stack>
            </ListItemButton>


            ))}</>
          </List>
          </Box>
        </Drawer>
  </>



    
    // <nav className="nav">
    //   {NavLinks.map(({ key, name }) => (
    //     <h2
    //       className={type === key ? 'active' : ''}
    //       onClick={() => setType(key)}
    //     >
    //       {name}
    //     </h2>
    //   ))}
    // </nav>
  );
};

export default Nav;
