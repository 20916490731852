import { Box, Button, Stack, Typography } from '@mui/material';
import React,{useEffect,useRef, useState} from 'react';
import { GoogleLogin } from 'react-google-login';
import { H2, P } from '../MUI/Typography';
import velocityLogo from '../../assets/images/velocityLogo.png';
import NotifyPopup from '../MUI/NotifyPopup';
/**
 * VelocityLoginComponent
 * 
 * @param {Object} props - The component props.
 * @param {boolean} props.isRegister - Indicates if the component is used for registration.
 * @returns {JSX.Element} The VelocityLoginComponent JSX element.
 */
const VelocityLoginComponent = ({isRegister=false}) => {
    const [error, setError] = useState(undefined);
    const signInWithVnf =()=>{
        // Handle the Velocity login response here
        setError('Velocity login is not implemented yet');
    }

    return (<>
    {error && <NotifyPopup message={error} severity='error' onClose={()=>setError(undefined)}/>}
        <Button variant="outlined" color="inherit" fullWidth  sx={{justifyContent:'flex-start',height:'45px',textTransform:'none'}} onClick={()=>signInWithVnf()}>
            <Stack direction='row' justifyContent={'space-between'} alignItems='center' spacing={2} ml={-1}>
            <img src={velocityLogo} height='20px' />
            <Box sx={{ textAlign: 'center' }}>
            <Typography variant='body2'color='grey.600'>{isRegister? 'Sign up' : 'Sign in'} with Velocity</Typography>
            </Box>
            
            </Stack>
        </Button>
    </>);
};

export default VelocityLoginComponent;