import draftToHtml from 'draftjs-to-html';
import { version } from 'react';

// ==============================|| THEME CONFIG  ||============================== //
const colors = [
    'plum',
    'coral',
    'cornflowerblue',
    'skyblue',
]
const config = {
  defaultPath: '/dashboard/default',
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'dark',
  presetColor: 'default',
  themeDirection: 'ltr',
  version: 'v0.0.9',
  avatarColor: colors[Math.floor(Math.random() * colors?.length)],
};

export default config;
export const drawerWidth = 260;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';




export const defaultEmailTemplate ={
    "blocks": [
        {
            "key": "dpgum",
            "text": "Hello {name},",
            "type": "header-three",
            "depth": 0,
            "inlineStyleRanges": [
                {
                    "offset": 6,
                    "length": 6,
                    "style": "BOLD",
                    "_id": "667973d97446e33a1c151d01"
                }
            ],
            "entityRanges": [],
            "_id": "667973d97446e33a1c151d00"
        },
        {
            "key": "1hbef",
            "text": "You have been issued a {credentialType} verifiable credential:",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "_id": "667973d97446e33a1c151d02"
        },
        {
            "key": "ac3mp",
            "text": "Employer: {companyName}",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [
                {
                    "offset": 0,
                    "length": 10,
                    "style": "BOLD",
                    "_id": "667973d97446e33a1c151d04"
                }
            ],
            "entityRanges": [],
            "_id": "667973d97446e33a1c151d03"
        },
        {
            "key": "3hbdd",
            "text": "Title: {credentialTitle}",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [
                {
                    "offset": 0,
                    "length": 6,
                    "style": "BOLD",
                    "_id": "667973d97446e33a1c151d06"
                }
            ],
            "entityRanges": [],
            "_id": "667973d97446e33a1c151d05"
        },
        {
            "key": "aliuq",
            "text": "To Claim:",
            "type": "header-three",
            "depth": 0,
            "inlineStyleRanges": [
                {
                    "offset": 0,
                    "length": 9,
                    "style": "UNDERLINE",
                    "_id": "667973d97446e33a1c151d08"
                }
            ],
            "entityRanges": [],
            "_id": "667973d97446e33a1c151d07"
        },
        {
            "key": "7i44l",
            "text": "Download the Velocity Career Wallet from the Google Play Store or Apple AppStore  and register with this email address.",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [
                {
                    "offset": 45,
                    "length": 17,
                    "key": "0",
                    "_id": "667973d97446e33a1c151d0a"
                },
                {
                    "offset": 66,
                    "length": 14,
                    "key": "1",
                    "_id": "667973d97446e33a1c151d0b"
                }
            ],
            "_id": "667973d97446e33a1c151d09"
        },
        {
            "key": "9g1bc",
            "text": "Click the Link or Scan the Code Below to claim:",
            "type": "header-four",
            "depth": 0,
            "inlineStyleRanges": [
                {
                    "offset": 0,
                    "length": 47,
                    "style": "UNDERLINE",
                    "_id": "667973d97446e33a1c151d0d"
                }
            ],
            "entityRanges": [],
            "_id": "667973d97446e33a1c151d0c"
        },
        {
            "key": "3uhal",
            "text": "{offerLink}",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "_id": "667973d97446e33a1c151d0e"
        },
        {
            "key": "2s293",
            "text": "{qrCode}",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "_id": "667973d97446e33a1c151d0f"
        },
        {
            "key": "f1cpo",
            "text": "",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "_id": "667973d97446e33a1c151d10"
        },
        {
            "key": "8tqre",
            "text": "or click \"Claim Career Credential\" in the wallet and search for {companyName} and present your email.",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [
                {
                    "offset": 10,
                    "length": 23,
                    "style": "BOLD",
                    "_id": "667973d97446e33a1c151d12"
                },
                {
                    "offset": 63,
                    "length": 15,
                    "style": "BOLD",
                    "_id": "667973d97446e33a1c151d13"
                }
            ],
            "entityRanges": [],
            "_id": "667973d97446e33a1c151d11"
        },
        {
            "key": "arre2",
            "text": "",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "_id": "667973d97446e33a1c151d14"
        },
        {
            "key": "5s8mg",
            "text": "",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "_id": "667973d97446e33a1c151d15"
        },
        {
            "key": "12na7",
            "text": "presented by https://velocitycreds.com  ",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [
                {
                    "offset": 0,
                    "length": 13,
                    "style": "fontsize-12",
                    "_id": "667973d97446e33a1c151d17"
                },
                {
                    "offset": 13,
                    "length": 25,
                    "style": "color-rgb(38,38,38)",
                    "_id": "667973d97446e33a1c151d18"
                },
                {
                    "offset": 13,
                    "length": 25,
                    "style": "bgcolor-rgb(240,248,255)",
                    "_id": "667973d97446e33a1c151d19"
                },
                {
                    "offset": 13,
                    "length": 25,
                    "style": "fontsize-14",
                    "_id": "667973d97446e33a1c151d1a"
                },
                {
                    "offset": 13,
                    "length": 25,
                    "style": "fontfamily--apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Oxygen, Ubuntu, Cantarell, \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
                    "_id": "667973d97446e33a1c151d1b"
                },
                {
                    "offset": 13,
                    "length": 25,
                    "style": "color-rgb(180,213,248)",
                    "_id": "667973d97446e33a1c151d1c"
                }
            ],
            "entityRanges": [
                {
                    "offset": 13,
                    "length": 25,
                    "key": "2",
                    "_id": "667973d97446e33a1c151d1d"
                }
            ],
            "_id": "667973d97446e33a1c151d16"
        }
    ],
    "entityMap": {
        "0": {
            "type": "LINK",
            "mutability": "MUTABLE",
            "data": {
                "url": "https://play.google.com/store/apps/details?id=io.velocitycareerlabs.holderapp&hl=en_US&pli=1",
                "targetOption": "_blank"
            }
        },
        "1": {
            "type": "LINK",
            "mutability": "MUTABLE",
            "data": {
                "url": "https://apps.apple.com/us/app/velocity-career-wallet/id1587589679",
                "targetOption": "_blank"
            }
        },
        "2": {
            "type": "LINK",
            "mutability": "MUTABLE",
            "data": {
                "url": "https://velocitycreds.com/",
                "title": "<span data-offset-key=\"1hbef-0-0\" style=\"box-sizing: inherit;\"><span data-text=\"true\" style=\"box-sizing: inherit;\">https://velocitycreds.com</span></span>",
                "targetOption": "_blank",
                "_map": {
                    "type": "LINK",
                    "mutability": "MUTABLE",
                    "data": {
                        "url": "https://velocitycreds.com/",
                        "title": "<span data-offset-key=\"1hbef-0-0\" style=\"box-sizing: inherit;\"><span data-text=\"true\" style=\"box-sizing: inherit;\">https://velocitycreds.com</span></span>",
                        "targetOption": "_blank"
                    }
                }
            }
        }
    }
}

export const defaultEmailHtml = draftToHtml(defaultEmailTemplate);

export const defaultDisclosureTemplate = {
    "blocks": [
        {
            "key": "dpgum",
            "text": "Hello {name},",
            "type": "header-three",
            "depth": 0,
            "inlineStyleRanges": [
                {
                    "offset": 6,
                    "length": 6,
                    "style": "BOLD"
                }
            ],
            "entityRanges": [],
            "data": {}
        },
        {
            "key": "enoc8",
            "text": "You have recieved a disclosure request from {companyName}.",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [
                {
                    "offset": 44,
                    "length": 13,
                    "style": "BOLD"
                }
            ],
            "entityRanges": [],
            "data": {}
        },
        {
            "key": "650vk",
            "text": " To disclose your verifiable credentials, please scan the QR code or click the link below to submit your credentials for inspection.",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
        },
        {
            "key": "9h458",
            "text": "To Submit For Inspection:",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [
                {
                    "offset": 0,
                    "length": 25,
                    "style": "UNDERLINE"
                }
            ],
            "entityRanges": [],
            "data": {}
        },
        {
            "key": "crin7",
            "text": "Scan this code or click the link below:",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
        },
        {
            "key": "58bmt",
            "text": "{qrCode}",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
        },
        {
            "key": "t7q9",
            "text": "{offerLink}",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
        },
        {
            "key": "6s9e2",
            "text": "",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
        },
        {
            "key": "eqjn3",
            "text": "",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
        },
        {
            "key": "12na7",
            "text": "presented by https://velocitycreds.com  ",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [
                {
                    "offset": 0,
                    "length": 13,
                    "style": "fontsize-12"
                },
                {
                    "offset": 13,
                    "length": 25,
                    "style": "color-rgb(38,38,38)"
                },
                {
                    "offset": 13,
                    "length": 25,
                    "style": "bgcolor-rgb(240,248,255)"
                },
                {
                    "offset": 13,
                    "length": 25,
                    "style": "fontsize-14"
                },
                {
                    "offset": 13,
                    "length": 25,
                    "style": "fontfamily--apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, Oxygen, Ubuntu, Cantarell, \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif"
                },
                {
                    "offset": 13,
                    "length": 25,
                    "style": "color-rgb(180,213,248)"
                }
            ],
            "entityRanges": [
                {
                    "offset": 13,
                    "length": 25,
                    "key": 0
                }
            ],
            "data": {}
        }
    ],
    "entityMap": {
        "0": {
            "type": "LINK",
            "mutability": "MUTABLE",
            "data": {
                "url": "https://velocitycreds.com/",
                "title": "<span data-offset-key=\"1hbef-0-0\" style=\"box-sizing: inherit;\"><span data-text=\"true\" style=\"box-sizing: inherit;\">https://velocitycreds.com</span></span>",
                "targetOption": "_blank",
                "_map": {
                    "type": "LINK",
                    "mutability": "MUTABLE",
                    "data": {
                        "url": "https://velocitycreds.com/",
                        "title": "<span data-offset-key=\"1hbef-0-0\" style=\"box-sizing: inherit;\"><span data-text=\"true\" style=\"box-sizing: inherit;\">https://velocitycreds.com</span></span>",
                        "targetOption": "_blank"
                    }
                }
            }
        }
    }
}

export const defaultDisclosureHtml = draftToHtml(defaultDisclosureTemplate);