// material-ui
import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';

// assets
import requests from '../../../../Api/requests';
import AutoComplete from '../../../MUI/AutoComplete';
import { useEffect } from 'react';
import get from '../../../../Hooks/getRequest';
import {useCookies} from 'react-cookie';
import PopoverComponent from '../../../MUI/PopoverComponent';
import OfferCard from '../../../MUI/OfferCard';
import { Stack } from '@mui/material';
import { H1, H2 } from '../../../MUI/Typography';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //
const getOptionData = (data) => {
  const checkedEmails = []; 
  const dataArr =  data.map((option) => {
    return {
      label: option?.email,
      value: option?.id
    }
  });
  return dataArr?.filter((option) => {
    if(checkedEmails.includes(option.label)){
      return false;
    }
    checkedEmails.push(option.label);
    return true;
  });
}
export default function Search() {
  const [options, setOptions] = React.useState([{label:'Create Offers to view them here',value:''}]);
  const [offers, setOffers] = React.useState([]);
  const [email, setEmail] = React.useState(undefined);
  const [cookies, setCookie] = useCookies(['AUTH_TOKEN']);
  const [popupData, setPopupData] = React.useState(undefined);
  useEffect(() => {
    if(options?.length === 1 && offers?.length === 0){
      get({url:`${requests.baseURL}/api/${cookies.AUTH_TOKEN}/offers`}).then((data) => {
        // console.log("Offers:",data);
        if(data?.error || !data || data?.length === 0 || data?.message){
          return;
        }
        setOffers(data);
        setOptions(getOptionData(data));
    });
    }
  }, [options]);


  useEffect(() => {
    if(email){
      // console.log('offers:',offers)
      var presentedOffers = [];
      offers?.forEach((offer) => {
        if(offer?.email === email){
          presentedOffers.push(offer);
        }
      });
      console.log("PresentedOffers:",presentedOffers[0]);
      setPopupData(presentedOffers);
    }
  },[email]);

  return (
    <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
        <AutoComplete options ={options} onChange={(e)=>setEmail(e)}/>
        {popupData && <PopoverComponent open={popupData} onClose={()=>setPopupData(undefined)}> 
          <H1>{email}:</H1>
          <Stack spacing={3} py={2}>          
            {popupData?.map((data) =>
          <OfferCard offerData={data} sx={{border:'1px solid'}}/>
          )}
          </Stack>

          </PopoverComponent>} 
    </Box>
  );
}
