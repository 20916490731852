import React from 'react';
// import Header from '../Components/Header/Header.js';
// import Nav from '../Components/Navbar/Nav.js';
// import Results from '../Components/Results/Results.js';
import OrgForm from '../Components/LoginForm/OrgForm.jsx';



const LoginOrgScreen = props => {

    function parentCallback(childData) {
        props.parentCallback(childData);
    }
    return (
    <div className="app">
    {/* <Header /> */}
    {/* <Nav /> */}
    <OrgForm parentCallback = {parentCallback} isRegister ={false}/>
    </div>
    )
};
export default LoginOrgScreen;