
import requests from '../Api/requests.js';
import Cookies from 'js-cookie';

async function updateUser(credentials) {
    if(credentials.username === undefined){
      console.log(JSON.stringify(credentials));
    }
    const extension = (credentials._id === undefined ? "" : credentials._id);
  
      try{
      return fetch(`${requests.baseURL}/users/${Cookies.get('AUTH_TOKEN')}/${extension}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      })
        .then(data => data.json(),console.log("Error"));
  }catch(err){
      console.log("error");
  }
}

export default updateUser;