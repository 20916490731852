
import React, { useEffect } from 'react';
import { Box, Stack, TextField, Button } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';
import { H2, P } from '../MUI/Typography';
import PopoverComponent from '../MUI/PopoverComponent';
import { createInvitation } from '../../Hooks/invitations';



const ProfileComponent = ({name,formData,setFormData}) => {

    const changeProfile =(title,value)=>{
        const prevProfile = formData?.keyIndividuals;
        if(title?.includes('Name')){
            prevProfile[title.replace('Name','GivenName')] = value;
      
        }else{
        prevProfile[title] = value;
        }
    setFormData((prevData) => ({...prevData, keyIndividuals: prevProfile}));
    }
    return (<>
        <TextField
            value={formData?.keyIndividuals?.[name+"GivenName"]  || ''}
            margin="normal"
            required={name==='admin'}
            fullWidth
            variant="outlined"
            id={name+"Name"}
            label={name+" Name (First and Last)"}
            name={name+"Name"}
            autoComplete={name+"Name"}
            onChange={(e)=>changeProfile(name+"Name",e.target.value)}
        />
        <TextField
            value={formData?.keyIndividuals?.[name+"Title"] || ''}
            margin="normal"
            required={name==='admin'}
            fullWidth
            variant="outlined"
            id={name+"Title"}
            label={name+" Title"}
            name={name+"Title"}
            autoComplete={name+"Title"}
            onChange={(e)=>changeProfile(name+"Title",e.target.value)}
        />
        <TextField
            value={formData?.keyIndividuals?.[name+"Email"] || ''}
            margin="normal"
            required={name==='admin'}   
            fullWidth
            variant="outlined"
            id={name+"Email"}
            label={name+" Email"}
            name={name+"Email"}
            autoComplete={name+"Email"}
            onChange={(e)=>changeProfile(name+"Email",e.target.value)}
        />
    </>);

};
/**
 * Represents a Registrar Component.
 * @component
 * @param {Object} orgData - The organization data.
 * @returns {JSX.Element} The Registrar Component.
 */
const RegistrarComponent = ({orgData}) => {
    const [open, setOpen] = React.useState(false);
    const [text, setText] = React.useState(null);
    const [formData, setFormData] = React.useState({
        inviteeEmail : orgData?.admin[0]?.email,
        inviteeProfile:{
            name:orgData?.name,
            description:orgData?.description,
            location:{
                countryCode:orgData?.place?.addressCountry || 'US',
                regionCode:orgData?.location?.addressRegion || 'US-GA',
            }, 
            physicalAddress:{
                countryCode:orgData?.place?.addressCountry || 'US',
                regionCode:orgData?.location?.addressRegion || 'US-GA',
            },
            logo:'',
            website:orgData?.websiteURL,
            contactEmail:orgData?.admin[0]?.email,
            technicalEmail:orgData?.supportEmail || orgData?.admin[0]?.email,
            type:'company',
        },
       inviteeService:[
        {
            id:'#vcl-issuer-1',
            serviceEndpoint:'https://agent.velocitycreds.com',
            type:'VlcCareerIssuer_v1'
       },
        {
            id:'#vcl-inspector-1',
            serviceEndpoint:'https://agent.velocitycreds.com',
            type:'VlcInspector_v1'
       },
    ],
        keyIndividuals:{
            adminGivenName:orgData?.admin[0]?.username,
            adminTitle:'Admin',
            adminEmail:orgData?.admin[0]?.email,
            signatoryGivenName:orgData?.users?.[0]?.user?.username,
            signatoryTitle:orgData?.users?.[0]?.user?.username && 'Signatory',
            signatoryEmail:orgData?.users?.[0]?.user?.email,
        }
    });
    useEffect(() => {
        setText(null);
    },[open]);
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        formData.keyIndividuals.adminFamilyName = formData.keyIndividuals.adminGivenName.split(' ')[1] || " ";
        formData.keyIndividuals.adminGivenName = formData.keyIndividuals.adminGivenName.split(' ')[0];
        formData.keyIndividuals.signatoryFamilyName = formData.keyIndividuals.signatoryGivenName?.split(' ')[1];
        formData.keyIndividuals.signatoryGivenName = formData.keyIndividuals.signatoryGivenName?.split(' ')[0];

        createInvitation(formData).then((data) => {
            console.log(data);
            if(data.error){
                setText ('Error in sending the request: '+data.error?.message);
                console.log(data.error);
            }else{
                setText('Request Sent Successfully');
                // setOpen(false);
            }
        })
    };
    return (
        Boolean(open) ? 
            <PopoverComponent open={open} onClose={()=>setOpen(false)}>
                <H2>Registrar Registration Request</H2>
                <Box component='form' alignItems='center' pb={2} onSubmit={handleSubmit}>
                    <Stack spacing={2} py={2} width='500px'>
                    <TextField
                        value={formData?.inviteeEmail || ''}
                        margin="normal"
                        required
                        fullWidth
                        variant="outlined"
                        id="inviteeEmail"
                        label="Invitee Email"
                        name="inviteeEmail"
                        autoComplete="inviteeEmail"
                        autoFocus
                        onChange={(e)=>setFormData((prevData) => ({...prevData, inviteeEmail: e.target.value}))}
                    />
                    <TextField
                        value={formData?.inviteeProfile?.name || ''}
                        margin="normal"
                        required
                        fullWidth
                        variant="outlined"
                        id="name"
                        label="Organization Name"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        onChange={(e)=>setFormData((prevData) => ({...prevData, inviteeProfile: {...prevData.inviteeProfile, name: e.target.value}}))}
                    />
                    <TextField
                        value={formData?.inviteeProfile?.description || ''}
                        margin="normal"
                        required
                        fullWidth
                        variant="outlined"
                        id="description"
                        label="Organization Description"
                        name="description"
                        autoComplete="description"
                        onChange={(e)=>setFormData((prevData) => ({...prevData, inviteeProfile: {...prevData.inviteeProfile, description: e.target.value}}))}
                    />
                    <TextField
                        value={formData?.inviteeProfile?.logo || ''}
                        margin="normal"
                        required
                        fullWidth
                        variant="outlined"
                        id="logo"
                        label="Organization logo URL"
                        name="logo"
                        autoComplete="logo"
                        onChange={(e)=>setFormData((prevData) => ({...prevData, inviteeProfile: {...prevData.inviteeProfile, logo: e.target.value}}))}
                    />
                    <H2>Organization Logo:</H2>
                    <img src={formData?.inviteeProfile?.logo} alt='Invalid URL'/>
                    <P>Must be Between 300x300px and 400x400px:</P>
                    <TextField
                        value={formData?.inviteeProfile?.website || ''}
                        margin="normal"
                        required
                        fullWidth
                        variant="outlined"
                        id="website"
                        label="Organization website URL"
                        name="website"
                        onChange={(e)=>setFormData((prevData) => ({...prevData, inviteeProfile: {...prevData.inviteeProfile, website: e.target.value}}))}
                    />
                    <TextField
                        value={formData?.inviteeProfile?.linkedInProfile || ''}
                        margin="normal"
                        // required
                        fullWidth
                        variant="outlined"
                        id="linkedInProfile"
                        label="Organization linkedInProfile URL"
                        name="linkedInProfile"
                        onChange={(e)=>setFormData((prevData) => ({...prevData, inviteeProfile: {...prevData.inviteeProfile, linkedInProfile: e.target.value}}))}
                    /> 
                    <TextField
                        value={formData?.inviteeProfile?.contactEmail || ''}
                        margin="normal"
                        required
                        fullWidth
                        variant="outlined"
                        id="contactEmail"
                        label="Organization contactEmail"
                        name="contactEmail"
                        onChange={(e)=>setFormData((prevData) => ({...prevData, inviteeProfile: {...prevData.inviteeProfile, contactEmail: e.target.value}}))}
                    />
                    <H2>Key Individuals</H2>
                    <ProfileComponent name='admin' formData={formData} setFormData={setFormData}/>
                    <ProfileComponent name='signatory' formData={formData} setFormData={setFormData}/>
                    {text && <H2 color={text?.includes('Error') ? 'error' : 'success'}>{text}</H2>}
                    <Button fullWidth variant='outlined' color='warning' type='submit' endIcon={<GavelIcon/>}>Submit request</Button>
                    </Stack>
                </Box>
            </PopoverComponent>
            
            
            :<Button
        fullWidth
        variant='outlined'
        color='warning'
        endIcon={<GavelIcon/>}
        onClick={()=>setOpen(true)}
        >Submit Registrar registration request</Button>
    );
};

export default RegistrarComponent;