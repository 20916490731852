import React from 'react';
import Header from '../Components/Header/Header.js';
import Nav from '../Components/Navbar/Nav.js';
import Results from '../Components/Results/Results.js';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import MantisDashboard from '../Components/MantisDashboard/index.jsx';
import MantisHeader from '../Components/MantisHeader/index.jsx';
import { useGetMenuMaster } from '../Api/menu.js';
import { drawerWidth } from '../config.js';
import { Box } from '@mui/material';
import MailTemplateComponent from '../Components/templates/MailTemplateComponent.jsx';
const TemplateScreen = () => {
  const { menuMaster } = useGetMenuMaster();
  const drawerOpen = menuMaster?.isDashboardDrawerOpened;
    return (
    <MantisHeader>
      <MailTemplateComponent />
    </MantisHeader>
    );
};
export default TemplateScreen;