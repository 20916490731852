import React,{useState} from 'react';
import './App.css';
import HomeScreen from './screens/HomeScreen';
import LoginScreen from './screens/LoginScreen';
import IssueScreen from './screens/IssueScreen';
import AccountScreen from './screens/AccountScreen';
import RegisterScreen from './screens/RegisterScreen';
import RegisterOrgScreen from './screens/RegisterOrgScreen';
import LoginOrgScreen from './screens/LoginOrgScreen';
import { Route ,Routes,Navigate, useNavigate, useLocation} from 'react-router-dom';
import{ validateToken,validateClient }from './Hooks/login';
import { useCookies } from 'react-cookie';
import { useContext ,useEffect} from 'react';
import { AppContext } from './AppContext';
//google foint inpoirts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import TemplateScreen from './screens/TemplateScreen';
import AdminScreen from './screens/AdminScreen';
import { NotFoundComponent } from './Components/CredentialEngineSearch/CredentialEngineSearch';
import { Stack } from '@mui/material';
import banner from './assets/images/banner.png';
import {gapi} from 'gapi-script';
import PageScreen from './screens/PageScreen';
import Header from './Components/Header/Header';
import LandingScreen from './screens/LandingScreen';
import InspectScreen from './screens/InspectScreen';
import ShareScreen from './screens/Sharing/ShareScreen';
import ClaimWebCredentialScreen from './screens/Sharing/ClaimWebCredentialScreen';
import PresentationScreen from './screens/Sharing/PresentationScreen';

function App() {
  const {user,setUser} = useContext(AppContext);
  const{client,setClient,role,setRole,setNotifications,notifications} = useContext(AppContext);
  const [loggedIn, setloggedIn] = useState(false);
  const [checkedToken, setCheckedToken] = useState(false);
  const [cookies, setCookie] = useCookies(['AUTH_TOKEN']);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const navigateTo = useNavigate();
  const location = useLocation();
  const path = location.pathname


  const loginWithCookies = (cookies,setUser,setClient,role,setRole,setloggedIn) => {
  
    if(cookies.AUTH_TOKEN !== undefined){
      //cheks if the token is valid
    validateToken(cookies.AUTH_TOKEN).then(
      data => {
        if(!data){setLoading(false); return;}
        setloggedIn(true)
        setUser(data);
        //if the token is valid, it checks if the user has a client
        if(data.client !== undefined ){
          validateClient(data.client,cookies.AUTH_TOKEN).then(clientData =>{
            console.log("client:",clientData);
            if(clientData !== undefined){
              if(clientData.pending){
                setRole('pending');
              }else{
            if(data.client === clientData._id){
              // console.log("Client Data:",data);
              setClient(clientData);
              
              for(let i = 0; i < clientData.admin.length; i++){
                if(clientData.admin[i].email === data.email){
                  setRole('admin');
                }
              }
               if(role === undefined){
                for(let i = 0; i < clientData.users.length; i++){
                  if(clientData.users[i].user.email === data.email){
                    setRole(clientData.users[i].role);
                  }
                }
               }
               updateNotifications(clientData,data);
            }
          }
          }
          }
          
          );
        }else{
          
          updateNotifications(undefined);
        }
        setLoading(false);
      }
    
    ).catch((err)=>{
      console.log("Error With COokie:",err);
      setloggedIn(false);
      setError(err?.message==='Failed to fetch' ? 'Server is currently unreachable. Try Again Later' : err?.message);
    });
  }else{
    setLoading(false);
  }
  
  }
  //tries to authenticate the user using cookies
  useEffect(() =>{
    loginWithCookies(cookies,setUser,setClient,role,setRole,setloggedIn);

    function start (){
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENTID,
        scope:""
      })
    }
    gapi.load('client:auth2',start);

  },[]);
  function callbackFunction(childData) {
    setloggedIn(childData);
  }
  const updateNotifications = (client,user) => {
    console.log("User",user);  
    var notif = [];
    if(!client && role !== 'pending'){
      setNotifications(notifications=>[
        {
          message: 'You are not Registered to an Org',
          secondary: 'Click here to Register',
          to: '/Account',
          time: new Date().toLocaleTimeString().replace(/:\d+ /, ' '),
          type: 'setting'
        }
      ]);
    }else if(role !== 'basic'){
      const newNotifications = client.requests.map((request) => (
        {
          message: 'Join request from ' + request.email,
          secondary: 'Click here to view',
          to: '/Account',
          time: new Date().toLocaleTimeString().replace(/:\d+ /, ' '),
          type: 'message'
        }));
        if(user?.invitationUrl){
          newNotifications.push({
            message: 'You have been invited to Velocity',
            secondary: 'Click here to accept',
            to: user?.invitationUrl,
            time: new Date().toLocaleTimeString().replace(/:\d+ /, ' '),
            type: 'message'
          });
        }
      notif = [...newNotifications,...notif];
    }
    if(client?.hasNewCredential){
      notif.push({
        message: 'You have recieved a new Credential Disclosure',
        secondary: 'Click here to view',
        to: '/inspect',
        time: new Date().toLocaleTimeString().replace(/:\d+ /, ' '),
        type: 'message'
      });
    }
    setNotifications(notifications=>[...notif,...notifications]);
  }
 
    console.log("Path:",path);
  const queryParams = new URLSearchParams(location.search);
  const redirect = queryParams.get('redirect');
  useEffect(() => {
    if(loggedIn){
      if (redirect) {
        console.log("Redirecting to:",redirect);
        navigateTo(redirect);
      }
    }
  },[loggedIn]);
 
  return (
    
      <Routes>
        {/* Not Logged In */}
      <Route path="Login" element = {loggedIn ? <Navigate to="/" /> : <LoginScreen parentCallback = {callbackFunction} loading={loading} parentError={error}/>} />
      <Route path="Register" element = {loggedIn ? <Navigate to={redirect||"/"} /> : <RegisterScreen parentCallback = {callbackFunction} loading={loading} parentError={error}/>} />
      <Route path="landing" element = {<LandingScreen loading={loading} parentError={error}/>} />
      {/* Once Logged In */}
      <Route path="RegisterOrg" element = {loggedIn ? <RegisterOrgScreen parentCallback = {callbackFunction}/> : <Navigate to="/Register?redirect=/registerOrg" /> } />
      <Route path="LoginOrg" element = {loggedIn ? <LoginOrgScreen parentCallback = {callbackFunction}/> : <Navigate to="/Register?redirect=/loginOrg" /> } />
      <Route path="/" element = {loggedIn ? <HomeScreen/> : <Navigate to="/landing?redirect=/" />}/>
      <Route path="Issue" element = {loggedIn ? <IssueScreen/> : <Navigate to="/Register?redirect=/issue" />}/>
      <Route path="Account" element = {loggedIn ? <AccountScreen/> : <Navigate to="/Register?redirect=/account" />}/>
      <Route path="templates" element = {loggedIn ? <TemplateScreen/> : <Navigate to="/Register?redirect=/templates" />}/>
      <Route path="inspect" element = {loggedIn ? <InspectScreen/> : <Navigate to="/Register?redirect=/inspect" />}/>
      <Route path="admin" element = {loggedIn ? <AdminScreen/> : <Navigate to="/Register" />}/>


    {/* Sharing Routes */}
      <Route path="info" element = {<PageScreen />} />
      <Route path="share" element = {<ShareScreen />} />
      <Route path="claim" element = {<ClaimWebCredentialScreen />} />
      <Route path="present" element = {<PresentationScreen />} />
      {/* Page Not Found */}
      <Route path='*' element={<><Header/><Stack pt={15}><NotFoundComponent title={'Whoops.... 404 Page Not Found'}/></Stack></>} />
      </Routes>

  );
}
 
export default App;
