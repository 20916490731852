import axios from 'axios';
import requests from '../../Api/requests';
import Cookies from 'js-cookie';


export const getDefaultMailTemplate = async () => {
    try {
        return await axios.get(`${requests.baseURL}/mailing/${Cookies.get('AUTH_TOKEN')}/template/`)
            .then(data => data.data);
    } catch (error) {
        console.log("Error:", error);
        return { error: error };
    }
}

export const getMailTemplates = async () => {
    try {
        return await axios.get(`${requests.baseURL}/mailing/${Cookies.get('AUTH_TOKEN')}/templates`)
            .then(data => data.data);
    } catch (error) {
        console.log("Error:", error);
        return { error: error };
    }
}
export const createMailTemplate = async (data) => {
    try {
        return await axios.post(`${requests.baseURL}/mailing/${Cookies.get('AUTH_TOKEN')}/template`, data)
            .then(data => data.data);
    } catch (error) {
        console.log("Error:", error);
        return { error: error };
    }
}
export const updateMailTemplate = async (data) => {
    try {
        const {_id,...rest} =data
        return await axios.put(`${requests.baseURL}/mailing/${Cookies.get('AUTH_TOKEN')}/template/${_id}`, rest)
            .then(data => data.data);
    } catch (error) {
        console.log("Error:", error);
        return { error: error };
    }
}
export const deleteMailTemplate = async (id) => {
    try {
        return await axios.delete(`${requests.baseURL}/mailing/${Cookies.get('AUTH_TOKEN')}/template/${id}`)
            .then(data => data.data);
    } catch (error) {
        console.log("Error:", error);
        return { error: error };
    }
}
export const deleteMailTemplates = async () => {
    try {
        return await axios.delete(`${requests.baseURL}/mailing/${Cookies.get('AUTH_TOKEN')}/templates`)
            .then(data => data.data);
    } catch (error) {
        console.log("Error:", error);
        return { error: error };
    }
}