import React, { useState,useContext } from 'react';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import sendOffer,{ proccessAdvancedEmploymentOfferFormData, proccessEmploymentOfferFormData } from '../../Hooks/sendOffer';
// import PickerWheel from '@codeurs/react-simple-wheel-picker';
// import './CredentialSelection.css';
import { FormHelperText } from '@mui/material';
import { Box, TextField } from '@mui/material';
import {H3,H2,P2} from '../MUI/Typography';
import { DesktopDatePicker as DatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider} from "@mui/x-date-pickers";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import MultiSelect from '../MUI/MultiSelect'; 
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import NotifyPopup from '../MUI/NotifyPopup';

import Form from '@rjsf/mui';
// import Form from '@rjsf/material-ui';
import validator from '@rjsf/validator-ajv8';
import education from '../../schemas/simple/education.json';
const EducationOfferForm = ({alignments}) => {
    // console.log("OfferForm",window.innerWidth);
    const theme = useTheme();
    const expireOptions = Array.from({length: 10}, (_, i) => i + 1).map((data)=>{return{id:data, value:data}});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    // console.log(expireOptions);
    const defaultData = {
        type: 'EducationDegreeGraduationV1.1',
    }
    const [formData, setFormData] = useState(defaultData);

    const handleSubmit = ({formData}) => {
        setError(null);
        setSuccess(null);
        // e.preventDefault();
        console.log(formData);
        const sendData = proccessAdvancedEmploymentOfferFormData(formData);
        if(sendData.error){
            setError(sendData.error || "Error in processing the Offer");
            return;
        }
        // console.log(sendData);
        if(alignments?.length >0){
            sendData.alignment = alignments;
        }
        const res = sendOffer(sendData);
        
        res.catch((err) => {
            setError(err || "Error in sending the offer");
            console.log(res);
        });
        res.then((data) => {
            console.log(res);
            if(data.error){
                setError(data.error || "Error in sending the offer");
            }else{
                setFormData(defaultData);
                setSuccess(true);
            }
        })
    };

    return (<>
    <Box>
        {error && <NotifyPopup severity="error" message={error} onClose={()=>setError(false)} />}
        {success && <NotifyPopup severity="success" message="Offer Sent" onClose={()=>setSuccess(false)} />}
    <Form
    className = {"cred__form "}
      schema={education}
      uiSchema={{}}
      formData={formData}
      onSubmit={handleSubmit}
      validator={validator}
      // focusOnFirstError
      noHtml5Validate
    />
    </Box>
       
        
        </>);
}; 

export default EducationOfferForm;