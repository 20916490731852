import React, { useState,useContext } from 'react';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import sendOffer,{ proccessBadgeOfferFormData } from '../../Hooks/sendOffer';
// import PickerWheel from '@codeurs/react-simple-wheel-picker';
// import './CredentialSelection.css';
import { Box, TextField } from '@mui/material';
import {H3,H2,P2,P} from '../MUI/Typography';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material';
import NotifyPopup from '../MUI/NotifyPopup';
/**
 * Component for rendering a form to create a badge offer.
 * @param {Object} props - The component props.
 * @param {Array} props.alignments - The alignments for the badge offer.
 * @returns {JSX.Element} The rendered component.
 */
const BadgeOfferForm = ({alignments}) => {
    // console.log("OfferForm",window.innerWidth);
    const theme = useTheme();
    const expireOptions = Array.from({length: 10}, (_, i) => i + 1).map((data)=>{return{id:data, value:data}});
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    // console.log(expireOptions);
    const defaultData = {
        type: 'OpenBadgeV2.0',
        selectComponent: 2,
        defaultImage: true,
    }
    const [formData, setFormData] = useState(defaultData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        // console.log(formData);
    };

    const handleSubmit = (e) => {
        setError(null);
        setSuccess(null);
        e.preventDefault();
        // console.log(formData);
        const sendData = proccessBadgeOfferFormData(formData);
        if(!sendData){
            setError("Error in processing the Offer");
            return;
        }
        if(alignments?.length >0){
            sendData.alignment = alignments;
        }
        const res = sendOffer(sendData);
        console.log(res);
        res.catch((err) => {
            setError(err?.message || "Error in sending the offer");
        });
        res.then((data) => {
            if(data.error){
                setError(data.error || "Error in sending the offer");
            }else{
                setFormData(defaultData);
                setSuccess(true);
            }
        })
    };

    return (<>

              {/* <FormHelperText sx={{color: 'red'}}>{""}</FormHelperText> */}
              <Box component={Paper} px={3} sx={{backgroundColor:theme.palette.mode === 'dark' && theme.palette.grey[800]}} width={'100%'}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1,height:'68vh' }} title ='Badge' width={'100%'} >
        <H2 style={{alignSelf : 'center'}}>{"Open Badge Issuing"}</H2>
                <TextField
                value={formData.name || ''}
                margin="normal"
                required
                fullWidth
                variant="outlined"
                id="name"
                label="Achievement Name"
                name="name"
                autoComplete="name"
                autoFocus
                onChange={handleChange}
              />
                <TextField
                value={formData.email || ''}
                margin="normal"
                required
                fullWidth
                variant="outlined"
                id="email"
                label="Recipient Email"
                name="email"
                autoComplete="email"
                type='email'
                autoFocus
                onChange={handleChange}
              />
                <TextField
                value={formData.description || ''}
                margin="normal"
                required
                fullWidth
                variant="outlined"
                id="description"
                label="Badge Description"
                name="description"
                autoComplete="description"
                autoFocus
                onChange={handleChange}
              />
                <TextField
                value={formData.narrative || ''}
                margin="normal"
                required
                fullWidth
                variant="outlined"
                id="narrative"
                label="Criteria URL"
                name="narrative"
                autoComplete="narrative"
                type='url'
                autoFocus
                onChange={handleChange}
              />
                
              
                <FormControlLabel
                labelPlacement='start'
                control={<Checkbox defaultChecked 
                checked={formData.defaultImage}
                onChange={(e)=>setFormData((prevData) => ({ ...prevData,defaultImage: e.target.checked }))}
                />} label={<P>Use Default Image</P>} />
                  {!formData.defaultImage && <TextField
                value={formData.imageUrl || ''}
                margin="normal"
                required
                fullWidth
                variant="outlined"
                id="imageUrl"
                label="ImageUrl"
                name="imageUrl"
                autoComplete="imageUrl"
                autoFocus
                onChange={handleChange}
                disabled={formData.defaultImage}
                type='url'
              />}
                <div>
                <Stack direction='row' spacing={2} py={3}>
                    <H2>Expires in:</H2>
                    <select
                        id="selectComponent"
                        name="selectComponent"
                        className = 'expireDropDown'
                        value={formData.selectComponent || ''}
                        onChange={handleChange}
                    >
                        {expireOptions.map((data) =>{ return(<option key={data.id} value={data.id}>{data.value} Years</option>)})}
                    </select>
                </Stack>
                
            </div>
            {error && <NotifyPopup message={error} severity='error' onClose={()=>setError(undefined)}/>}
            {success && <NotifyPopup message={'Offer Created Successfully'} severity='success' onClose={()=>setSuccess(undefined)}/>}
            <Button type="submit" sx={{alignSelf:'center',minWidth:'10rem'}} variant='contained' endIcon={<SendIcon/>}>Submit</Button>
        </Box>
        </Box>
        </>);
};

export default BadgeOfferForm;