import axios from 'axios';
import requests from '../../Api/requests.js';
import Cookies from 'js-cookie';

export async function getAllDisclosures() {
    try {
        const response = await axios.get(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/disclosures`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (err) {
        return err;
    }
}
export async function deleteDisclosure(id) {
    try{
        return fetch(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/disclosures/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(data => data.json()).catch(err => console.log("Error getting:",err));
    }catch(err){
        console.log("error");
    }
}
export async function createDisclosure(data) {
    try{
        return fetch(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/disclosures`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(data => data.json()).catch(err => console.log("Error getting:",err));
    }catch(err){
        console.log("error");
    }
}