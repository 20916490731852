import React from 'react';
import Header from '../Components/Header/Header.js';
import Nav from '../Components/Navbar/Nav.js';
import Results from '../Components/Results/Results.js';
import AccountView from '../Components/AccountView/AccountView.js';
import MantisHeader from '../Components/MantisHeader/index.jsx';


const HomeScreen = () => {
    return (
    <MantisHeader>
    <AccountView />
    </MantisHeader>

    );
};
export default HomeScreen;