import React, { useContext,useEffect,useState } from 'react';
import AccountCard from '../Card/AccountCard.js';
import './Results.css';
import { AppContext } from './../../AppContext';
import requests from '../../Api/requests.js';
import get from '../../Hooks/getRequest.js';
import Table from '../Table/Table.js';
import {getRows} from '../Table/tableData.js';
import deleteOffer from '../../Hooks/deleteOffer';
import { isObject } from '@rjsf/utils';
import Box from '@mui/material/Box';
import OfferView from '../Table/OfferView.js';
import Button from '@mui/material/Button';


const getOfferData = (data) => {
  const issuedData = [{date: 0, count: 0, data: []},{date: 1, count: 0, data: []},{date: 2, count: 0, data: []},{date: 3, count: 0, data: []},{date: 4, count: 0, data: []},{date: 5, count: 0, data: []},{date: 6, count: 0, data: []},{date: 7, count: 0, data: []},{date: 8, count: 0, data: []},{date: 9, count: 0, data: []},{date: 10, count: 0, data: []},{date: 11, count: 0, data: []}];
  // console.log("IssuedTable:getIssuedData",data);
  try{
  for(const d of data){
      const date = new Date(d?.offerCreationDate).getMonth();
      const index = issuedData.findIndex((data) => data.date === date);
      if(index >=0){
          issuedData[index].count += 1;
      }else{  
          issuedData.push({date: date, count: 1});
      }
      issuedData[index].data.push({date: new Date(d?.offerCreationDate).getDate()});
  }
  return {offerData:issuedData};
}catch(err){
  return [];
}
}

const Results = ({type='Users',setOfferData}) => { 
  const {role} = useContext(AppContext);
  const [results, setResults] = useState((<p>Loading...</p>));
  const [isLoading, setIsLoading] = useState(true);
  const [prevType, setPrevType] = useState(type);
  const [view, setView] = useState(undefined);
  const [offers, setOffers] = useState([]);
  const [canRemove, setCanRemove] = useState(undefined);
  const [resend, setResend] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);


  useEffect(() => {
    setOfferData(getOfferData(offers));
  }, [offers]);


  const isEmailLoading = () => emailLoading;
  useEffect(() => {
    console.log("Email Loading:",emailLoading);
  }, [emailLoading]);

  if (canRemove === undefined && role !== undefined){
    setIsLoading(true);
    if(role === "admin"){
      setCanRemove(true);
    }else{
      setCanRemove(false);
    }
  }
// useEffect((results) => {
//   console.log("Results:",view);
// }, [view]);
  if(type!==prevType){
    setIsLoading(true);
    setPrevType(type);
  }
  const removeOffer = async (data) => {
    // console.log("Data fgrom removeOffer:",data.target.id);
    try{
    deleteOffer(data.currentTarget.id).then((data) => {
      if(data.error){
        console.log("Error in deleting offer");
      }else{
        console.log("Offer Deleted");
        setIsLoading(true);
      }
    
    })
  }catch(err){
    console.log("Error in deleting offer");
  }
  }
  const tableSetView = (data) => {
    console.log("TableSetView:",data);
    setView(data);
    setIsLoading(true);
  }
  useEffect(() => {
    setIsLoading(true);
  }, [view]);
  const getResults = async e => {
    // e.preventDefault();

    try{
    const data = await get({
      url: requests[type] || requests["Offers"]
    });
    
    return data;
  }catch(err){
    console.log("Get Failed");
    return undefined;
  }

  }
  
  if(isLoading){

  getResults()
  .then((data) => {
    
    if(data){
      setOffers(data);
      // console.log("Offers:",offers,"Data:",data);
      if(!isObject(data)){
        for(const d of data){
          d.removeOffer = removeOffer;
          d.view = setView;
          d.canRemove = canRemove;
          d.emailLoading = isEmailLoading;
          d.emailSent = false;
        }
        
      }
    }
    // console.log("Data:",data);
    const rows = getRows(data);
    // console.log("Rows:",rows);
    // console.log("Data:",data)
    if(!data && !resend){
      console.log("Resend");
      setResend(true);
      setIsLoading(true);
    }
    setResults (
      <Box className={type === "Offers" ? 'results' : 'not-wrap'} width='100%'>
        
      {(data !== undefined && rows) ? (((type === "Offers") ? 
          (!data.isObject && <>
          {view && <OfferView useView={view} setView={tableSetView} /> }
          <Table loading={isLoading} rows = {rows} removeOffer={removeOffer} view={view} setView={tableSetView} canRemove = {(role === "admin" && rows?.length > 0)}/></>)  : 
          
          (!data.isObject && (<Box mt={10}>{data?.map((data)=>(<AccountCard data={data} key={data.id} />))}</Box> )))) : 
          
          (<Table loading={isLoading} rows = {[]} removeOffer={removeOffer} view={view} setView={tableSetView} canRemove = {true}/>)}
      </Box>
    )
  });
  setIsLoading(false);
  }
  return (results);







//   if (error)
//     return <code className="error">{JSON.stringify(error, null, 4)}</code>;
  
//   return (
//     <section className="results">
//       {/* <Card movie={{}} key={1} /> */}
      
//       {isLoading
//         ? 'Loading...'
//         : 
//         data?.map((data) => ( type === "MyWallet" ? <Card data={data} key={data.id} /> : <AccountCard data={data} key={data.id} />)
//         )}
//     </section>
//   );
};

export default Results;
