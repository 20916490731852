// material-ui
import React, { useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box, Button, ButtonGroup, Checkbox, Fade } from '@mui/material';
import {Editor} from '../MUI/Editor.js';
import { convertFromRaw, Editor as Viewer,EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import { defaultDisclosureTemplate, defaultEmailTemplate } from '../../config.js';
import {CircularProgress} from '@mui/material';
// project import
import MainCard from '../MantisDashboard/components/MainCard.jsx';
import ComponentSkeleton from './ComponentSkeleton.jsx';
import { getMailTemplates,createMailTemplate,deleteMailTemplate,deleteMailTemplates, updateMailTemplate } from '../../Hooks/mailing/index.js';
import { config } from 'process';
import SelectComponent from '../MUI/SelectComponent.js';
import SaveIcon from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PopoverComponent from '../MUI/PopoverComponent.js';
import Input from '@mui/material/Input';
import {IconButton} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getTemplate } from '@rjsf/utils';
import FormControlLabel from '@mui/material/FormControlLabel';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
// ==============================|| MAILING ||============================== //

export default function MailTemplateComponent() {
  const defaultOption = {value:defaultEmailTemplate,label:'VelocityCreds Template',id:0,type:'issue'};
  const defaultInspectionOption = {value:defaultDisclosureTemplate,label:'VelocityCreds Template',id:0,type:'inspection'};
  const [content, setContent] = React.useState(defaultEmailTemplate);
  const [loading, setLoading] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const [saveAsName, setSaveAsName] = React.useState('');
  const [templates, setTemplates] = React.useState(undefined);
  const [error, setError] = React.useState(undefined);
  const [selectValue, setSelectValue] = React.useState(defaultEmailTemplate);
  const [selectedId, setSelectedId] = React.useState(0);
  const [defaultTemplate,setDefaultTemplate] = React.useState({template:defaultEmailTemplate,id:0});
  const [targetType,setTargetType] = React.useState('issue');
  // console.log("defaultTemplate:",defaultTemplate,selectValue);
  useEffect(() => {
   
    console.log("selectValue:UseEffect",selectValue);
  },[selectValue]);
  const loadMailTemplates = () => {
    // fetch mail templates
    setError(undefined);
    setLoading(true);
    getMailTemplates().then((data) => {
      console.log("GetTemplates:",data);
      if(data.error){
        setError(data.error);
      }else{
        setTemplates([defaultOption,defaultInspectionOption,...data.map((data) => {
          if(data.isDefault && data.type === targetType){
            setSelectValue(data.template);
            setContent(data.template);
            setDefaultTemplate(data);
          }
          return {label:data.name,value:data.template,id:data._id,type:data.type}}
        )].filter((template)=>{
          console.log("Template:",template.type,"Target:",targetType,'Result:',template.type === targetType);
          return template.type === targetType}));
      }
      setLoading(false);
    });
  }
  // Function to convert editor content to HTML
  const getEditorContent = () => {
    // const rawContent = convertToRaw().getCurrentContent());
    
    const rawContent = content;
    return draftToHtml(rawContent);
  };
    // Function to create a mailto link
  const createMailtoLink = () => {
      const subject = "Your Credential Is Waiting For You!";
      const html = (getEditorContent())
      // console.log("decodeHtml:",decodeHtmlEntities(html));
    //  const body = convertFromRaw(content).getPlainText();
      return `mailto:?subject=${subject}&body=${html}`;
  };
  useEffect (() => {
    setSelectValue(targetType==='issue' ? defaultOption.value : defaultInspectionOption.value);
    updateContent(targetType==='issue' ? defaultOption.value : defaultInspectionOption.value);
    loadMailTemplates();
    
  },[targetType,defaultEmailTemplate]);

  const updateContent = (newContent) => {
    setContent(newContent);
  }
  useEffect(() => {
    // console.log(content);
  }, [content]);
  const handleOnChange = async(e)=>{
    // console.log("SelectValue:",e.target);
    setLoading(true); //LOADING TO REFRESH STATE OF EDITOR
    await setContent(e.target.value);
    await setSelectValue(e.target.value);
    await setSelectedId(e.target.id);
    setLoading(false);
  }
  return (
    <Box pl={3} pt={10}>
    <ComponentSkeleton>
      <Grid container spacing={3}>
        <Grid item md={10} lg={9}>
          <ButtonGroup variant="contained" color="primary">
            <Button onClick={() => setTargetType('issue')} variant='outlined' sx={{borderBottom:'none'}}disabled={targetType==='issue'} color={targetType==='issue'?'secondary':'primary'}>Issuing</Button>
            <Button onClick={() => setTargetType('inspection')} variant='outlined' sx={{borderBottom:'none'}} disabled={targetType==='inspection'} color={targetType==='inspection'?'secondary':'primary'}>Inspection</Button>
          </ButtonGroup>
          <Stack spacing={3}> 
          
            <PopoverComponent open={popup} onClose={()=>setPopup(false)} >
              <Stack spacing={2} direction='row' p={3}>
                
                <Typography variant='h4'><u>Save Template As</u></Typography>
                </Stack>
                <Stack spacing={2} p={3}>
                <Input placeholder='Template Name'
                title='Template Name'
                onChange={(e)=>{setSaveAsName(e.target.value)}}
                />
                <Button variant='contained' color='success' endIcon={<SaveIcon/>}onClick={(e)=>{
                  createMailTemplate({name:saveAsName,template:content,type:targetType}).then((res)=>{
                    const addTemplate={value:content,label:saveAsName,id:res._id,type:targetType }
                  setTemplates([addTemplate,...templates]);
                  ;console.log(addTemplate);
                  handleOnChange({target:addTemplate});});
                  setPopup(false);}}>Save</Button>
            </Stack>


            </PopoverComponent>
            <MainCard title="">
              <Stack spacing={2} minHeight={1000}>
                <Stack spacing={2} direction='row'>
                <Typography variant="h2" color='text.primary'>Email Template</Typography>
                {/* <Button variant='contained' color='info' onClick={()=>loadMailTemplates()}>Get Templates</Button> */}
                <Stack spacing={0} direction='row'>
                
                <SelectComponent options={templates} title='Templates' value={selectValue} onChange={handleOnChange}
                selectedId={selectedId}
                />
                
                {selectedId !== 0 && <IconButton onClick={()=>{
                  
                   deleteMailTemplate(selectedId)
                   setTemplates(templates.filter((template)=>template.id !== selectedId))
                   handleOnChange({target:defaultOption})
                   setError('Template Deleted')
                  }}
                  title="Delete Template"
                  ><DeleteForeverIcon/></IconButton>}


                  {targetType==='issue'&&<Checkbox icon={<FavoriteBorderOutlinedIcon/>} checked={selectValue === defaultTemplate.template  }
                  onChange={(e)=>{
                    if(e.target.checked){
                      // console.log("DefaultTemplate SET:",defaultTemplate);
                      if(selectedId === 0){
                        updateMailTemplate({template:defaultTemplate.template,_id:defaultTemplate._id,isDefault:false});
                      }else{      
                        updateMailTemplate({template:content,_id:selectedId,isDefault:true});
                      }
                        setDefaultTemplate({template:content,id:selectedId});
                    }  
                  }}
                  checkedIcon={<FavoriteIcon/>} title='Make Default Template' />}
                  
                  
                  
                  
                  </Stack>
                  
                {content!==selectValue ? <>
                <Fade in={true} timeout={1000}>
               
                <Stack spacing={2} direction='row' width={300}>
                
                {selectedId !== 0 && <Button variant='outlined' color='success' fullWidth endIcon={<SaveIcon/>} 
                
                onClick={()=>{updateMailTemplate({template:content,_id:selectedId}).then(()=>{

                  setTemplates(templates.map((template)=>{return template.id === selectedId ? {value:content,label:template.label,id:selectedId} : template}));
                  handleOnChange({target:{value:content,label:selectValue.label,id:selectedId}});
                }).catch((err)=>setError(err));}}

                >Save</Button>}


                <Button variant='outlined' color='warning' fullWidth endIcon={<SaveAsIcon fontSize='large'/>}
                onClick={()=>{setPopup(true)}}  
                >Save As</Button>
                </Stack>
                </Fade>
                </> : <Typography pt={1}>Make Changes to save</Typography>}

               
                {loading && <Box width='100%'><CircularProgress color='secondary' size={40} /></Box>}
                </Stack>
                {error && <Typography variant='h6' color='error'>{error + ""}</Typography>}
                {!loading && <Editor 
                // state={content}
                setState={updateContent}
                defaultProp = {content}
                />}
                <Button variant='contained' color='secondary' target='_blank' href={createMailtoLink()}>Generate Email</Button>
                {/* <Button variant='contained' fullWidth onClick={()=>console.log(toText(content))}>Save</Button> */}
              </Stack>
            </MainCard>

          </Stack>
        </Grid>
        <Grid item xs={12} md={2} lg={3} mt={4.5}>
          <Stack spacing={3}>
            <MainCard >
              <Typography variant="h3">Replacment Strings</Typography>

              <Grid container spacing={2} border={'1px solid'} mt={2}>
                    <Grid item md={12} lg={6}>
                      <Typography variant="subtitle1" ><b>{'{name}'}</b></Typography>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <Typography variant="caption">First and Last name of recipient (changed to 'recipient' for inspection)</Typography>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <Typography variant="subtitle1"><b>{'{companyName}'}</b></Typography>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <Typography variant="caption">Company name of issuer</Typography>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <Typography variant="subtitle1"><b>{'{credentialTitle}'}</b></Typography>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <Typography variant="caption">Title of credential (e.g Director of Sales)</Typography>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <Typography variant="subtitle1"><b>{'{credentialType}'}</b></Typography>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <Typography variant="caption">Type of credential (e.g Employment)</Typography>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <Typography variant="subtitle1"><b>{'{offerLink}'}</b></Typography>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <Typography variant="caption">Link to the offer</Typography>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <Typography variant="subtitle1"><b>{'{qrCode}'}</b></Typography>
                    </Grid>
                    <Grid item md={12} lg={6}>
                      <Typography variant="caption">QR code for the offer</Typography>
                    </Grid>
                  </Grid>
            </MainCard>
           
          </Stack>
        </Grid>
      </Grid>
    </ComponentSkeleton>
  </Box>);
}
