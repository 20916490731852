import React, { useEffect, useState } from 'react';

import { Navigate, useLocation,Link } from 'react-router-dom';
import { NotFoundComponent } from '../../Components/CredentialEngineSearch/CredentialEngineSearch.js';
import { decodeShareCode } from '../../Hooks/disclosures/requests.js';
import { Button, CircularProgress,  Paper, Stack } from '@mui/material';
import CredentialCard from '../../Components/RecievedCredentialsComponent/CredentialCard.js';
import { H1, H2, P } from '../../Components/MUI/Typography.js';
import Header from '../../Components/Header/Header.js';
import PlayIcon from '@mui/icons-material/PlayArrow';
const ShareScreen = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const credential = queryParams.get('credential');
    const [credentials, setCredentials] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [from,setFrom] = useState(undefined);
    useEffect(() => {
        decodeShareCode(credential).then((res) => {
            if(res.error){
                setLoading(false);
                return;
            }
            setCredentials(res.credentials);
            console.log(res);
            setFrom(res.from);
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            setLoading(false);
        });
    }, [credential]);
    return (credential ? 

        loading ? <CircularProgress sx={{ pt: 30 }} size={60} /> 
        : credentials ? 
        <>
        <Header/>
        <Stack  alignItems='center' justifyContent='center' sx={{pt:10}} width='100%' component={Paper} pb={1}>
        <H1 color='grey.600' sx={{textDecoration:'underline'}}>Credentials that can be trusted. </H1>
        <H2>Velocity Credentials are secure, verified, portable credentials housed on the velocity blockchain network. </H2>
        <Button variant='contained' color='primary' component={Link} startIcon={<PlayIcon/>} to='https://youtu.be/A9e7Ow8pzBc' target='_blank'>Learn More</Button>
        </Stack>
        <H1 ><H2 color='black'>{' '+from}.</H2> <H1>Shared a disclosure:</H1></H1>
        <Stack width='90%' alignContent='center' pl='5%'>
            <P>Click to View</P>
        <CredentialCard credential={credentials} />
        </Stack>
        </>


        : <Navigate to='/NotFound'/>
        : 
        <Navigate to='/NotFound'/>);
};
export default ShareScreen;