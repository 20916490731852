import axios from 'axios';
import requests from '../../Api/requests.js';

export async function decodeClaimCode(code) {
    try {
        const response = await axios.get(`${requests.baseURL}/api/offers/claim/${code}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log('Response:',response);
        return response.data;
    } catch (err) {
        console.error('Error:', err);
        return {error:err};
        // return err;
    }
}

