import React,{useState} from 'react';
import axios from 'axios';
import './FileUpload.css';
import requests from '../../Api/requests';
import { useCookies } from 'react-cookie';


const FileUpload = () => {
    const [cookies, setCookie] = useCookies(['AUTH_TOKEN']);
    const [image, setImage] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const handleFileChange = (event) => {
        setImage(event.target.files[0]);
    };
   const onSubmit = e => {
    try{
        e.preventDefault()
        const formData = new FormData()
        formData.append('image', image)
        axios.defaults.withCredentials = true;
        axios.post(`${requests.baseURL}/client/upload/${cookies.AUTH_TOKEN}`, formData, {
        }).then(res => {
            setSuccess(true)
        }).catch(err => {
            setError('Error in uploading file (must be .jpg.png.jpeg)')
        })
    }catch(err){
        setError('Error in uploading file (must be .jpg.png.jpeg)')
    }
}
    return (<>{success ? <div className="success__card">Image Will Update when page restarts</div> : 
        <form className = 'upload__form' onSubmit={onSubmit}>
        <label className="file__upload">Choose Image
            <input id = 'file__input' type="file" onChange={handleFileChange} accept='image/*'/>
        </label>
        {error && <div className="error__card">{error}</div>}
        {(image!=null)&&
        <div className="form-group">
            <button className="btn__upload btn-primary" type="submit">Upload</button>
        </div>}
      </form>}</>
    );
};

export default FileUpload;