import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// project import
import MainCard from './components/MainCard';

// third-party
import ReactApexChart from 'react-apexcharts';

// chart options
const columnChartOptions = {
  chart: {
    type: 'bar',
    height: 430,
    toolbar: {
      show: false
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '30%',
      borderRadius: 4
    }
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']
  },
  stroke: {
    show: true,
    width: 8,
    colors: ['transparent']
  },
  yaxis: {
    title: {
      text: 'Credentials'
    }
  },
  fill: {
    opacity: 1
  },
  tooltip: {
    y: {
      formatter(val) {
        return `${val} credentials`;
      }
    }
  },
  legend: {
    show: false
  },
  responsive: [
    {
      breakpoint: 600,
      options: {
        yaxis: {
          show: false
        }
      }
    }
  ]
};
const getDailyStats=(data) => {
  var num =0;
  const date = new Date().getDate();
  data[new Date().getMonth()]?.data?.forEach((stats)=>{
    if(stats.date === date){
      num += 1;
    }
  })
  return [num];
};
const getMonthStats = (data,range) => {
  var stats = range.map((date)=>{return {date: date, count: 0}});
  data[new Date().getMonth()]?.data?.forEach((data)=>{
    var index = stats.findIndex((d)=>d.date === data.date);
    if(index >=0){
      stats[index].count += 1;
    }
    // else{
    //   stats.push({date: data.date, count: 1});
    // }
  });
  // console.log("SalesChart:getMonthStats",data);
  return stats.map((data)=>data.count);
};

// ==============================|| SALES COLUMN CHART ||============================== //

export default function SalesChart({offerData,issuedData,displayType}) {
  const theme = useTheme();
  var numOffers = 0;
  offerData?.forEach((data)=>{numOffers += data?.count});
  var numIssued = 0;
  issuedData?.forEach((data)=>{numOffers += data?.count});
  // console.log("SalesChart",offerData,issuedData);
  const [legend, setLegend] = useState({
    offers: true,
    issued: true
  });

  const getDateRange = (rangeData) => {
    if(!rangeData){
      return [...new Set([...getDateRange(offerData),...getDateRange(issuedData)].sort((a,b)=>a-b))];
    }
    const month = new Date().getMonth();
    const range = [];
    const len = (rangeData[month]?.data?.length)
    for(let i = 0; i < len; i++){
      var date = rangeData[month]?.data[i]?.date;
      // console.log("SalesChart:getDateRange",offerData[month]?.data[i]);
      if(date){
        var index = range.findIndex((data)=>data === date);
        if(index <0){

          range.push(date);
        }
      }
    }
    return range.length > 0 ? range : ['Today'];
  }

  const initialSeries = [
    {
      name: 'Offers Created',
      data: displayType==='year' ? offerData?.filter((data,index)=>(data.count>0 || offerData[index].count > 0)).map((data)=>data?.count) :
      displayType==='month' ? getMonthStats(offerData,getDateRange()) :
       getDailyStats(offerData)
    },
    {
      name: 'Issued Credentials',
      data: displayType==='year' ? issuedData?.filter((data,index)=>(data.count>0 || offerData[index].count > 0)).map((data)=>data?.count) :
      displayType==='month' ? getMonthStats(issuedData,getDateRange()) :
       getDailyStats(issuedData)
    }
  ];






  const { offers, issued } = legend;

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const warning = theme.palette.warning.main;
  const primaryMain = theme.palette.primary.main;
  const successDark = theme.palette.success.dark;

  const [series, setSeries] = useState(initialSeries);

  const handleLegendChange = (event) => {
    setLegend({ ...legend, [event.target.name]: event.target.checked });
  };

  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [options, setOptions] = useState(columnChartOptions);

  useEffect(() => {
    if (offers && issued) {
      setSeries(initialSeries);
    } else if (issued) {
      setSeries([
        {
          name: 'Credential Issued',
          data: displayType==='year' ? issuedData?.filter((data,index)=>(data.count>0 || offerData[index].count > 0)).map((data)=>data?.count) :
          displayType==='month' ? getMonthStats(issuedData,getDateRange()) :
           getDailyStats(issuedData)
   
        }
      ]);
    } else if (offers) {
      setSeries([
        {
          name: 'Offers Created',
          data: displayType==='year' ? offerData?.filter((data,index)=>(data.count>0 || offerData[index].count > 0)).map((data)=>data?.count) :
          displayType==='month' ? getMonthStats(offerData,getDateRange()) :
           getDailyStats(offerData)
        }
      ]);
    } else {
      setSeries([]);
    }
    setOptions({...columnChartOptions, xaxis: {
      categories: 
      displayType === 'month' ? 
      getDateRange() : 

      displayType === 'year' ? ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'].filter((data,index)=>{
        if(offerData[index]?.count > 0 || issuedData[index]?.count > 0){
          // console.log("SalesChart:filter",data);
          return true;
        }
          return false;
        
      }) : ['Today']
    }});
    
  }, [offers, issued,offerData,issuedData,displayType]);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: !(offers && issued) && issued ? [primaryMain] : [warning, primaryMain],
      xaxis: {
        labels: {
          style: {
            colors: [secondary, secondary, secondary, secondary, secondary, secondary]
          }
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: [secondary]
          }
        }
      },
      grid: {
        borderColor: line
      },
      plotOptions: {
        bar: {
          columnWidth: xsDown ? '60%' : '30%'
        }
      }
    }));
  }, [primary, secondary, line, warning, primaryMain, successDark, offers, issued, xsDown]);

  return (
    <MainCard sx={{ mt: 1 }} content={false}>
      <Box sx={{ p: 2.5, pb: 0 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack spacing={1.5}>
            <Typography variant="h6" color="secondary">
              Total
            </Typography>
            <Typography variant="h4">{numOffers + numIssued}</Typography>
          </Stack>
          <FormControl component="fieldset">
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox color="warning" checked={offers} onChange={handleLegendChange} name="offers" />}
                label="Offers Created"
              />
              <FormControlLabel control={<Checkbox checked={issued} onChange={handleLegendChange} name="issued" />} label="Issued Credentials" />
            </FormGroup>
          </FormControl>
        </Stack>
        <Box id="chart" sx={{ bgcolor: 'transparent' }}>
          <ReactApexChart options={options} series={series} type="bar" height={360} />
        </Box>
      </Box>
    </MainCard>
  );
}
