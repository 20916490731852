import requests from '../Api/requests.js';
import Cookies from 'js-cookie';


async function deleteOffer(id) {
      if(id===undefined){
        id='';
      }else{
        id='/'+id;
      }
  try{
      return fetch(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/offers${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({delete:true})
      })
        .then(data => data.json()).catch(err => console.log("Error getting:",err));
  }catch(err){
      console.log("error");
    }
}
  
  
  export default deleteOffer;