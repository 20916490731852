// material-ui
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import VerifiedIcon from '@mui/icons-material/VerifiedOutlined';
import { DashboardOutlined } from '@ant-design/icons';
import NoteAltIcon from '@mui/icons-material/ContactMail';
// project import
import NavGroup from './NavGroup';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../../../../AppContext';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PolicyIcon from '@mui/icons-material/Policy';
import SettingsIcon from '@mui/icons-material/Settings';
// import menuItem from 'menu-items'; SEE ORIGINAL REPO FOR THIS FILE

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const pages = [
  {
    id: 'group-dashboard',
    title: 'Credentials',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/',
        icon: DashboardOutlined,
      },
      {
        id: 'issue',
        title: 'Issue a Credential',
        type: 'item',
        url: '/issue',
        icon: VerifiedIcon,
      },
      {
        id: 'inspect',
        title: 'Inspect a Credential',
        type: 'item',
        url: '/inspect',
        icon: PolicyIcon,
      },
    ]
  },
  {
    id: 'group-dashboard',
    title: 'Settings',
    type: 'group',
    children: [
      {
        id: 'templates',
        title: 'Email Templates',
        type: 'item',
        url: '/templates',
        icon: NoteAltIcon,
      },
      {
        id: 'Settings',
        title: 'Account Settings',
        type: 'item',
        url: '/account',
        icon: SettingsIcon,
      }
    ]
  }
]

export default function Navigation() {


  const {user} = useContext(AppContext);
  if(user.super){
    pages[2] = ({
      id: 'group-admin',
      title: 'Admin',
      type: 'group',
      children: [
        {
          id: 'admin',
          title: 'Admin',
          type: 'item',
          url: '/admin',
          icon: AdminPanelSettingsIcon,
        }
      ]
    
    });
  }




  const navGroups = pages.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
}
