import React, { useEffect, useState } from 'react';

import { Navigate, useLocation,Link } from 'react-router-dom';
import { NotFoundComponent } from '../../Components/CredentialEngineSearch/CredentialEngineSearch.js';
import { decodeShareCode } from '../../Hooks/disclosures/requests.js';
import { Button, CircularProgress,  Paper, Slide, Stack } from '@mui/material';
import CredentialCard from '../../Components/RecievedCredentialsComponent/CredentialCard.js';
import { H1, H2, P } from '../../Components/MUI/Typography.js';
import Header from '../../Components/Header/Header.js';
import PlayIcon from '@mui/icons-material/PlayArrow';
import * as CredentialHandlerPolyfill from 'credential-handler-polyfill';
import { decodeClaimCode } from '../../Hooks/sharing/decodeClaimCode.js';
import ReactJson from 'react-json-view';
import GetAppIcon from '@mui/icons-material/GetApp';
import NotifyPopup from '../../Components/MUI/NotifyPopup.js';
import { getOfferDID, getVerifiableCredential } from '../../schemas/parsers/VerifiableCredentialParser.js';
import { getDescriptor } from '../../Components/CredentialForm/CredentialSection.jsx';
import { getOrgData } from '../../Api/fields.js';
import requests from '../../Api/requests.js';
import axios from 'axios';
import Cookies from 'js-cookie';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HomeIcon from '@mui/icons-material/Home';
import OfferCard from '../../Components/MUI/OfferCard.js';
import { setOfferClaimed } from '../../Hooks/sharing/nonVelocityHandling.js';
//Load the polyfill once the app is ready to work with credentials
await CredentialHandlerPolyfill.loadOnce();
console.log('Ready to work with credentials!', CredentialHandlerPolyfill.WebCredential);




const ClaimWebCredentialScreen = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const credential = queryParams.get('credential');
    const [credentials, setCredentials] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [from,setFrom] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [issued, setIssued] = useState(false);
    const [descriptor, setDescriptor] = useState(undefined);
    const [logo,setLogo] = useState(undefined);
    const [context, setContext] = useState(undefined);
    useEffect(() => {
    
        decodeClaimCode(credential).then((res) => {
            
            if(res.error){
                setLoading(false);
                setError(res.error);
                return;
            }
            if(res.claimed){
                console.log('Offer already claimed');
                setCredentials({'claimed':true}); //empty object to prevent errors
                setIssued(true);
                setLoading(false);
                return;
            }
            setLogo(res.logo);
           
            setCredentials(res.offer);
            console.log(res);
            setFrom(res.from);
            //loading Display descriptor
            getDescriptor(res.offer.type[0]).then((data) => {
                setDescriptor(data);
                console.log('Descriptor:',data);
                setLoading(false);
            });
            //load org data
            axios.get(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/${getOfferDID(res.offer.credentialSubject)}/verified-profile`).then((data) => {
                if(data.data?.credentialSubject?.logo){
                    setLogo(data.data?.credentialSubject?.logo);
                }
                
            }).catch((e) => console.log('Error:', e));
            //load context
            axios.get(`${requests.baseURL}/images/layer1context.json`).then((data) => {
                setContext(data.data);
            }).catch((e) => console.log('Error:', e));


        }).catch((err) => {
            console.log(err);
            setLoading(false);
        });



    }, [credential]);
 

    useEffect(() => {
        console.log('Logo:',logo) ;
    }, [logo]);

    const claimOffer = () => {
        //make presentation Wrapper
        const presentation = {
            "@context": [
              "https://www.w3.org/2018/credentials/v1",
              "https://www.w3.org/2018/credentials/examples/v1"
            ],
            "type": "VerifiablePresentation",
            verifiableCredential: [
                getVerifiableCredential(credentials,descriptor,logo,context)
            ],
            //A proof is not required on the Verifiable Presentation (only on the VCs themselves)
        };
        const credentialType = 'VerifiablePresentation';
        const webCredentialWrapper = new CredentialHandlerPolyfill.WebCredential(
        credentialType, presentation, {
        recommendedHandlerOrigins: [
            'https://demo.vereswallet.dev/'
        ]
        
        });
        //Store the credential in the web wallet
        navigator.credentials.store(webCredentialWrapper).then((result) => {
        if(!result) {
            setError('Credential not stored');
        }else{
            setOfferClaimed(credential).then((data) => {
                console.log('Claimed:',data);
                setIssued(true);
            
        }).catch((error) => {
            setError(error.message || error +" ");
        });
        }
    }).catch((error) => {
        setError(error.message || error +" ");
    });
    }
    return (credential ? 

        loading ? <CircularProgress sx={{ pt: 30 }} size={60} /> 
        : credentials ? 
        <>
        {error && <NotifyPopup message={error} severity="error" onClose={()=>setError(undefined)} bottom={true}/>}
        <Header/>
        <Stack  alignItems='center' justifyContent='center' sx={{pt:10}} width='100%' component={Paper} pb={1}>
        <H1 color='grey.600' sx={{textDecoration:'underline'}}>Flexible, portable, verified. </H1>
        <H2>VelocityCreds enables organizations to focus on what credentials to issue, not how to issue them. We have support for both velocity enabled blockchain credentials and web wallet enabled W3C Verifiable Credentials</H2>
        <Button variant='outlined' color='inherit' component={Link} startIcon={<PlayIcon/>} to='https://youtu.be/A9e7Ow8pzBc' target='_blank'>Learn More</Button>
        </Stack>
        {!issued ? <>
        
            <Stack direction='row'>
            {logo&&<img src={logo} alt='logo' style={{width:'100px',height:'100px',padding:'5px'}}/>}
        <H1 ><H2 color='black'>{from || 'An Organization'}.</H2> <H1 color='grey.600'>Issued you a Verifiable Credential:</H1></H1>
        </Stack>
        <Stack width='90%' alignContent='center' pl='5%'>
            <OfferCard offerData={credentials.credentialSubject} sx={{border:'1px solid'}}/>
            <Button variant='contained' sx={{boarderRadius:0}} endIcon={<GetAppIcon/>} onClick={claimOffer}>Claim Offer</Button>
        
        
        
       
        </Stack>
        </>
        
        :
        
        
        
        <Stack justifyContent='center' alignItems='center' pt={2}>
            <Slide in direction="up">
                <Stack alignItems='center' spacing={2}>
            <Stack direction='row' alignItems='center' spacing={2}>
                <CheckCircleIcon color='success'sx={{transform:'scale(1.7)'}}/>
            <H1 color='inherit' fontSize={25}>Your Credential has been Issued!</H1>
           </Stack>
            <H2 color='grey.600'>You can view your credential in your web wallet.</H2>
            <Button variant='outlined' component={Link} to='/landing?redirect=/' startIcon={<HomeIcon />} >
            Home
        </Button>
            </Stack>
        </Slide>
        
        </Stack>}
        <ReactJson src={getVerifiableCredential(credentials,descriptor,logo,context)} collapsed />
        </>


        : <Navigate to='/NotFound'/>
        : 
        <Navigate to='/NotFound'/>);
};
export default ClaimWebCredentialScreen;