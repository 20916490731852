import requests from "../Api/requests";
import Cookies from "js-cookie";
const revokeCredential = async (credential,credentialId) => {
    try {
        return await fetch(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/credentials/${credentialId}/revoke`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credential)
        }).then(response => response.json());

    } catch (error) {
        console.log(error);
        // Handle the error here
    }
};

export default revokeCredential;