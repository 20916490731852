import parseClr from "./Clr.parser"
import ParseNCBSNMulitLicensev1 from "./NCBSNMulitLicensev1.0.parser"


const parsers = {
    'clr':parseClr,
    'ncsbn-multi-license':ParseNCBSNMulitLicensev1
}

export default parsers

