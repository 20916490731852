import { Box, Button, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import NotifyPopup from '../MUI/NotifyPopup';
import { H1, P } from '../MUI/Typography';
import { NotFoundComponent } from '../CredentialEngineSearch/CredentialEngineSearch';
import CredentialCard from './CredentialCard';
import { getAllReviecedCredentials } from '../../Hooks/disclosures/recievedCredentials';
import PopoverComponent from '../MUI/PopoverComponent';


const ReceivedCredentialsComponent = () => {
    const[error,setError] = React.useState(undefined);
    const[success,setSuccess] = React.useState(undefined);
    const [credentials,setCredentials]= React.useState(undefined);

    useEffect(() => {
        async function getCredentials(){
            try{
                const credentials = await getAllReviecedCredentials();
                setCredentials(credentials);
            }catch(e){
                console.log("Error:",e);
            }
        }
        getCredentials();
    }, []);
    return (
        <Box sx={{marginTop:'80px'}} width='100%' alignContent='center' >



        {error && <NotifyPopup message={error} severity="error" onClose={()=>setError(undefined)}/>}
        {success && <NotifyPopup message={success} severity="success" onClose={()=>setSuccess(undefined)}/>}
        <Stack width='100%' px={2}>
        <H1 color='inherit' fontSize={30} sx={{borderBottom:'2px solid coral', width:'3 50px'}}>{'Received Disclosures'}</H1>
        <Stack spacing={1} width='100%' pt={1} alignItems='center'>
            {credentials?.length>0 && <P sx={{pl:1}}>Click to View</P>}
        {credentials?.length>0 ? credentials.map((credential,index) => (
            <CredentialCard key={index} credential={credential} rawCredentials={credential?.rawCredentials} listId={credential._id}onDelete={()=>setCredentials(credentials.toSpliced(index,1))} onClick={()=>{}}/>
        )) : <NotFoundComponent title='No Credentials Recieved'/>}
        </Stack>
        </Stack>
    </Box> 
    );
};

export default ReceivedCredentialsComponent;