import axios from 'axios';
import requests from '../../Api/requests';
import Cookies from 'js-cookie';

export async function sendDisclosureEmail(body){
    try{
        return await axios.post(`${requests.baseURL}/api/${Cookies.get('AUTH_TOKEN')}/disclosure-email`,body)
            .then(data => data.data);
    }catch(err){
        console.log("Error:",err);
        return {error:err};
    }
}